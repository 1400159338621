@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'components/article';
@import 'components/icons';

@layer utilities {
  ::after {
    box-sizing: initial;
  }
}

@layer base {
  .collapse {
    visibility: initial;
  }
}

html {
  @apply text-slate-700 break-all;
}

* {
  @apply font-normal p-0;
}

h3 {
  @apply text-lg;
}

#footer a {
  @apply text-white;
}

abbr[title] {
  @apply no-underline;
}

.hc-label {
  @apply text-white text-[10px] py-0.5 px-1 bg-black/50;
}

g text tspan {
  @apply font-bold;
}

.fr-embedly {
  height: auto !important;
}

g.highcharts-scrollbar {
  @apply hidden;
}

.control-label {
  @apply hidden;
}
