#article-body {
  p {
    margin: 16px 0;
    font-size: 1em;
  }
  
  a {
    color: #1D4ED8;
    &:hover {
      opacity: 0.7;
    }
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: auto;
  }

  h2,
  h3 {
    margin: 40px 0 24px;
    padding: 0 8px;
    font-weight: bold;
    line-height: 32px;

    a {
      color: #333;
    }
  }

  h2 {
    background: #f5f5f5;
    border-left: 5px solid #F57C00;
    border-bottom: 1px solid #e0e0e0;

    &.h2-ordered-list {
      counter-reset: h3-counter;
      counter-increment: h2-counter;

      &::before {
        content: counter(h2-counter) '. ';
      }
    }
  }

  h3 {
    border-bottom: 2px solid #F57C00;

    &.h3-ordered-list {
      counter-increment: h3-counter;

      &::before {
        content: '（' counter(h3-counter) '）';
      }
    }
  }

  pre {
    margin: 8;
    overflow: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  blockquote {
    margin: 16px 0;
    padding: 8px;
    font-size: 1em;
    background: #f8fafc;
    border: 1px solid #b8c2cc;
  }

  div.pt1 {
    background: #fbe9e7;
    border: 1px solid #ee7700;
    color: #3e2723;
    border-radius: 3px;
    margin: 16px 0;
    padding: 8px;
    font-size: 1em;
  }

  .scrollable-table {
    overflow-x: scroll;
  }

  table {
    width: 100%;
    margin: 16px 0;
    border-collapse: collapse;
    border: 0 none;
    border-spacing: 0;

    th,
    td {
      border-top: none;
      border-right: none;
      border-left: none;
      position: relative;
      padding: 8px;

      &.sticky-cell {
        position: -webkit-sticky;
        position: sticky;
        z-index: 1;
        left: 0;
      }
    }

    th {
      border-width: 1px;
      border-style: solid;
      border-color: #fff;
      border-spacing: 1px;
      font-weight: bold;
      font-size: 0.875em;
      color: #333;
    }

    td {
      background-color: #fff;
      text-align: left;
      height: 32px;
      border-bottom: 1px solid #ccc;
    }

    thead {
      th {
        text-align: center;
        background-color: #e2e9ed;
      }
    }

    tbody {
      th {
        background-color: #ededed;
      }
    }

    tr {
      &.last-child {
        th,
        td {
          border-bottom: none;
        }
      }
    }

    &.orange {
      font-size: 1em;
      border: 2px solid #cccccc;

      p {
        margin: 0;
      }

      tr:nth-child(even) {
        background-color: #fafafa;
      }

      th {
        background: #df7d2f;
        color: #fff;
        font-weight: normal;
        border-right: 2px solid #cccccc;
        border-bottom: 2px solid #cccccc;
      }

      td {
        border-bottom: 2px solid #cccccc;
      }
    }

    &.gray {
      font-size: 1em;
      text-shadow: 1px 1px 0px #fff;
      background: #eaebec;
      border: #ccc 1px solid;
      border-collapse: inherit;

      p {
        margin: 0;
      }

      th {
        border-top: 1px solid #fafafa;
        border-bottom: 1px solid #e0e0e0;
        background: #ededed;
        background: -webkit-gradient(linear, left top, left bottom, from(#ededed), to(#ebebeb));
        background: -moz-linear-gradient(top, #ededed, #ebebeb);
      }

      td {
        border-top: 1px solid #ffffff;
        border-bottom: none;
        background: #fafafa;
        background: -webkit-gradient(linear, left top, left bottom, from(#fbfbfb), to(#fafafa));
        background: -moz-linear-gradient(top, #fbfbfb, #fafafa);
      }
    }
  }

  ul { list-style-type: disc; }
  ol { list-style-type: decimal; }

  ul, ol {
    margin-bottom: unset;
    padding: 1rem 1rem 1rem 2rem;
    list-style-position: outside;

    ul { list-style-type: square; }

    > li {
      list-style: inherit;

      ol > li {
        list-style: none;
      }
    }
  }

  iframe {
    max-width: 100%;
  }

  strong {
    font-weight: bold;
  }

  pre.news-content {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI',
      Helvetica, Arial, 'Noto Sans JP', 'ヒラギノ角ゴ ProN',
      'Hiragino Kaku Gothic ProN', YuGothic, '游ゴシック', Meiryo, 'メイリオ',
      'MS PGothic', 'ＭＳ Ｐゴシック', sans-serif;
  }

  .mokuji {
    background: #fafafa;
    border: solid 1px #df7d2f;
    margin-bottom: 2em;
  
    .mokuji-head {
      background: #df7d2f;
      color: #fff;
      font-size: 1.125em;
      padding: 0.2em;
      text-align: center;
    }
  
    a {
      font-size: 1.125em;
      color: #333;
    }
  
    li { padding: 8px 0; }
  
    > ol {
      margin: 0px;
  
      > li {
        border-bottom: 1px dashed #b8c2cc;
        &:last-child{ border-bottom: none; }
  
        > ol {
          padding: unset;
          counter-reset: ol;
          > li {
            counter-increment: ol;
            border-bottom: none;
  
            &:before {
              content: "（" counter(ol) "）";
            }
          }
        }
      }
    }
  }

  .news-content iframe {
    margin: 0 auto;
  }

  &.crypto-times-jp {
    .wp-caption {
      &.aligncenter {
        width: 100% !important;
      }
    }
  }
}

.article-body.cripcy-jp {
  img {
    display: inline;
  }
}

.article-bottom {
  margin: 24px 8px;
}

.article-option {
  display: inline-block;
  margin-right: 0.8em;
  color: #8795a1;

  .publish_dt {
    margin-right: 0.6em;

    @media (min-width: 768px) {
      display: inline-block;
    }
  }
}

.article-list_wrap {
  padding: 8px 16px;
}

.btn {
  &.green {
    position: relative;
    background: #04b431;
    text-shadow: none;
    font-weight: bold;
    color: #fffc00;
    width: 90%;
    margin: 0 auto;

    i {
      position: absolute;
      right: 0%;
      top: 30%;
    }
  }
}
