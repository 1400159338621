/*! purgecss start ignore */
@font-face {
  font-family: 'cc_icomoon';
  src: url('../../../../../public/fonts/cc_icomoon.eot?72axam');
  src: url('../../../../../public/fonts/cc_icomoon.eot?72axam#iefix') format('embedded-opentype'),
    url('../../../../../public/fonts/cc_icomoon.ttf?72axam') format('truetype'),
    url('../../../../../public/fonts/cc_icomoon.woff?72axam') format('woff'),
    url('../../../../../public/fonts/cc_icomoon.svg?72axam#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'cc_icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  margin: 0 3px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* use #000000 to prevent color change in black currency fonts */
  &.currency {
    color: #000000;
  }
}

.rank {
  &.icon-rank1 {
    color: #e6bf56;
  }

  &.icon-rank2 {
    color: #a3a3a3;
  }

  &.icon-rank3 {
    color: #d5896b;
  }
}

.icon-building:before {
  content: '\eb10';
}
.m-icon {
  padding: 3px 2px 1px 2px;
  border-radius: 2px;
  font-size: 0.875em;
}
.icon_bg {
  &:before {
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    display: inline-block;
    text-align: center;
    width: 1.75em;
    height: 1.75em;
    line-height: 1.75;
  }
  &.theme_primer:before {
    background-color: #529e7f;
  }
}

.icon-line:before {
  content: '\e991';
}
.icon-facebook_f:before {
  content: "\e990";
}
.icon-bitcoin:before {
  content: '\e958';
}
.icon-kabutan:before {
  content: '\e959';
}
.icon-expected:before {
  content: '\e95a';
}
.icon-wowoo .path1:before {
  content: '\e95b';
  color: rgb(255, 255, 255);
}
.icon-wowoo .path2:before {
  content: '\e977';
  margin-left: -0.9990234375em;
  color: rgb(230, 33, 26);
}
.icon-wowoo .path3:before {
  content: '\e978';
  margin-left: -0.9990234375em;
  color: rgb(255, 255, 255);
}
.icon-btg .path1:before {
  content: '\e95c';
  color: rgb(0, 32, 107);
}
.icon-btg .path2:before {
  content: '\e979';
  margin-left: -1em;
  color: rgb(0, 32, 107);
}
.icon-btg .path3:before {
  content: '\e97a';
  margin-left: -1em;
  color: rgb(235, 168, 9);
}
.icon-cms:before {
  content: '\e95d';
  color: #282f71;
}
.icon-qash:before {
  content: '\e95e';
  color: #1347e8;
}
.icon-ark:before {
  content: '\e95f';
  color: #cd0e02;
}
.icon-bat .path1:before {
  content: '\e960';
  color: rgb(161, 27, 96);
}
.icon-bat .path2:before {
  content: '\e97b';
  margin-left: -1em;
  color: rgb(107, 33, 142);
}
.icon-bat .path3:before {
  content: '\e97c';
  margin-left: -1em;
  color: rgb(255, 85, 15);
}
.icon-btc .path1:before {
  content: '\e961';
  color: rgb(247, 147, 26);
}
.icon-btc .path2:before {
  content: '\e97d';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-btc .path3:before {
  content: "\e97d";
  color: rgb(255, 255, 255);
}
.icon-btc .inverted:before {
  content: '\e93f';
  color: black;
}
.icon-bch .path1:before {
  content: '\e97f';
  color: rgb(10, 193, 142);
}
.icon-bch .path2:before {
  content: '\e980';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-dash .path1:before {
  content: '\e963';
  color: rgb(28, 117, 188);
}
.icon-dash .path2:before {
  content: '\e981';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-dash .path3:before {
  content: '\e982';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-etc:before {
  content: '\e964';
  color: #3ab83a;
}
.icon-eth .path1:before {
  content: '\e965';
  color: rgb(139, 147, 174);
}
.icon-eth .path2:before {
  content: '\eb53';
  margin-left: -1em;
  color: rgb(99, 105, 139);
}
.icon-eth .path3:before {
  content: '\eb54';
  margin-left: -1em;
  color: rgb(139, 147, 174);
}
.icon-eth .path4:before {
  content: '\eb55';
  margin-left: -1em;
  color: rgb(99, 105, 139);
}
.icon-eth .path5:before {
  content: '\eb56';
  margin-left: -1em;
  color: rgb(70, 75, 113);
}
.icon-eth .path6:before {
  content: '\eb57';
  margin-left: -1em;
  color: rgb(99, 105, 139);
}
.icon-fct .path1:before {
  content: '\e966';
  color: rgb(65, 123, 164);
}
.icon-fct .path2:before {
  content: '\e983';
  margin-left: -1em;
  color: rgb(211, 125, 65);
}
.icon-fct .path3:before {
  content: '\e984';
  margin-left: -1em;
  color: rgb(232, 181, 33);
}
.icon-fct .path4:before {
  content: '\e985';
  margin-left: -1em;
  color: rgb(211, 125, 65);
}
.icon-fct .path5:before {
  content: '\e986';
  margin-left: -1em;
  color: rgb(65, 123, 164);
}
.icon-fct .path6:before {
  content: '\e987';
  margin-left: -1em;
  color: rgb(232, 181, 33);
}
.icon-fct .path7:before {
  content: '\e988';
  margin-left: -1em;
  color: rgb(211, 125, 65);
}
.icon-fct .path8:before {
  content: '\e989';
  margin-left: -1em;
  color: rgb(239, 243, 201);
}
.icon-fct .path9:before {
  content: '\e98a';
  margin-left: -1em;
  color: rgb(65, 123, 164);
}
.icon-fct .path10:before {
  content: '\e98b';
  margin-left: -1em;
  color: rgb(211, 125, 65);
}
.icon-fct .path11:before {
  content: '\e98c';
  margin-left: -1em;
  color: rgb(232, 181, 33);
}
.icon-fct .path12:before {
  content: '\e98d';
  margin-left: -1em;
  color: rgb(65, 123, 164);
}
.icon-fct .path13:before {
  content: '\e98e';
  margin-left: -1em;
  color: rgb(211, 125, 65);
}
.icon-fct .path14:before {
  content: '\e98f';
  margin-left: -1em;
  color: rgb(65, 123, 164);
}
.icon-fct .path15:before {
  content: '\eb32';
  margin-left: -1em;
  color: rgb(211, 125, 65);
}
.icon-fct .path16:before {
  content: '\eb33';
  margin-left: -1em;
  color: rgb(65, 123, 164);
}
.icon-fct .path17:before {
  content: '\eb34';
  margin-left: -1em;
  color: rgb(232, 181, 33);
}
.icon-fct .path18:before {
  content: '\eb35';
  margin-left: -1em;
  color: rgb(211, 125, 65);
}
.icon-fct .path19:before {
  content: '\eb36';
  margin-left: -1em;
  color: rgb(65, 123, 164);
}
.icon-fct .path20:before {
  content: '\eb37';
  margin-left: -1em;
  color: rgb(65, 123, 164);
}
.icon-fct .path21:before {
  content: '\eb38';
  margin-left: -1em;
  color: rgb(232, 181, 33);
}
.icon-fct .path22:before {
  content: '\eb39';
  margin-left: -1em;
  color: rgb(65, 123, 164);
}
.icon-fct .path23:before {
  content: '\eb3a';
  margin-left: -1em;
  color: rgb(211, 125, 65);
}
.icon-fct .path24:before {
  content: '\eb3b';
  margin-left: -1em;
  color: rgb(65, 123, 164);
}
.icon-fct .path25:before {
  content: '\eb3c';
  margin-left: -1em;
  color: rgb(232, 181, 33);
}
.icon-fct .path26:before {
  content: '\eb3d';
  margin-left: -1em;
  color: rgb(65, 123, 164);
}
.icon-fct .path27:before {
  content: '\eb3e';
  margin-left: -1em;
  color: rgb(211, 125, 65);
}
.icon-fct .path28:before {
  content: '\eb3f';
  margin-left: -1em;
  color: rgb(65, 123, 164);
}
.icon-fct .path29:before {
  content: '\eb40';
  margin-left: -1em;
  color: rgb(232, 181, 33);
}
.icon-fct .path30:before {
  content: '\eb41';
  margin-left: -1em;
  color: rgb(211, 125, 65);
}
.icon-fct .path31:before {
  content: '\eb42';
  margin-left: -1em;
  color: rgb(65, 123, 164);
}
.icon-fct .path32:before {
  content: '\eb43';
  margin-left: -1em;
  color: rgb(211, 125, 65);
}
.icon-fct .path33:before {
  content: '\eb44';
  margin-left: -1em;
  color: rgb(65, 123, 164);
}
.icon-game:before {
  content: '\e967';
  color: #92c43a;
}
.icon-iot:before {
  content: '\e958';
  color: #333333;
}
.icon-lsk .path1:before {
  content: '\e968';
  color: rgb(34, 93, 146);
}
.icon-lsk .path2:before {
  content: '\eb45';
  margin-left: -1em;
  color: rgb(34, 93, 146);
}
.icon-lsk .path3:before {
  content: '\eb46';
  margin-left: -1em;
  color: rgb(34, 93, 146);
}
.icon-lsk .path4:before {
  content: '\eb47';
  margin-left: -1em;
  color: rgb(34, 93, 146);
}
.icon-lsk .path5:before {
  content: '\eb48';
  margin-left: -1em;
  color: rgb(34, 93, 146);
}
.icon-lsk .path6:before {
  content: '\eb49';
  margin-left: -1em;
  color: rgb(34, 93, 146);
}
.icon-lsk .path7:before {
  content: '\eb4a';
  margin-left: -1em;
  color: rgb(44, 40, 38);
}
.icon-lsk .path8:before {
  content: '\eb4b';
  margin-left: -1em;
  color: rgb(44, 40, 38);
}
.icon-lsk .path9:before {
  content: '\eb4c';
  margin-left: -1em;
  color: rgb(44, 40, 38);
}
.icon-lsk .path10:before {
  content: '\eb4d';
  margin-left: -1em;
  color: rgb(44, 40, 38);
}
.icon-ltc:before {
  content: '\e969';
  color: #9e9e9e;
}
.icon-mona:before {
  content: '\e96a';
  color: #a59062;
}
.icon-neo:before {
  content: '\e96b';
  color: #73b32d;
}
.icon-pay:before {
  content: '\e96c';
  color: #333333;
}
.icon-qtum:before {
  content: '\e96d';
  color: #39a5da;
}
.icon-sc:before {
  content: '\e96e';
  color: #00cba0;
}
.icon-strat:before {
  content: '\e96f';
}
.icon-waves .path1:before {
  content: '\e970';
  color: rgb(61, 182, 211);
}
.icon-waves .path2:before {
  content: '\eb4e';
  margin-left: -1em;
  color: rgb(226, 225, 225);
}
.icon-xem .path1:before {
  content: '\e971';
  color: rgb(103, 178, 232);
}
.icon-xem .path2:before {
  content: '\eb4f';
  margin-left: -1em;
  color: rgb(247, 168, 0);
}
.icon-xem .path3:before {
  content: '\eb50';
  margin-left: -1em;
  color: rgb(0, 196, 179);
}
.icon-xem .path4:before {
  content: '\eb51';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-xmr .path1:before {
  content: '\e972';
  color: rgb(255, 102, 0);
}
.icon-xmr .path2:before {
  content: '\eb52';
  margin-left: -1em;
  color: rgb(76, 76, 76);
}
.icon-xrp:before {
  content: '\e973';
}
.icon-zec:before {
  content: '\e974';
}
.icon-omg:before {
  content: '\e97e';
}
.icon-theta:before {
  content: '\e900';
  color: #29cad2;
}
.icon-tusd .path1:before {
  content: '\e901';
  color: rgb(90, 175, 255);
}
.icon-tusd .path2:before {
  content: '\e902';
  margin-left: -0.9482421875em;
  color: rgb(93, 201, 188);
}
.icon-tusd .path3:before {
  content: '\e903';
  margin-left: -0.9482421875em;
  color: rgb(90, 175, 255);
}
.icon-atom .path1:before {
  content: '\e904';
  color: rgb(46, 49, 72);
}
.icon-atom .path2:before {
  content: '\e905';
  margin-left: -1em;
  color: rgb(27, 30, 54);
}
.icon-atom .path3:before {
  content: '\e906';
  margin-left: -1em;
  color: rgb(111, 115, 144);
}
.icon-atom .path4:before {
  content: '\e907';
  margin-left: -1em;
  color: rgb(111, 115, 144);
}
.icon-atom .path5:before {
  content: '\e908';
  margin-left: -1em;
  color: rgb(111, 115, 144);
}
.icon-atom .path6:before {
  content: '\e909';
  margin-left: -1em;
  color: rgb(183, 185, 200);
}
.icon-atom .path7:before {
  content: '\e90a';
  margin-left: -1em;
  color: rgb(183, 185, 200);
}
.icon-atom .path8:before {
  content: '\e90b';
  margin-left: -1em;
  color: rgb(183, 185, 200);
}
.icon-atom .path9:before {
  content: '\e90c';
  margin-left: -1em;
  color: rgb(183, 185, 200);
}
.icon-bsv .path1:before {
  content: '\e90d';
  color: rgb(234, 179, 0);
}
.icon-bsv .path2:before {
  content: '\e90e';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-bsv .path3:before {
  content: '\e90f';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-beginner:before {
  content: '\f108';
}
.icon-chart2:before {
  content: '\f10f';
}
.icon-ranking:before {
  content: '\f106';
}
.icon-special:before {
  content: '\f107';
}
.icon-updown:before {
  content: '\f105';
}
.icon-remember:before {
  content: '\f104';
}
.icon-loop:before {
  content: '\ea2d';
}
.icon-next:before {
  content: '\ea19';
}
.icon-forward2:before {
  content: '\ea1b';
}
.icon-play3:before {
  content: '\ea1c';
}
.icon-pause2:before {
  content: '\ea1d';
}
.icon-forward3:before {
  content: '\ea20';
}
.icon-doll:before {
  content: '\e950';
}
.icon-euro:before {
  content: '\e951';
}
.icon-follow_each:before {
  content: '\e952';
}
.icon-picks_buy:before {
  content: '\e953';
}
.icon-picks_sell:before {
  content: '\e954';
}
.icon-yen:before {
  content: '\e955';
}
.icon-crown:before {
  content: '\e808';
}
.icon-yahoo:before {
  content: '\e809';
}
.icon-btn_google_signin_dark_pressed_web:before {
  content: '\e802';
}
.icon-hatenabookmark-logo:before {
  content: '\e803';
}
.icon-news_new:before {
  content: '\e800';
}
.icon-beikoku .path1:before {
  content: '\e600';
  color: rgb(246, 170, 0);
}
.icon-beikoku .path2:before {
  content: '\e601';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-beikoku .path3:before {
  content: '\e602';
  margin-left: -1em;
  color: rgb(246, 170, 0);
}
.icon-beikoku .path4:before {
  content: '\e603';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-beikoku .path5:before {
  content: '\e604';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mincomo .path1:before {
  content: '\e605';
  color: rgb(246, 170, 0);
}
.icon-mincomo .path2:before {
  content: '\e606';
  margin-left: -1em;
  color: rgb(246, 170, 0);
}
.icon-mincomo .path3:before {
  content: '\e607';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mincomo .path4:before {
  content: '\e608';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mincomo .path5:before {
  content: '\e609';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-mintame:before {
  content: '\e60d';
}
.icon-minkabu:before {
  content: '\e60e';
}
.icon-check-alt:before {
  content: '\e60f';
}
.icon-x-altx-alt:before {
  content: '\e610';
}
.icon-denied:before {
  content: '\e611';
}
.icon-plus-alt:before {
  content: '\e612';
}
.icon-minus-alt:before {
  content: '\e613';
}
.icon-new-window:before {
  content: '\e7cc';
}
.icon-arrow-left-alt1:before {
  content: '\e615';
}
.icon-arrow-right-alt1:before {
  content: '\e617';
}
.icon-arrow-up-alt1:before {
  content: '\e619';
}
.icon-arrow-down-alt1:before {
  content: '\e61b';
}
.icon-image:before {
  content: '\e61e';
}
.icon-chart:before {
  content: '\e61f';
}
.icon-bars:before {
  content: '\e621';
}
.icon-alert:before {
  content: '\f02d';
}
.icon-chevron-down:before {
  content: '\f0a3';
}
.icon-chevron-left:before {
  content: '\f0a4';
}
.icon-chevron-right:before {
  content: '\f078';
}
.icon-chevron-up:before {
  content: '\f0a2';
}
.icon-comment:before {
  content: '\f02b';
}
.icon-comment-discussion:before {
  content: '\f04f';
}
.icon-credit-card:before {
  content: '\f045';
}
.icon-diff-added:before {
  content: '\f06b';
}
.icon-diff-removed:before {
  content: '\f06c';
}
.icon-law:before {
  content: '\f0d8';
}
.icon-light-bulb:before {
  content: '\f000';
}
.icon-repo:before {
  content: '\f001';
}
.icon-steps:before {
  content: '\f0c7';
  color: #4b4b4b;
}
.icon-triangle-down:before {
  content: '\f05b';
}
.icon-triangle-left:before {
  content: '\f044';
}
.icon-triangle-right:before {
  content: '\f05a';
}
.icon-triangle-up:before {
  content: '\f0aa';
}
.icon-home:before {
  content: '\e623';
}
.icon-office:before {
  content: '\e626';
}
.icon-newspaper:before {
  content: '\e627';
}
.icon-pencil:before {
  content: '\e628';
}
.icon-pencil2:before {
  content: '\e629';
}
.icon-pen:before {
  content: '\e62b';
}
.icon-image2:before {
  content: '\e630';
}
.icon-bullhorn:before {
  content: '\e63d';
}
.icon-book:before {
  content: '\e642';
}
.icon-file-empty:before {
  content: '\e647';
}
.icon-files-empty:before {
  content: '\e648';
}
.icon-file-text2:before {
  content: '\e649';
}
.icon-copy:before {
  content: '\e64f';
}
.icon-folder-download:before {
  content: '\e656';
}
.icon-folder-upload:before {
  content: '\e657';
}
.icon-price-tag:before {
  content: '\e658';
}
.icon-price-tags:before {
  content: '\e659';
}
.icon-cart:before {
  content: '\e65d';
}
.icon-coin-dollar:before {
  content: '\e65e';
}
.icon-coin-euro:before {
  content: '\e65f';
}
.icon-coin-pound:before {
  content: '\e660';
}
.icon-coin-yen:before {
  content: '\e661';
}
.icon-credit-card2:before {
  content: '\e662';
}
.icon-calculator:before {
  content: '\e663';
}
.icon-phone:before {
  content: '\e665';
}
.icon-alarm:before {
  content: '\e673';
}
.icon-bell:before {
  content: '\e674';
}
.icon-calendar:before {
  content: '\e676';
}
.icon-display:before {
  content: '\e679';
}
.icon-laptop:before {
  content: '\e67a';
}
.icon-mobile2:before {
  content: '\e67c';
}
.icon-tablet:before {
  content: '\e67d';
}
.icon-undo:before {
  content: '\e688';
}
.icon-redo:before {
  content: '\e689';
}
.icon-undo2:before {
  content: '\e68a';
}
.icon-redo2:before {
  content: '\e68b';
}
.icon-forward:before {
  content: '\e68c';
}
.icon-reply:before {
  content: '\e68d';
}
.icon-bubble:before {
  content: '\e68e';
}
.icon-bubbles:before {
  content: '\e68f';
}
.icon-bubbles2:before {
  content: '\e690';
}
.icon-bubble2:before {
  content: '\e691';
}
.icon-bubbles3:before {
  content: '\e692';
}
.icon-bubbles4:before {
  content: '\e693';
}
.icon-spinner3:before {
  content: '\e69f';
}
.icon-spinner11:before {
  content: '\e6a7';
}
.icon-search:before {
  content: '\e6a9';
}
.icon-key:before {
  content: '\e6b0';
}
.icon-lock:before {
  content: '\e6b2';
}
.icon-wrench:before {
  content: '\e6b4';
}
.icon-cog:before {
  content: '\e6b7';
}
.icon-cogs:before {
  content: '\e6b8';
}
.icon-pie-chart:before {
  content: '\e6bd';
}
.icon-stats-dots:before {
  content: '\e6be';
}
.icon-stats-bars:before {
  content: '\e6bf';
}
.icon-stats-bars2:before {
  content: '\e6c0';
}
.icon-trophy:before {
  content: '\e6c1';
}
.icon-gift:before {
  content: '\e6c2';
}
.icon-glass2:before {
  content: '\e6c4';
}
.icon-mug:before {
  content: '\e6c5';
}
.icon-meter:before {
  content: '\e6c9';
}
.icon-bin2:before {
  content: '\e6d0';
  color: #858585;
}
.icon-briefcase:before {
  content: '\e6d1';
}
.icon-clipboard:before {
  content: '\e6db';
}
.icon-list-numbered:before {
  content: '\e6dc';
}
.icon-list:before {
  content: '\e6dd';
}
.icon-menu:before {
  content: '\e6e0';
}
.icon-sphere:before {
  content: '\e6ec';
}
.icon-eye:before {
  content: '\e6f1';
}
.icon-eye-blocked:before {
  content: '\e6f4';
}
.icon-bookmark:before {
  content: '\e6f5';
}
.icon-bookmarks:before {
  content: '\e6f6';
}
.icon-star-empty:before {
  content: '\e6fa';
}
.icon-star-half:before {
  content: '\e6fb';
}
.icon-star-full:before {
  content: '\e956';
}
.icon-heart:before {
  content: '\e6fd';
}
.icon-plus:before {
  content: '\e72d';
}
.icon-minus:before {
  content: '\e72e';
}
.icon-cross:before {
  content: '\e732';
}
.icon-checkmark:before {
  content: '\e733';
}
.icon-checkmark2:before {
  content: '\e734';
}
.icon-enter:before {
  content: '\e736';
}
.icon-exit:before {
  content: '\e737';
}
.icon-loop2:before {
  content: '\e751';
}
.icon-arrow-up-left:before {
  content: '\e754';
}
.icon-arrow-up:before {
  content: '\e755';
}
.icon-arrow-up-right:before {
  content: '\e756';
}
.icon-arrow-right:before {
  content: '\e757';
}
.icon-arrow-down-right:before {
  content: '\e758';
}
.icon-arrow-down:before {
  content: '\e759';
}
.icon-arrow-down-left:before {
  content: '\e75a';
}
.icon-arrow-left:before {
  content: '\e75b';
}
.icon-arrow-up-left2:before {
  content: '\e75c';
}
.icon-arrow-up2:before {
  content: '\e75d';
}
.icon-arrow-up-right2:before {
  content: '\e75e';
}
.icon-arrow-right2:before {
  content: '\e75f';
}
.icon-arrow-down-right2:before {
  content: '\e760';
}
.icon-arrow-down2:before {
  content: '\e761';
}
.icon-arrow-down-left2:before {
  content: '\e762';
}
.icon-arrow-left2:before {
  content: '\e763';
}
.icon-circle-up:before {
  content: '\e764';
}
.icon-circle-right:before {
  content: '\e765';
}
.icon-circle-down:before {
  content: '\e766';
}
.icon-circle-left:before {
  content: '\e767';
}
.icon-sort-alpha-asc:before {
  content: '\e76b';
}
.icon-sort-alpha-desc:before {
  content: '\e76c';
}
.icon-sort-numeric-asc:before {
  content: '\e76d';
}
.icon-sort-numberic-desc:before {
  content: '\e76e';
}
.icon-sort-amount-asc:before {
  content: '\e76f';
}
.icon-sort-amount-desc:before {
  content: '\e770';
}
.icon-checkbox-checked:before {
  content: '\e775';
}
.icon-checkbox-unchecked:before {
  content: '\e776';
}
.icon-radio-checked:before {
  content: '\e777';
}
.icon-radio-checked2:before {
  content: '\e778';
}
.icon-radio-unchecked:before {
  content: '\e779';
}
.icon-share:before {
  content: '\e7a0';
}
.icon-new-tab:before {
  content: '\e7a1';
}
.icon-feed3:before {
  content: '\e7b8';
}
.icon-pdf-file:before {
  content: '\e931';
}
.icon-minna_kasoutsuka:before {
  content: '\e92d';
  color: #ee7800;
}
.icon-minna_kasoutsukakun:before {
  content: '\e92e';
}
.icon-minna_toshin:before {
  content: '\e92f';
  color: #e94709;
}
.icon-minna_toshinkun:before {
  content: '\e930';
  color: #e94709;
}
.icon-cxminkabu:before {
  content: '\e936';
  color: #e4007f;
}
.icon-fxminkabu:before {
  content: '\e923';
  color: #0081cc;
}
.icon-rising:before {
  content: '\e925';
}
.icon-ipo:before {
  content: '\e926';
}
.icon-candlestick:before {
  content: '\e927';
}
.icon-coinpurse:before {
  content: '\e928';
}
.icon-handshake:before {
  content: '\e929';
}
.icon-sprout:before {
  content: '\e92a';
}
.icon-tool:before {
  content: '\e92b';
}
.icon-emoji-happy:before {
  content: '\e942';
}
.icon-emoji-neutral:before {
  content: '\e943';
}
.icon-emoji-sad:before {
  content: '\e944';
}
.icon-thumbs-down:before {
  content: '\e945';
}
.icon-thumbs-up:before {
  content: '\e946';
}
.icon-LINE_Icon:before {
  content: '\e947';
  color: #00b900;
}
.icon-earningrate:before {
  content: '\e948';
}
.icon-bin_full:before {
  content: '\e949';
  color: #858585;
}
.icon-revenueamount:before {
  content: '\e94a';
  color: #4b4b4b;
}
.icon-post:before {
  content: '\e94b';
  color: #4b4b4b;
}
.icon-spinner_stop:before {
  content: '\e94c';
}
.icon-police2:before {
  content: '\e94d';
  color: #b8c2cc;
}
.icon-general:before {
  content: '\e94e';
}
.icon-chevron-right2:before {
  content: '\e94f';
}
.icon-chevron-small-down:before {
  content: '\e704';
}
.icon-chevron-small-left:before {
  content: '\e705';
}
.icon-chevron-small-right:before {
  content: '\e706';
}
.icon-chevron-small-up:before {
  content: '\e707';
}
.icon-chevron-thin-down:before {
  content: '\e708';
}
.icon-chevron-thin-left:before {
  content: '\e709';
}
.icon-chevron-thin-right:before {
  content: '\e70a';
}
.icon-chevron-thin-up:before {
  content: '\e70b';
}
.icon-attachment2:before {
  content: '\e710';
}
.icon-help-with-circle:before {
  content: '\e804';
}
.icon-info-with-circle:before {
  content: '\e805';
}
.icon-language:before {
  content: '\e711';
}
.icon-new:before {
  content: '\e712';
}
.icon-facebook:before {
  content: '\e806';
}
.icon-twitter:before {
  content: '\e807';
}
.icon-btt:before {
  content: '\e910';
}
.icon-celr:before {
  content: '\e911';
}
.icon-matic .path1:before {
  content: '\e914';
  color: rgb(127, 71, 226);
}
.icon-matic .path2:before {
  content: '\e915';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path3:before {
  content: '\e916';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path4:before {
  content: '\e917';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path5:before {
  content: '\e918';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path6:before {
  content: '\e919';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path7:before {
  content: '\e91a';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path8:before {
  content: '\eb89';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path9:before {
  content: '\eb8a';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path10:before {
  content: '\eb8b';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path11:before {
  content: '\eb8c';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path12:before {
  content: '\eb8d';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path13:before {
  content: '\eb8e';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path14:before {
  content: '\eb8f';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path15:before {
  content: '\eb90';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path16:before {
  content: '\eb91';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path17:before {
  content: '\eb92';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path18:before {
  content: '\eb93';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path19:before {
  content: '\eb94';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path20:before {
  content: '\eb95';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path21:before {
  content: '\eb96';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path22:before {
  content: '\eb97';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path23:before {
  content: '\eb98';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path24:before {
  content: '\eb99';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path25:before {
  content: '\eb9a';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path26:before {
  content: '\eb9b';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path27:before {
  content: '\eb9c';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path28:before {
  content: '\eb9d';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path29:before {
  content: '\eb9e';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path30:before {
  content: '\eb9f';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path31:before {
  content: '\eba0';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path32:before {
  content: '\eba1';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path33:before {
  content: '\eba2';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path34:before {
  content: '\eba3';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path35:before {
  content: '\eba4';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path36:before {
  content: '\eba5';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path37:before {
  content: '\eba6';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path38:before {
  content: '\eba7';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path39:before {
  content: '\eba8';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path40:before {
  content: '\eba9';
  margin-left: -1em;
  color: rgb(173, 137, 238);
}
.icon-matic .path41:before {
  content: '\ebaa';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path42:before {
  content: '\ebab';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path43:before {
  content: '\ebac';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path44:before {
  content: '\ebad';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path45:before {
  content: '\ebae';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path46:before {
  content: '\ebaf';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path47:before {
  content: '\ebb0';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path48:before {
  content: '\ebb1';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path49:before {
  content: '\ebb2';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path50:before {
  content: '\ebb3';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path51:before {
  content: '\ebb4';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path52:before {
  content: '\ebb5';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path53:before {
  content: '\ebb6';
  margin-left: -1em;
  color: rgb(173, 137, 238);
}
.icon-matic .path54:before {
  content: '\ebb7';
  margin-left: -1em;
  color: rgb(254, 254, 254);
}
.icon-matic .path55:before {
  content: '\ebb8';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path56:before {
  content: '\ebb9';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-matic .path57:before {
  content: '\ebba';
  margin-left: -1em;
  color: rgb(226, 213, 249);
}
.icon-usdc .path1:before {
  content: '\e91b';
  color: rgb(39, 117, 202);
}
.icon-usdc .path2:before {
  content: '\e91c';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-usdc .path3:before {
  content: '\e91d';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ustd .path1:before {
  content: '\e91e';
  color: rgb(38, 161, 123);
}
.icon-ustd .path2:before {
  content: '\e91f';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-xtz:before {
  content: '\e920';
  color: #2c7df6;
}
.icon-upicon:before {
  content: '\e921';
}
.icon-rank1:before {
  content: '\e922';
}
.icon-rank2:before {
  content: '\e924';
}
.icon-rank3:before {
  content: '\e92c';
}
.icon-rank4:before {
  content: '\e932';
}
.icon-rank5:before {
  content: '\e933';
}
.icon-rank6:before {
  content: '\e934';
}
.icon-rank7:before {
  content: '\e935';
}
.icon-rank8:before {
  content: '\e937';
}
.icon-rank9:before {
  content: '\e938';
}
.icon-rank10:before {
  content: '\e939';
}
.icon-users:before {
  content: '\e93a';
}
.icon-user:before {
  content: '\e93b';
}
.icon-minkabu_co_logo:before {
  content: '\e93c';
}
.icon-minkabu_logo:before {
  content: '\e93d';
  color: #e60012;
}
.icon-mountain_chart:before {
  content: '\e93e';
}
.icon-line_chart:before {
  content: '\e93f';
}
.icon-envelope:before {
  content: '\e940';
  color: #4b4b4b;
}
.icon-envelope2:before {
  content: '\e941';
  color: #4b4b4b;
}
.icon-more_vert:before {
  content: '\e5d4';
}
.icon-tune:before {
  content: '\e429';
}
.icon-cake1:before {
  content: '\e7e9';
}
.icon-merge_type:before {
  content: '\e252';
}
.icon-call_split:before {
  content: '\e0b6';
}
.icon-menu2:before {
  content: '\e5d2';
}
.icon-check:before {
  content: '\e206';
}
.icon-angle-double-left:before {
  content: '\f100';
}
.icon-angle-double-right:before {
  content: '\f101';
}
.icon-angle-double-up:before {
  content: '\f102';
}
.icon-angle-double-down:before {
  content: '\f103';
}
.icon-sort_down .path1:before {
  content: '\eb2a';
  color: rgb(51, 51, 51);
}
.icon-sort_down .path2:before {
  content: '\eb2b';
  margin-left: -0.625em;
  color: rgb(153, 153, 153);
}
.icon-sort_up .path1:before {
  content: '\eb2c';
  color: rgb(153, 153, 153);
}
.icon-sort_up .path2:before {
  content: '\eb2d';
  margin-left: -0.625em;
  color: rgb(51, 51, 51);
}
.icon-sort:before {
  content: '\eb2e';
  color: #999;
}
.icon-augur .path1:before {
  content: '\ea99';
  color: rgb(95, 38, 82);
}
.icon-augur .path2:before {
  content: '\eb28';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-bnb .path1:before {
  content: "\ea01";
  color: rgb(240, 185, 11);
}
.icon-bnb .path2:before {
  content: "\efeb";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-bnb .path3:before {
  content: "\efec";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-bnb .path4:before {
  content: "\efed";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-bnb .path5:before {
  content: "\efee";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-bts:before {
  content: '\e9f6';
  color: #35baeb;
}
.icon-ada:before {
  content: '\efb6';
  color: #0033ad;
}
.icon-link .path1:before {
  content: '\e9da';
  color: rgb(231, 231, 233);
}
.icon-link .path2:before {
  content: '\e9db';
  margin-left: -0.8671875em;
  color: rgb(63, 160, 241);
}
.icon-link .path3:before {
  content: '\e9dc';
  margin-left: -0.8671875em;
  color: rgb(0, 0, 0);
}
.icon-link .path4:before {
  content: '\e9dd';
  margin-left: -0.8671875em;
  color: rgb(0, 0, 0);
}
.icon-link .path5:before {
  content: '\e9de';
  margin-left: -0.8671875em;
  color: rgb(0, 0, 0);
}
.icon-link .path6:before {
  content: '\e9df';
  margin-left: -0.8671875em;
  color: rgb(111, 157, 206);
}
.icon-link .path7:before {
  content: '\e9e0';
  margin-left: -0.8671875em;
  color: rgb(111, 157, 206);
}
.icon-link .path8:before {
  content: '\e9e1';
  margin-left: -0.8671875em;
  color: rgb(111, 157, 206);
}
.icon-link .path9:before {
  content: '\e9e2';
  margin-left: -0.8671875em;
  color: rgb(111, 157, 206);
}
.icon-link .path10:before {
  content: '\e9e3';
  margin-left: -0.8671875em;
  color: rgb(111, 157, 206);
}
.icon-link .path11:before {
  content: '\e9e4';
  margin-left: -0.8671875em;
  color: rgb(111, 157, 206);
}
.icon-link .path12:before {
  content: '\e9e5';
  margin-left: -0.8671875em;
  color: rgb(0, 0, 0);
}
.icon-link .path13:before {
  content: '\e9e6';
  margin-left: -0.8671875em;
  color: rgb(0, 0, 0);
}
.icon-link .path14:before {
  content: '\e9e7';
  margin-left: -0.8671875em;
  color: rgb(63, 160, 241);
}
.icon-link .path15:before {
  content: '\e9e8';
  margin-left: -0.8671875em;
  color: rgb(255, 255, 255);
}
.icon-link .path16:before {
  content: '\e9e9';
  margin-left: -0.8671875em;
  color: rgb(63, 160, 241);
}
.icon-link .path17:before {
  content: '\e9ea';
  margin-left: -0.8671875em;
  color: rgb(63, 160, 241);
}
.icon-link .path18:before {
  content: '\e9eb';
  margin-left: -0.8671875em;
  color: rgb(44, 108, 234);
}
.icon-link .path19:before {
  content: '\e9ec';
  margin-left: -0.8671875em;
  color: rgb(44, 108, 234);
}
.icon-link .path20:before {
  content: '\e9ed';
  margin-left: -0.8671875em;
  color: rgb(0, 0, 0);
}
.icon-link .path21:before {
  content: '\e9ee';
  margin-left: -0.8671875em;
  color: rgb(0, 0, 0);
}
.icon-link .path22:before {
  content: '\e9ef';
  margin-left: -0.8671875em;
  color: rgb(0, 0, 0);
}
.icon-link .path23:before {
  content: '\e9f0';
  margin-left: -0.8671875em;
  color: rgb(0, 0, 0);
}
.icon-link .path24:before {
  content: '\e9f1';
  margin-left: -0.8671875em;
  color: rgb(0, 0, 0);
}
.icon-link .path25:before {
  content: '\e9f2';
  margin-left: -0.8671875em;
  color: rgb(0, 0, 0);
}
.icon-link .path26:before {
  content: '\e9f3';
  margin-left: -0.8671875em;
  color: rgb(0, 0, 0);
}
.icon-link .path27:before {
  content: '\e9f4';
  margin-left: -0.8671875em;
  color: rgb(0, 0, 0);
}
.icon-link .path28:before {
  content: '\e9f5';
  margin-left: -0.8671875em;
  color: rgb(0, 0, 0);
}
.icon-doge .path1:before {
  content: '\ea04';
  color: rgb(152, 132, 48);
}
.icon-doge .path2:before {
  content: '\ea05';
  margin-left: -1em;
  color: rgb(122, 106, 42);
}
.icon-doge .path3:before {
  content: '\ea06';
  margin-left: -1em;
  color: rgb(186, 159, 51);
}
.icon-doge .path4:before {
  content: '\ea07';
  margin-left: -1em;
  color: rgb(226, 204, 133);
}
.icon-doge .path5:before {
  content: '\ea08';
  margin-left: -1em;
  color: rgb(241, 215, 137);
}
.icon-doge .path6:before {
  content: '\ea09';
  margin-left: -1em;
  color: rgb(244, 236, 180);
}
.icon-doge .path7:before {
  content: '\ea0a';
  margin-left: -1em;
  color: rgb(243, 225, 157);
}
.icon-doge .path8:before {
  content: '\ea0b';
  margin-left: -1em;
  color: rgb(242, 232, 176);
}
.icon-doge .path9:before {
  content: '\ea0c';
  margin-left: -1em;
  color: rgb(245, 238, 192);
}
.icon-doge .path10:before {
  content: '\ea0d';
  margin-left: -1em;
  color: rgb(230, 219, 157);
}
.icon-doge .path11:before {
  content: '\ea0e';
  margin-left: -1em;
  color: rgb(229, 203, 122);
}
.icon-doge .path12:before {
  content: '\ea0f';
  margin-left: -1em;
  color: rgb(216, 193, 115);
}
.icon-doge .path13:before {
  content: '\ea10';
  margin-left: -1em;
  color: rgb(241, 215, 137);
}
.icon-doge .path14:before {
  content: '\ea11';
  margin-left: -1em;
  color: rgb(224, 205, 129);
}
.icon-doge .path15:before {
  content: '\ea12';
  margin-left: -1em;
  color: rgb(223, 197, 124);
}
.icon-doge .path16:before {
  content: '\ea13';
  margin-left: -1em;
  color: rgb(229, 204, 124);
}
.icon-doge .path17:before {
  content: '\ea14';
  margin-left: -1em;
  color: rgb(210, 194, 129);
}
.icon-doge .path18:before {
  content: '\ea15';
  margin-left: -1em;
  color: rgb(226, 194, 112);
}
.icon-doge .path19:before {
  content: '\ea16';
  margin-left: -1em;
  color: rgb(204, 179, 96);
}
.icon-doge .path20:before {
  content: '\ea17';
  margin-left: -1em;
  color: rgb(213, 180, 87);
}
.icon-doge .path21:before {
  content: '\ea18';
  margin-left: -1em;
  color: rgb(210, 178, 87);
}
.icon-doge .path22:before {
  content: '\ea1a';
  margin-left: -1em;
  color: rgb(210, 177, 89);
}
.icon-doge .path23:before {
  content: '\ea1e';
  margin-left: -1em;
  color: rgb(194, 164, 77);
}
.icon-doge .path24:before {
  content: '\ea1f';
  margin-left: -1em;
  color: rgb(202, 161, 62);
}
.icon-doge .path25:before {
  content: '\ea21';
  margin-left: -1em;
  color: rgb(181, 149, 68);
}
.icon-doge .path26:before {
  content: '\ea22';
  margin-left: -1em;
  color: rgb(200, 157, 58);
}
.icon-doge .path27:before {
  content: '\ea23';
  margin-left: -1em;
  color: rgb(206, 176, 82);
}
.icon-doge .path28:before {
  content: '\ea24';
  margin-left: -1em;
  color: rgb(204, 168, 71);
}
.icon-doge .path29:before {
  content: '\ea25';
  margin-left: -1em;
  color: rgb(195, 157, 60);
}
.icon-doge .path30:before {
  content: '\ea26';
  margin-left: -1em;
  color: rgb(210, 186, 107);
}
.icon-doge .path31:before {
  content: '\ea27';
  margin-left: -1em;
  color: rgb(192, 153, 58);
}
.icon-doge .path32:before {
  content: '\ea28';
  margin-left: -1em;
  color: rgb(168, 143, 51);
}
.icon-doge .path33:before {
  content: '\ea29';
  margin-left: -1em;
  color: rgb(227, 197, 113);
}
.icon-doge .path34:before {
  content: '\ea2a';
  margin-left: -1em;
  color: rgb(216, 182, 91);
}
.icon-doge .path35:before {
  content: '\ea2b';
  margin-left: -1em;
  color: rgb(211, 174, 77);
}
.icon-doge .path36:before {
  content: '\ea2c';
  margin-left: -1em;
  color: rgb(202, 161, 62);
}
.icon-doge .path37:before {
  content: '\ea2e';
  margin-left: -1em;
  color: rgb(244, 239, 200);
}
.icon-doge .path38:before {
  content: '\ea2f';
  margin-left: -1em;
  color: rgb(235, 204, 115);
}
.icon-doge .path39:before {
  content: '\ea30';
  margin-left: -1em;
  color: rgb(229, 198, 107);
}
.icon-doge .path40:before {
  content: '\ea31';
  margin-left: -1em;
  color: rgb(230, 189, 98);
}
.icon-doge .path41:before {
  content: '\ea32';
  margin-left: -1em;
  color: rgb(182, 147, 63);
}
.icon-doge .path42:before {
  content: '\ea33';
  margin-left: -1em;
  color: rgb(196, 153, 55);
}
.icon-doge .path43:before {
  content: '\ea34';
  margin-left: -1em;
  color: rgb(223, 192, 104);
}
.icon-doge .path44:before {
  content: '\ea35';
  margin-left: -1em;
  color: rgb(168, 143, 51);
}
.icon-doge .path45:before {
  content: '\ea36';
  margin-left: -1em;
  color: rgb(201, 158, 61);
}
.icon-doge .path46:before {
  content: '\ea37';
  margin-left: -1em;
  color: rgb(181, 134, 52);
}
.icon-doge .path47:before {
  content: '\ea38';
  margin-left: -1em;
  color: rgb(168, 143, 51);
}
.icon-doge .path48:before {
  content: '\ea39';
  margin-left: -1em;
  color: rgb(230, 195, 103);
}
.icon-doge .path49:before {
  content: '\ea3a';
  margin-left: -1em;
  color: rgb(220, 186, 90);
}
.icon-doge .path50:before {
  content: '\ea3b';
  margin-left: -1em;
  color: rgb(233, 206, 119);
}
.icon-doge .path51:before {
  content: '\ea3c';
  margin-left: -1em;
  color: rgb(205, 174, 80);
}
.icon-doge .path52:before {
  content: '\ea3d';
  margin-left: -1em;
  color: rgb(210, 168, 62);
}
.icon-doge .path53:before {
  content: '\ea3e';
  margin-left: -1em;
  color: rgb(194, 150, 55);
}
.icon-doge .path54:before {
  content: '\ea3f';
  margin-left: -1em;
  color: rgb(172, 129, 50);
}
.icon-doge .path55:before {
  content: '\ea40';
  margin-left: -1em;
  color: rgb(168, 143, 51);
}
.icon-doge .path56:before {
  content: '\ea41';
  margin-left: -1em;
  color: rgb(166, 122, 46);
}
.icon-doge .path57:before {
  content: '\ea42';
  margin-left: -1em;
  color: rgb(140, 98, 40);
}
.icon-doge .path58:before {
  content: '\ea43';
  margin-left: -1em;
  color: rgb(203, 169, 76);
}
.icon-doge .path59:before {
  content: '\ea44';
  margin-left: -1em;
  color: rgb(168, 143, 51);
}
.icon-doge .path60:before {
  content: '\ea45';
  margin-left: -1em;
  color: rgb(190, 156, 68);
}
.icon-doge .path61:before {
  content: '\ea46';
  margin-left: -1em;
  color: rgb(161, 124, 52);
}
.icon-doge .path62:before {
  content: '\ea47';
  margin-left: -1em;
  color: rgb(140, 98, 40);
}
.icon-doge .path63:before {
  content: '\ea48';
  margin-left: -1em;
  color: rgb(146, 114, 58);
}
.icon-doge .path64:before {
  content: '\ea49';
  margin-left: -1em;
  color: rgb(177, 142, 62);
}
.icon-doge .path65:before {
  content: '\ea4a';
  margin-left: -1em;
  color: rgb(84, 62, 38);
}
.icon-doge .path66:before {
  content: '\ea4b';
  margin-left: -1em;
  color: rgb(128, 96, 49);
}
.icon-doge .path67:before {
  content: '\ea4c';
  margin-left: -1em;
  color: rgb(182, 144, 60);
}
.icon-doge .path68:before {
  content: '\ea4d';
  margin-left: -1em;
  color: rgb(104, 80, 38);
}
.icon-doge .path69:before {
  content: '\ea4e';
  margin-left: -1em;
  color: rgb(212, 185, 104);
}
.icon-doge .path70:before {
  content: '\ea4f';
  margin-left: -1em;
  color: rgb(204, 168, 73);
}
.icon-doge .path71:before {
  content: '\ea50';
  margin-left: -1em;
  color: rgb(88, 81, 54);
}
.icon-doge .path72:before {
  content: '\ea51';
  margin-left: -1em;
  color: rgb(37, 34, 17);
}
.icon-doge .path73:before {
  content: '\ea52';
  margin-left: -1em;
  color: rgb(195, 179, 120);
}
.icon-doge .path74:before {
  content: '\ea53';
  margin-left: -1em;
  color: rgb(230, 216, 156);
}
.icon-doge .path75:before {
  content: '\ea54';
  margin-left: -1em;
  color: rgb(37, 34, 17);
}
.icon-doge .path76:before {
  content: '\ea55';
  margin-left: -1em;
  color: rgb(111, 103, 77);
}
.icon-doge .path77:before {
  content: '\ea56';
  margin-left: -1em;
  color: rgb(172, 146, 76);
}
.icon-doge .path78:before {
  content: '\ea57';
  margin-left: -1em;
  color: rgb(159, 142, 87);
}
.icon-doge .path79:before {
  content: '\ea58';
  margin-left: -1em;
  color: rgb(234, 201, 112);
}
.icon-doge .path80:before {
  content: '\ea59';
  margin-left: -1em;
  color: rgb(215, 188, 107);
}
.icon-doge .path81:before {
  content: '\ea5a';
  margin-left: -1em;
  color: rgb(88, 81, 54);
}
.icon-doge .path82:before {
  content: '\ea5b';
  margin-left: -1em;
  color: rgb(69, 58, 37);
}
.icon-doge .path83:before {
  content: '\ea5c';
  margin-left: -1em;
  color: rgb(132, 117, 75);
}
.icon-doge .path84:before {
  content: '\ea5d';
  margin-left: -1em;
  color: rgb(37, 34, 17);
}
.icon-doge .path85:before {
  content: '\ea5e';
  margin-left: -1em;
  color: rgb(247, 247, 231);
}
.icon-doge .path86:before {
  content: '\ea5f';
  margin-left: -1em;
  color: rgb(248, 246, 222);
}
.icon-doge .path87:before {
  content: '\ea60';
  margin-left: -1em;
  color: rgb(212, 195, 139);
}
.icon-doge .path88:before {
  content: '\ea61';
  margin-left: -1em;
  color: rgb(226, 213, 157);
}
.icon-doge .path89:before {
  content: '\ea62';
  margin-left: -1em;
  color: rgb(203, 172, 90);
}
.icon-doge .path90:before {
  content: '\ea63';
  margin-left: -1em;
  color: rgb(214, 188, 111);
}
.icon-doge .path91:before {
  content: '\ea64';
  margin-left: -1em;
  color: rgb(223, 198, 119);
}
.icon-doge .path92:before {
  content: '\ea65';
  margin-left: -1em;
  color: rgb(196, 168, 89);
}
.icon-doge .path93:before {
  content: '\ea66';
  margin-left: -1em;
  color: rgb(146, 125, 73);
}
.icon-doge .path94:before {
  content: '\ea67';
  margin-left: -1em;
  color: rgb(110, 99, 66);
}
.icon-doge .path95:before {
  content: '\ea68';
  margin-left: -1em;
  color: rgb(57, 51, 31);
}
.icon-doge .path96:before {
  content: '\ea69';
  margin-left: -1em;
  color: rgb(41, 35, 17);
}
.icon-doge .path97:before {
  content: '\ea6a';
  margin-left: -1em;
  color: rgb(37, 34, 17);
}
.icon-doge .path98:before {
  content: '\ea6b';
  margin-left: -1em;
  color: rgb(63, 56, 36);
}
.icon-doge .path99:before {
  content: '\ea6c';
  margin-left: -1em;
  color: rgb(45, 40, 21);
}
.icon-doge .path100:before {
  content: '\ea6d';
  margin-left: -1em;
  color: rgb(130, 118, 79);
}
.icon-doge .path101:before {
  content: '\ea6e';
  margin-left: -1em;
  color: rgb(45, 40, 22);
}
.icon-doge .path102:before {
  content: '\ea6f';
  margin-left: -1em;
  color: rgb(87, 79, 53);
}
.icon-doge .path103:before {
  content: '\ea70';
  margin-left: -1em;
  color: rgb(130, 118, 79);
}
.icon-doge .path104:before {
  content: '\ea71';
  margin-left: -1em;
  color: rgb(110, 99, 66);
}
.icon-doge .path105:before {
  content: '\ea72';
  margin-left: -1em;
  color: rgb(88, 81, 54);
}
.icon-doge .path106:before {
  content: '\ea73';
  margin-left: -1em;
  color: rgb(87, 79, 53);
}
.icon-doge .path107:before {
  content: '\ea74';
  margin-left: -1em;
  color: rgb(130, 118, 79);
}
.icon-doge .path108:before {
  content: '\ea75';
  margin-left: -1em;
  color: rgb(110, 99, 66);
}
.icon-doge .path109:before {
  content: '\ea76';
  margin-left: -1em;
  color: rgb(88, 81, 54);
}
.icon-doge .path110:before {
  content: '\ea77';
  margin-left: -1em;
  color: rgb(87, 79, 53);
}
.icon-doge .path111:before {
  content: '\ea78';
  margin-left: -1em;
  color: rgb(37, 34, 17);
}
.icon-doge .path112:before {
  content: '\ea79';
  margin-left: -1em;
  color: rgb(207, 182, 108);
}
.icon-doge .path113:before {
  content: '\ea7a';
  margin-left: -1em;
  color: rgb(207, 182, 108);
}
.icon-doge .path114:before {
  content: '\ea7b';
  margin-left: -1em;
  color: rgb(207, 182, 108);
}
.icon-doge .path115:before {
  content: '\ea7c';
  margin-left: -1em;
  color: rgb(207, 182, 108);
}
.icon-doge .path116:before {
  content: '\ea7d';
  margin-left: -1em;
  color: rgb(207, 182, 108);
}
.icon-doge .path117:before {
  content: '\ea7e';
  margin-left: -1em;
  color: rgb(207, 182, 108);
}
.icon-doge .path118:before {
  content: '\ea7f';
  margin-left: -1em;
  color: rgb(207, 182, 108);
}
.icon-doge .path119:before {
  content: '\ea80';
  margin-left: -1em;
  color: rgb(207, 182, 108);
}
.icon-doge .path120:before {
  content: '\ea81';
  margin-left: -1em;
  color: rgb(222, 194, 112);
}
.icon-doge .path121:before {
  content: '\ea82';
  margin-left: -1em;
  color: rgb(203, 175, 89);
}
.icon-doge .path122:before {
  content: '\ea83';
  margin-left: -1em;
  color: rgb(227, 198, 119);
}
.icon-doge .path123:before {
  content: '\ea84';
  margin-left: -1em;
  color: rgb(236, 213, 146);
}
.icon-doge .path124:before {
  content: '\ea85';
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.8;
}
.icon-gas .path1:before {
  content: '\e9d8';
  color: rgb(115, 179, 45);
}
.icon-gas .path2:before {
  content: '\e9d9';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icx:before {
  content: '\ea9c';
  color: #01c4c8;
}
.icon-iota:before {
  content: '\ea9d';
}
.icon-knc .path1:before {
  content: '\eafb';
  color: rgb(37, 150, 143);
}
.icon-knc .path2:before {
  content: '\eafc';
  margin-left: -0.5615234375em;
  color: rgb(189, 219, 213);
}
.icon-knc .path3:before {
  content: '\eafd';
  margin-left: -0.5615234375em;
  color: rgb(237, 246, 241);
}
.icon-knc .path4:before {
  content: '\eafe';
  margin-left: -0.5615234375em;
  color: rgb(107, 184, 160);
}
.icon-knc .path5:before {
  content: '\eaff';
  margin-left: -0.5615234375em;
  color: rgb(255, 255, 255);
}
.icon-knc .path6:before {
  content: '\eb00';
  margin-left: -0.5615234375em;
  color: rgb(255, 255, 255);
}
.icon-knc .path7:before {
  content: '\eb01';
  margin-left: -0.5615234375em;
  color: rgb(255, 255, 255);
}
.icon-knc .path8:before {
  content: '\eb02';
  margin-left: -0.5615234375em;
  color: rgb(28, 38, 52);
}
.icon-knc .path9:before {
  content: '\eb03';
  margin-left: -0.5615234375em;
  color: rgb(255, 255, 255);
}
.icon-knc .path10:before {
  content: '\eb04';
  margin-left: -0.5615234375em;
  color: rgb(28, 38, 52);
}
.icon-knc .path11:before {
  content: '\eb05';
  margin-left: -0.5615234375em;
  color: rgb(255, 255, 255);
}
.icon-knc .path12:before {
  content: '\eb06';
  margin-left: -0.5615234375em;
  color: rgb(28, 38, 52);
}
.icon-knc .path13:before {
  content: '\eb07';
  margin-left: -0.5615234375em;
  color: rgb(255, 255, 255);
}
.icon-knc .path14:before {
  content: '\eb08';
  margin-left: -0.5615234375em;
  color: rgb(28, 38, 52);
}
.icon-knc .path15:before {
  content: '\eb09';
  margin-left: -0.5615234375em;
  color: rgb(255, 255, 255);
}
.icon-knc .path16:before {
  content: '\eb0a';
  margin-left: -0.5615234375em;
  color: rgb(28, 38, 52);
}
.icon-knc .path17:before {
  content: '\eb0b';
  margin-left: -0.5615234375em;
  color: rgb(255, 255, 255);
}
.icon-knc .path18:before {
  content: '\eb0c';
  margin-left: -0.5615234375em;
  color: rgb(28, 38, 52);
}
.icon-knc .path19:before {
  content: '\eb0d';
  margin-left: -0.5615234375em;
  color: rgb(255, 255, 255);
}
.icon-knc .path20:before {
  content: '\eb0e';
  margin-left: -0.5615234375em;
  color: rgb(28, 38, 52);
}
.icon-knc .path21:before {
  content: '\eb0f';
  margin-left: -0.5615234375em;
  color: rgb(255, 255, 255);
}
.icon-lrc .path1:before {
  content: '\ead5';
  color: rgb(60, 60, 60);
}
.icon-lrc .path2:before {
  content: '\ead6';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-mkr .path1:before {
  content: '\eada';
  color: rgb(27, 188, 154);
}
.icon-mkr .path2:before {
  content: '\eadb';
  margin-left: -1em;
  color: rgb(84, 105, 122);
}
.icon-xno .path1:before {
  content: '\eb16';
  color: rgb(32, 154, 231);
}
.icon-xno .path2:before {
  content: '\eb17';
  margin-left: -1em;
  color: rgb(98, 185, 238);
}
.icon-xno .path3:before {
  content: '\eb18';
  margin-left: -1em;
  color: rgb(98, 185, 239);
}
.icon-xno .path4:before {
  content: '\eb19';
  margin-left: -1em;
  color: rgb(100, 186, 238);
}
.icon-xno .path5:before {
  content: '\eb1a';
  margin-left: -1em;
  color: rgb(100, 186, 239);
}
.icon-xno .path6:before {
  content: '\eb1b';
  margin-left: -1em;
  color: rgb(159, 212, 244);
}
.icon-xno .path7:before {
  content: '\eb1c';
  margin-left: -1em;
  color: rgb(187, 224, 247);
}
.icon-xno .path8:before {
  content: '\eb1d';
  margin-left: -1em;
  color: rgb(154, 210, 244);
}
.icon-xno .path9:before {
  content: '\edc5';
  margin-left: -1em;
  color: rgb(187, 224, 247);
}
.icon-xno .path10:before {
  content: '\edc6';
  margin-left: -1em;
  color: rgb(254, 254, 254);
}
.icon-xno .path11:before {
  content: '\edc7';
  margin-left: -1em;
  color: rgb(32, 155, 232);
}
.icon-xno .path12:before {
  content: '\edc8';
  margin-left: -1em;
  color: rgb(32, 154, 231);
}
.icon-xno .path13:before {
  content: '\edc9';
  margin-left: -1em;
  color: rgb(32, 154, 231);
}
.icon-ont .path1:before {
  content: '\eae1';
  color: rgb(71, 163, 190);
}
.icon-ont .path2:before {
  content: '\eae2';
  margin-left: -1em;
  color: rgb(252, 253, 251);
}
.icon-ont .path3:before {
  content: '\eae3';
  margin-left: -1em;
  color: rgb(252, 253, 251);
}
.icon-req .path1:before {
  content: '\e9aa';
  color: rgb(0, 140, 98);
}
.icon-req .path2:before {
  content: '\e9ab';
  margin-left: -0.9189453125em;
  color: rgb(0, 230, 160);
}
.icon-icon-salt .path1:before {
  content: '\e996';
  color: rgb(52, 165, 169);
}
.icon-icon-salt .path2:before {
  content: '\e997';
  margin-left: -0.9501953125em;
  color: rgb(52, 165, 169);
}
.icon-icon-salt .path3:before {
  content: '\e998';
  margin-left: -0.9501953125em;
  color: rgb(59, 157, 162);
}
.icon-icon-salt .path4:before {
  content: '\e999';
  margin-left: -0.9501953125em;
  color: rgb(59, 157, 162);
}
.icon-icon-salt .path5:before {
  content: '\e99a';
  margin-left: -0.9501953125em;
  color: rgb(68, 118, 122);
}
.icon-icon-salt .path6:before {
  content: '\e99b';
  margin-left: -0.9501953125em;
  color: rgb(95, 119, 127);
}
.icon-icon-salt .path7:before {
  content: '\e99c';
  margin-left: -0.9501953125em;
  color: rgb(95, 119, 127);
}
.icon-icon-salt .path8:before {
  content: '\e99d';
  margin-left: -0.9501953125em;
  color: rgb(59, 157, 162);
}
.icon-icon-salt .path9:before {
  content: '\e99e';
  margin-left: -0.9501953125em;
  color: rgb(59, 157, 162);
}
.icon-icon-salt .path10:before {
  content: '\e99f';
  margin-left: -0.9501953125em;
  color: rgb(61, 154, 159);
}
.icon-icon-salt .path11:before {
  content: '\e9a0';
  margin-left: -0.9501953125em;
  color: rgb(61, 154, 159);
}
.icon-icon-salt .path12:before {
  content: '\e9a1';
  margin-left: -0.9501953125em;
  color: rgb(67, 148, 153);
}
.icon-icon-salt .path13:before {
  content: '\e9a2';
  margin-left: -0.9501953125em;
  color: rgb(67, 148, 153);
}
.icon-icon-salt .path14:before {
  content: '\e9a3';
  margin-left: -0.9501953125em;
  color: rgb(255, 255, 255);
}
.icon-icon-salt .path15:before {
  content: '\e9a4';
  margin-left: -0.9501953125em;
  color: rgb(71, 144, 150);
}
.icon-icon-salt .path16:before {
  content: '\e9a5';
  margin-left: -0.9501953125em;
  color: rgb(77, 137, 144);
}
.icon-icon-salt .path17:before {
  content: '\e9a6';
  margin-left: -0.9501953125em;
  color: rgb(78, 135, 142);
}
.icon-icon-salt .path18:before {
  content: '\e9a7';
  margin-left: -0.9501953125em;
  color: rgb(40, 186, 188);
}
.icon-icon-salt .path19:before {
  content: '\e9a8';
  margin-left: -0.9501953125em;
  color: rgb(45, 173, 176);
}
.icon-icon-salt .path20:before {
  content: '\e9a9';
  margin-left: -0.9501953125em;
  color: rgb(45, 173, 176);
}
.icon-snt .path1:before {
  content: '\eae4';
  color: rgb(91, 109, 238);
}
.icon-snt .path2:before {
  content: '\eae5';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-steem .path1:before {
  content: '\eae6';
  color: rgb(22, 80, 152);
}
.icon-steem .path2:before {
  content: '\eae7';
  margin-left: -0.94140625em;
  color: rgb(92, 157, 213);
}
.icon-xlm:before {
  content: '\e9b3';
}
.icon-storm .path1:before {
  content: '\e957';
  color: rgb(0, 37, 110);
}
.icon-storm .path2:before {
  content: '\e975';
  margin-left: -1em;
  color: rgb(2, 35, 98);
}
.icon-storm .path3:before {
  content: '\e976';
  margin-left: -1em;
  color: rgb(253, 181, 77);
}
.icon-icon-sys:before {
  content: '\e993';
  color: #0082c6;
}
.icon-trx:before {
  content: '\e962';
  color: #ff060a;
}
.icon-ven .path1:before {
  content: '\e9b8';
  color: rgb(159, 123, 207);
}
.icon-ven .path2:before {
  content: '\e9b9';
  margin-left: -1.06640625em;
  color: rgb(139, 133, 213);
}
.icon-ven .path3:before {
  content: '\e9ba';
  margin-left: -1.06640625em;
  color: rgb(31, 35, 28);
  opacity: 0.055;
}
.icon-ven .path4:before {
  content: '\e9bb';
  margin-left: -1.06640625em;
  color: rgb(21, 25, 28);
  opacity: 0.055;
}
.icon-ven .path5:before {
  content: '\e9bc';
  margin-left: -1.06640625em;
  color: rgb(0, 0, 0);
}
.icon-ven .path6:before {
  content: '\e9bd';
  margin-left: -1.06640625em;
  color: rgb(0, 0, 0);
}
.icon-ven .path7:before {
  content: '\e9be';
  margin-left: -1.06640625em;
  color: rgb(0, 0, 0);
}
.icon-ven .path8:before {
  content: '\e9bf';
  margin-left: -1.06640625em;
  color: rgb(0, 0, 0);
}
.icon-ven .path9:before {
  content: '\e9c0';
  margin-left: -1.06640625em;
  color: rgb(135, 123, 197);
}
.icon-ven .path10:before {
  content: '\e9c1';
  margin-left: -1.06640625em;
  color: rgb(31, 35, 28);
  opacity: 0.055;
}
.icon-ven .path11:before {
  content: '\e9c2';
  margin-left: -1.06640625em;
  color: rgb(0, 0, 0);
}
.icon-ven .path12:before {
  content: '\e9c3';
  margin-left: -1.06640625em;
  color: rgb(31, 35, 28);
  opacity: 0.055;
}
.icon-ven .path13:before {
  content: '\e9c4';
  margin-left: -1.06640625em;
  color: rgb(0, 0, 0);
}
.icon-ven .path14:before {
  content: '\e9c5';
  margin-left: -1.06640625em;
  color: rgb(21, 25, 28);
  opacity: 0.055;
}
.icon-ven .path15:before {
  content: '\e9c6';
  margin-left: -1.06640625em;
  color: rgb(0, 0, 0);
}
.icon-ven .path16:before {
  content: '\e9c7';
  margin-left: -1.06640625em;
  color: rgb(21, 25, 28);
  opacity: 0.055;
}
.icon-ven .path17:before {
  content: '\e9c8';
  margin-left: -1.06640625em;
  color: rgb(0, 0, 0);
}
.icon-ven .path18:before {
  content: '\e9c9';
  margin-left: -1.06640625em;
  color: rgb(21, 25, 28);
  opacity: 0.055;
}
.icon-ven .path19:before {
  content: '\e9ca';
  margin-left: -1.06640625em;
  color: rgb(0, 0, 0);
}
.icon-ven .path20:before {
  content: '\e9cb';
  margin-left: -1.06640625em;
  color: rgb(146, 120, 199);
}
.icon-ven .path21:before {
  content: '\e9cc';
  margin-left: -1.06640625em;
  color: rgb(146, 120, 199);
}
.icon-ven .path22:before {
  content: '\e9cd';
  margin-left: -1.06640625em;
  color: rgb(146, 120, 199);
}
.icon-ven .path23:before {
  content: '\e9ce';
  margin-left: -1.06640625em;
  color: rgb(146, 120, 199);
}
.icon-ven .path24:before {
  content: '\e9cf';
  margin-left: -1.06640625em;
  color: rgb(146, 120, 199);
}
.icon-ven .path25:before {
  content: '\e9d0';
  margin-left: -1.06640625em;
  color: rgb(146, 120, 199);
}
.icon-ven .path26:before {
  content: '\e9d1';
  margin-left: -1.06640625em;
  color: rgb(146, 120, 199);
}
.icon-ven .path27:before {
  content: '\e9d2';
  margin-left: -1.06640625em;
  color: rgb(146, 120, 199);
}
.icon-ven .path28:before {
  content: '\e9d3';
  margin-left: -1.06640625em;
  color: rgb(146, 120, 199);
}
.icon-ven .path29:before {
  content: '\e9d4';
  margin-left: -1.06640625em;
  color: rgb(146, 120, 199);
}
.icon-ven .path30:before {
  content: '\e9d5';
  margin-left: -1.06640625em;
  color: rgb(146, 120, 199);
}
.icon-ven .path31:before {
  content: '\e9d6';
  margin-left: -1.06640625em;
  color: rgb(146, 120, 199);
}
.icon-xvg .path1:before {
  content: '\eaf9';
  color: rgb(0, 203, 255);
}
.icon-xvg .path2:before {
  content: '\eafa';
  margin-left: -0.6943359375em;
  color: rgb(48, 53, 51);
  opacity: 0.31;
}
.icon-wtc:before {
  content: '\eb1e';
  color: #2951d3;
}
.icon-wan .path1:before {
  content: '\eaed';
  color: rgb(18, 105, 171);
}
.icon-wan .path2:before {
  content: '\eaee';
  margin-left: -0.8662109375em;
  color: rgb(63, 188, 237);
}
.icon-wan .path3:before {
  content: '\eaef';
  margin-left: -0.8662109375em;
  color: rgb(18, 105, 171);
}
.icon-wan .path4:before {
  content: '\eaf0';
  margin-left: -0.8662109375em;
  color: rgb(63, 188, 237);
}
.icon-etn .path1:before {
  content: '\ea93';
  color: rgb(9, 27, 38);
}
.icon-etn .path2:before {
  content: '\ea94';
  margin-left: -1.0029296875em;
  color: rgb(9, 27, 38);
}
.icon-etn .path3:before {
  content: '\ea95';
  margin-left: -1.0029296875em;
  color: rgb(9, 27, 38);
}
.icon-etn .path4:before {
  content: '\ea96';
  margin-left: -1.0029296875em;
  color: rgb(9, 27, 38);
}
.icon-etn .path5:before {
  content: '\ea97';
  margin-left: -1.0029296875em;
  color: rgb(35, 190, 226);
}
.icon-bnt .path1:before {
  content: '\ea02';
  color: rgb(0, 13, 43);
}
.icon-bnt .path2:before {
  content: '\ea03';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-maid .path1:before {
  content: '\ead7';
  color: rgb(153, 190, 231);
}
.icon-maid .path2:before {
  content: '\ead8';
  margin-left: -0.8984375em;
  color: rgb(85, 146, 215);
}
.icon-ela .path1:before {
  content: '\ea86';
  color: rgb(29, 54, 145);
}
.icon-ela .path2:before {
  content: '\ea87';
  margin-left: -1.1640625em;
  color: rgb(5, 104, 178);
}
.icon-ela .path3:before {
  content: '\ea88';
  margin-left: -1.1640625em;
  color: rgb(102, 183, 47);
}
.icon-ela .path4:before {
  content: '\ea89';
  margin-left: -1.1640625em;
  color: rgb(94, 185, 221);
}
.icon-ela .path5:before {
  content: '\ea8a';
  margin-left: -1.1640625em;
  color: rgb(244, 234, 27);
}
.icon-ela .path6:before {
  content: '\ea8b';
  margin-left: -1.1640625em;
  color: rgb(179, 208, 2);
}
.icon-ela .path7:before {
  content: '\ea8c';
  margin-left: -1.1640625em;
  color: rgb(29, 54, 145);
}
.icon-ela .path8:before {
  content: '\ea8d';
  margin-left: -1.1640625em;
  color: rgb(5, 104, 178);
}
.icon-ela .path9:before {
  content: '\ea8e';
  margin-left: -1.1640625em;
  color: rgb(102, 183, 47);
}
.icon-ela .path10:before {
  content: '\ea8f';
  margin-left: -1.1640625em;
  color: rgb(94, 185, 221);
}
.icon-ela .path11:before {
  content: '\ea90';
  margin-left: -1.1640625em;
  color: rgb(244, 234, 27);
}
.icon-ela .path12:before {
  content: '\ea91';
  margin-left: -1.1640625em;
  color: rgb(179, 208, 2);
}
.icon-storj:before {
  content: '\eae8';
  color: #2683ff;
}
.icon-drgn .path1:before {
  content: '\e994';
  color: rgb(0, 0, 0);
}
.icon-drgn .path2:before {
  content: '\e995';
  margin-left: -0.59375em;
  color: rgb(201, 17, 17);
}
.icon-kcs:before {
  content: '\ead4';
  color: #0097e8;
}
.icon-dent:before {
  content: '\e992';
  color: #666;
}
.icon-okb .path1:before {
  content: '\e9f8';
  color: rgb(122, 189, 247);
}
.icon-okb .path2:before {
  content: '\e9f9';
  margin-left: -1em;
  color: rgb(13, 116, 245);
}
.icon-okb .path3:before {
  content: '\e9fa';
  margin-left: -1em;
  color: rgb(68, 148, 247);
}
.icon-okb .path4:before {
  content: '\e9fb';
  margin-left: -1em;
  color: rgb(0, 92, 244);
}
.icon-okb .path5:before {
  content: '\e9fc';
  margin-left: -1em;
  color: rgb(24, 110, 249);
}
.icon-okb .path6:before {
  content: '\e9fd';
  margin-left: -1em;
  color: rgb(2, 70, 242);
}
.icon-okb .path7:before {
  content: '\e9fe';
  margin-left: -1em;
  color: rgb(0, 70, 248);
}
.icon-okb .path8:before {
  content: '\e9ff';
  margin-left: -1em;
  color: rgb(7, 41, 241);
}
.icon-eos .path1:before {
  content: '\eb30';
  color: rgb(0, 0, 0);
}
.icon-eos .path2:before {
  content: '\eb31';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-omg2 .path1:before {
  content: '\eb29';
  color: rgb(26, 83, 240);
}
.icon-omg2 .path2:before {
  content: '\eb2f';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-bsv .path1:before {
  content: '\e90d';
  color: rgb(234, 179, 0);
}
.icon-bsv .path2:before {
  content: '\e90e';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-bsv .path3:before {
  content: '\e90f';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-xtz:before {
  content: '\e920';
  color: #2c7df6;
}
.icon-atm .path1:before {
  content: '\e904';
  color: rgb(46, 49, 72);
}
.icon-atm .path2:before {
  content: '\e905';
  margin-left: -1em;
  color: rgb(27, 30, 54);
}
.icon-atm .path3:before {
  content: '\e906';
  margin-left: -1em;
  color: rgb(111, 115, 144);
}
.icon-atm .path4:before {
  content: '\e907';
  margin-left: -1em;
  color: rgb(111, 115, 144);
}
.icon-atm .path5:before {
  content: '\e908';
  margin-left: -1em;
  color: rgb(111, 115, 144);
}
.icon-atm .path6:before {
  content: '\e909';
  margin-left: -1em;
  color: rgb(183, 185, 200);
}
.icon-atm .path7:before {
  content: '\e90a';
  margin-left: -1em;
  color: rgb(183, 185, 200);
}
.icon-atm .path8:before {
  content: '\e90b';
  margin-left: -1em;
  color: rgb(183, 185, 200);
}

// TODO: confirm naming
.icon-atm .path9:before {
  content: '\e90c';
  margin-left: -1em;
  color: rgb(183, 185, 200);
}
.icon-btt:before {
  content: '\e910';
}
.icon-zrx:before {
  content: '\eb24';
}
.icon-hot:before {
  content: '\e912';
  color: #1ba7ae;
}

// TODO: confirm naming
.icon-theta-token1:before {
  content: '\e900';
  color: #29cad2;
}
.icon-beam .path1:before {
  content: '\ea9e';
  color: rgb(11, 118, 255);
}
.icon-beam .path2:before {
  content: '\ea9f';
  margin-left: -1.4404296875em;
  color: rgb(36, 193, 255);
}
.icon-beam .path3:before {
  content: '\eaa0';
  margin-left: -1.4404296875em;
  color: rgb(57, 255, 242);
}
.icon-beam .path4:before {
  content: '\eaa1';
  margin-left: -1.4404296875em;
  color: rgb(0, 226, 194);
}
.icon-beam .path5:before {
  content: '\eaa2';
  margin-left: -1.4404296875em;
  color: rgb(255, 255, 255);
}
.icon-beam .path6:before {
  content: '\eaa3';
  margin-left: -1.4404296875em;
  color: rgb(255, 109, 255);
}
.icon-beam .path7:before {
  content: '\eaa4';
  margin-left: -1.4404296875em;
  color: rgb(161, 116, 255);
}
.icon-beam .path8:before {
  content: '\eaa5';
  margin-left: -1.4404296875em;
  color: rgb(171, 56, 230);
}
.icon-fil .path1:before {
  content: '\e9ac';
  color: rgb(0, 144, 255);
}
.icon-fil .path2:before {
  content: '\e9ad';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-comp .path1:before {
  content: '\e9ae';
  color: rgb(7, 10, 14);
}
.icon-comp .path2:before {
  content: '\e9af';
  margin-left: -1em;
  color: rgb(0, 211, 149);
}
.icon-dai .path1:before {
  content: '\e9b0';
  color: rgb(245, 172, 55);
}
.icon-dai .path2:before {
  content: '\e9b1';
  margin-left: -1em;
  color: rgb(254, 254, 253);
}
.icon-uni:before {
  content: '\e9b2';
  color: #ff007a;
}
.icon-dot:before {
  content: '\e9b4';
  color: #e6007a;
}
.icon-enj .path1:before {
  content: '\eaa6';
  color: rgb(120, 102, 213);
}
.icon-enj .path2:before {
  content: '\eaa7';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-enj .path3:before {
  content: '\eaa8';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-avax .path1:before {
  content: '\e912';
  color: rgb(232, 65, 66);
}
.icon-avax .path2:before {
  content: '\e9f7';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-hbar .path1:before {
  content: '\ea92';
  color: rgb(0, 0, 0);
}
.icon-hbar .path2:before {
  content: '\ea98';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ftt .path1:before {
  content: '\eaa9';
  color: rgb(2, 166, 194);
}
.icon-ftt .path2:before {
  content: '\eaaa';
  margin-left: -1em;
  color: rgb(95, 202, 222);
}
.icon-ftt .path3:before {
  content: '\eaab';
  margin-left: -1em;
  color: rgb(95, 202, 222);
}
.icon-ftt .path4:before {
  content: '\eaac';
  margin-left: -1em;
  color: rgb(171, 235, 244);
}
.icon-chz:before {
  content: '\eaad';
  color: #cd0124;
}
.icon-wbtc .path1:before {
  content: '\eaae';
  color: rgb(90, 85, 100);
}
.icon-wbtc .path2:before {
  content: '\eaaf';
  margin-left: -1em;
  color: rgb(90, 85, 100);
}
.icon-wbtc .path3:before {
  content: '\eab0';
  margin-left: -1em;
  color: rgb(90, 85, 100);
}
.icon-wbtc .path4:before {
  content: '\eab1';
  margin-left: -1em;
  color: rgb(90, 85, 100);
}
.icon-wbtc .path5:before {
  content: '\eab2';
  margin-left: -1em;
  color: rgb(240, 146, 66);
}
.icon-wbtc .path6:before {
  content: '\eab3';
  margin-left: -1em;
  color: rgb(40, 33, 56);
}
.icon-sol .path1:before {
  content: '\eab4';
  color: rgb(51, 204, 204);
}
.icon-sol .path2:before {
  content: '\eab5';
  margin-left: -1em;
  color: rgb(86, 182, 197);
}
.icon-sol .path3:before {
  content: '\eab6';
  margin-left: -1em;
  color: rgb(51, 204, 204);
}
.icon-sol .path4:before {
  content: '\eab7';
  margin-left: -1em;
  color: rgb(51, 204, 204);
}
.icon-sol .path5:before {
  content: '\eab8';
  margin-left: -1em;
  color: rgb(114, 157, 206);
}
.icon-sol .path6:before {
  content: '\eab9';
  margin-left: -1em;
  color: rgb(141, 133, 214);
}
.icon-sol .path7:before {
  content: '\eaba';
  margin-left: -1em;
  color: rgb(198, 83, 230);
}
.icon-sol .path8:before {
  content: '\eabb';
  margin-left: -1em;
  color: rgb(122, 150, 208);
}
.icon-sol .path9:before {
  content: '\eabc';
  margin-left: -1em;
  color: rgb(153, 122, 217);
}
.icon-sol .path10:before {
  content: '\eabd';
  margin-left: -1em;
  color: rgb(109, 161, 204);
}
.icon-sol .path11:before {
  content: '\eabe';
  margin-left: -1em;
  color: rgb(135, 139, 212);
}
.icon-sol .path12:before {
  content: '\eabf';
  margin-left: -1em;
  color: rgb(105, 165, 203);
}
.icon-sol .path13:before {
  content: '\eac0';
  margin-left: -1em;
  color: rgb(81, 186, 196);
}
.icon-sol .path14:before {
  content: '\eac1';
  margin-left: -1em;
  color: rgb(177, 101, 224);
}
.icon-sol .path15:before {
  content: '\eac2';
  margin-left: -1em;
  color: rgb(102, 168, 202);
}
.icon-sol .path16:before {
  content: '\eac3';
  margin-left: -1em;
  color: rgb(59, 206, 190);
}
.icon-sol .path17:before {
  content: '\eac4';
  margin-left: -1em;
  color: rgb(45, 218, 186);
}
.icon-sol .path18:before {
  content: '\eac5';
  margin-left: -1em;
  color: rgb(22, 239, 179);
}
.icon-sol .path19:before {
  content: '\eac6';
  margin-left: -1em;
  color: rgb(94, 175, 200);
}
.icon-sol .path20:before {
  content: '\eac7';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-aave .path1:before {
  content: '\eac8';
  color: rgb(92, 179, 194);
}
.icon-aave .path2:before {
  content: '\eac9';
  margin-left: -1em;
  color: rgb(96, 166, 188);
}
.icon-aave .path3:before {
  content: '\eaca';
  margin-left: -1em;
  color: rgb(100, 156, 184);
}
.icon-aave .path4:before {
  content: '\eacb';
  margin-left: -1em;
  color: rgb(104, 149, 181);
}
.icon-aave .path5:before {
  content: '\eacc';
  margin-left: -1em;
  color: rgb(115, 135, 175);
}
.icon-aave .path6:before {
  content: '\eacd';
  margin-left: -1em;
  color: rgb(122, 128, 172);
}
.icon-aave .path7:before {
  content: '\eace';
  margin-left: -1em;
  color: rgb(135, 114, 166);
}
.icon-aave .path8:before {
  content: '\eacf';
  margin-left: -1em;
  color: rgb(154, 100, 160);
}
.icon-aave .path9:before {
  content: '\ead0';
  margin-left: -1em;
  color: rgb(165, 92, 156);
}
.icon-aave .path10:before {
  content: '\ead1';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-cake .path1:before {
  content: '\ead2';
  color: rgb(96, 96, 99);
}
.icon-cake .path2:before {
  content: '\ead3';
  margin-left: -1em;
  color: rgb(190, 190, 190);
}
.icon-cake .path3:before {
  content: '\eade';
  margin-left: -1em;
  color: rgb(250, 249, 250);
}
.icon-cake .path4:before {
  content: '\eadf';
  margin-left: -1em;
  color: rgb(99, 48, 1);
}
.icon-cake .path5:before {
  content: '\eb58';
  margin-left: -1em;
  color: rgb(254, 220, 144);
}
.icon-cake .path6:before {
  content: '\eb59';
  margin-left: -1em;
  color: rgb(209, 136, 79);
}
.icon-cake .path7:before {
  content: '\eb5a';
  margin-left: -1em;
  color: rgb(99, 48, 1);
}
.icon-cake .path8:before {
  content: '\eb5b';
  margin-left: -1em;
  color: rgb(254, 220, 144);
}
.icon-cake .path9:before {
  content: '\eb5c';
  margin-left: -1em;
  color: rgb(209, 136, 79);
}
.icon-cake .path10:before {
  content: '\eb5d';
  margin-left: -1em;
  color: rgb(99, 48, 1);
}
.icon-cake .path11:before {
  content: '\eb5e';
  margin-left: -1em;
  color: rgb(254, 220, 144);
}
.icon-cake .path12:before {
  content: '\eb5f';
  margin-left: -1em;
  color: rgb(209, 136, 79);
}
.icon-cake .path13:before {
  content: '\eb60';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cake .path14:before {
  content: '\eb61';
  margin-left: -1em;
  color: rgb(99, 48, 1);
}
.icon-cake .path15:before {
  content: '\eb62';
  margin-left: -1em;
  color: rgb(158, 114, 0);
}
.icon-cake .path16:before {
  content: '\eb63';
  margin-left: -1em;
  color: rgb(158, 114, 0);
}
.icon-cake .path17:before {
  content: '\eb64';
  margin-left: -1em;
  color: rgb(240, 185, 11);
}
.icon-cake .path18:before {
  content: '\eb65';
  margin-left: -1em;
  color: rgb(158, 114, 0);
}
.icon-cake .path19:before {
  content: '\eb66';
  margin-left: -1em;
  color: rgb(99, 48, 1);
}
.icon-sushi .path1:before {
  content: '\eb67';
  color: rgb(65, 158, 231);
}
.icon-sushi .path2:before {
  content: '\eb68';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-sushi .path3:before {
  content: '\eb69';
  margin-left: -1em;
  color: rgb(65, 158, 231);
}
.icon-sushi .path4:before {
  content: '\eb6a';
  margin-left: -1em;
  color: rgb(232, 96, 158);
}
.icon-sushi .path5:before {
  content: '\eb6b';
  margin-left: -1em;
  color: rgb(162, 119, 186);
}
.icon-sushi .path6:before {
  content: '\eb6c';
  margin-left: -1em;
  color: rgb(162, 119, 186);
}
.icon-sushi .path7:before {
  content: '\eb6d';
  margin-left: -1em;
  color: rgb(14, 15, 35);
}
.icon-sushi .path8:before {
  content: '\eb6e';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-sushi .path9:before {
  content: '\eb6f';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-sushi .path10:before {
  content: '\eb70';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-sushi .path11:before {
  content: '\eb71';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-sushi .path12:before {
  content: '\eb72';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-sushi .path13:before {
  content: '\eb73';
  margin-left: -1em;
  color: rgb(65, 158, 231);
}
.icon-icp .path1:before {
  content: '\eb74';
  color: rgb(241, 90, 36);
}
.icon-icp .path2:before {
  content: '\eb75';
  margin-left: -1.375em;
  color: rgb(241, 90, 36);
}
.icon-icp .path3:before {
  content: '\eb76';
  margin-left: -1.375em;
  color: rgb(242, 98, 38);
}
.icon-icp .path4:before {
  content: '\eb77';
  margin-left: -1.375em;
  color: rgb(244, 118, 43);
}
.icon-icp .path5:before {
  content: '\eb78';
  margin-left: -1.375em;
  color: rgb(246, 140, 49);
}
.icon-icp .path6:before {
  content: '\eb79';
  margin-left: -1.375em;
  color: rgb(249, 159, 54);
}
.icon-icp .path7:before {
  content: '\eb7a';
  margin-left: -1.375em;
  color: rgb(251, 176, 59);
}
.icon-icp .path8:before {
  content: '\eb7b';
  margin-left: -1.375em;
  color: rgb(132, 38, 131);
}
.icon-icp .path9:before {
  content: '\eb7c';
  margin-left: -1.375em;
  color: rgb(41, 171, 226);
}
.icon-icp .path10:before {
  content: '\eb7d';
  margin-left: -1.375em;
  color: rgb(237, 30, 121);
}
.icon-icp .path11:before {
  content: '\eb7e';
  margin-left: -1.375em;
  color: rgb(223, 32, 123);
}
.icon-icp .path12:before {
  content: '\eb7f';
  margin-left: -1.375em;
  color: rgb(168, 36, 128);
}
.icon-icp .path13:before {
  content: '\eb80';
  margin-left: -1.375em;
  color: rgb(132, 38, 131);
}
.icon-icp .path14:before {
  content: '\eb81';
  margin-left: -1.375em;
  color: rgb(82, 39, 133);
}
.icon-mana .path1:before {
  content: '\ec26';
  color: rgb(241, 148, 100);
}
.icon-mana .path2:before {
  content: '\ec27';
  margin-left: -1em;
  color: rgb(254, 254, 254);
}
.icon-mana .path3:before {
  content: '\ec28';
  margin-left: -1em;
  color: rgb(245, 182, 91);
}
.icon-mana .path4:before {
  content: '\ec29';
  margin-left: -1em;
  color: rgb(230, 52, 83);
}
.icon-mana .path5:before {
  content: '\ec2a';
  margin-left: -1em;
  color: rgb(231, 63, 84);
}
.icon-mana .path6:before {
  content: '\ec2b';
  margin-left: -1em;
  color: rgb(234, 97, 86);
}
.icon-mana .path7:before {
  content: '\ec2c';
  margin-left: -1em;
  color: rgb(232, 79, 84);
}
.icon-mana .path8:before {
  content: '\ec2d';
  margin-left: -1em;
  color: rgb(233, 88, 86);
}
.icon-mana .path9:before {
  content: '\ec2e';
  margin-left: -1em;
  color: rgb(232, 78, 84);
}
.icon-mana .path10:before {
  content: '\ec2f';
  margin-left: -1em;
  color: rgb(234, 94, 85);
}
.icon-mana .path11:before {
  content: '\ec30';
  margin-left: -1em;
  color: rgb(232, 73, 84);
}
.icon-mana .path12:before {
  content: '\ec31';
  margin-left: -1em;
  color: rgb(233, 88, 86);
}
.icon-mana .path13:before {
  content: '\ec32';
  margin-left: -1em;
  color: rgb(233, 83, 85);
}
.icon-mana .path14:before {
  content: '\ec33';
  margin-left: -1em;
  color: rgb(233, 84, 85);
}
.icon-mana .path15:before {
  content: '\ec34';
  margin-left: -1em;
  color: rgb(232, 73, 84);
}
.icon-mana .path16:before {
  content: '\ec35';
  margin-left: -1em;
  color: rgb(235, 106, 87);
}
.icon-mana .path17:before {
  content: '\ec36';
  margin-left: -1em;
  color: rgb(231, 69, 84);
}
.icon-mana .path18:before {
  content: '\ec37';
  margin-left: -1em;
  color: rgb(231, 59, 84);
}
.icon-mana .path19:before {
  content: '\ec38';
  margin-left: -1em;
  color: rgb(231, 69, 84);
}
.icon-mana .path20:before {
  content: '\ec39';
  margin-left: -1em;
  color: rgb(235, 108, 87);
}
.icon-mana .path21:before {
  content: '\ec3a';
  margin-left: -1em;
  color: rgb(239, 137, 88);
}
.icon-mana .path22:before {
  content: '\ec3b';
  margin-left: -1em;
  color: rgb(230, 56, 83);
}
.icon-mana .path23:before {
  content: '\ec3c';
  margin-left: -1em;
  color: rgb(237, 121, 88);
}
.icon-mana .path24:before {
  content: '\ec3d';
  margin-left: -1em;
  color: rgb(240, 142, 89);
}
.icon-mana .path25:before {
  content: '\ec3e';
  margin-left: -1em;
  color: rgb(241, 152, 91);
}
.icon-mana .path26:before {
  content: '\ec3f';
  margin-left: -1em;
  color: rgb(238, 129, 88);
}
.icon-mana .path27:before {
  content: '\ec40';
  margin-left: -1em;
  color: rgb(241, 148, 91);
}
.icon-mana .path28:before {
  content: '\ec41';
  margin-left: -1em;
  color: rgb(235, 108, 87);
}
.icon-mana .path29:before {
  content: '\ec42';
  margin-left: -1em;
  color: rgb(243, 164, 91);
}
.icon-mana .path30:before {
  content: '\ec43';
  margin-left: -1em;
  color: rgb(236, 112, 87);
}
.icon-mana .path31:before {
  content: '\ec44';
  margin-left: -1em;
  color: rgb(230, 56, 83);
}
.icon-mana .path32:before {
  content: '\ec45';
  margin-left: -1em;
  color: rgb(237, 125, 88);
}
.icon-mana .path33:before {
  content: '\ec46';
  margin-left: -1em;
  color: rgb(239, 133, 88);
}
.icon-mana .path34:before {
  content: '\ec47';
  margin-left: -1em;
  color: rgb(234, 94, 85);
}
.icon-mana .path35:before {
  content: '\ec48';
  margin-left: -1em;
  color: rgb(238, 128, 88);
}
.icon-mana .path36:before {
  content: '\ec49';
  margin-left: -1em;
  color: rgb(242, 159, 91);
}
.icon-mana .path37:before {
  content: '\ec4a';
  margin-left: -1em;
  color: rgb(239, 139, 88);
}
.icon-mana .path38:before {
  content: '\ec4b';
  margin-left: -1em;
  color: rgb(240, 145, 89);
}
.icon-mana .path39:before {
  content: '\ec4c';
  margin-left: -1em;
  color: rgb(237, 119, 88);
}
.icon-mana .path40:before {
  content: '\ec4d';
  margin-left: -1em;
  color: rgb(235, 101, 86);
}
.icon-mana .path41:before {
  content: '\ec4e';
  margin-left: -1em;
  color: rgb(230, 53, 83);
}
.icon-mana .path42:before {
  content: '\ec4f';
  margin-left: -1em;
  color: rgb(244, 169, 90);
}
.icon-mana .path43:before {
  content: '\ec50';
  margin-left: -1em;
  color: rgb(236, 118, 87);
}
.icon-mana .path44:before {
  content: '\ec51';
  margin-left: -1em;
  color: rgb(234, 99, 86);
}
.icon-mana .path45:before {
  content: '\ec52';
  margin-left: -1em;
  color: rgb(232, 72, 84);
}
.icon-mana .path46:before {
  content: '\ec53';
  margin-left: -1em;
  color: rgb(231, 59, 84);
}
.icon-mana .path47:before {
  content: '\ec54';
  margin-left: -1em;
  color: rgb(235, 103, 87);
}
.icon-mana .path48:before {
  content: '\ec55';
  margin-left: -1em;
  color: rgb(242, 156, 91);
}
.icon-mana .path49:before {
  content: '\ec56';
  margin-left: -1em;
  color: rgb(236, 115, 88);
}
.icon-mana .path50:before {
  content: '\ec57';
  margin-left: -1em;
  color: rgb(236, 115, 88);
}
.icon-mana .path51:before {
  content: '\ec58';
  margin-left: -1em;
  color: rgb(242, 156, 91);
}
.icon-mana .path52:before {
  content: '\ec59';
  margin-left: -1em;
  color: rgb(245, 174, 91);
}
.icon-mana .path53:before {
  content: '\ec5a';
  margin-left: -1em;
  color: rgb(236, 112, 93);
}
.icon-mana .path54:before {
  content: '\ec5b';
  margin-left: -1em;
  color: rgb(229, 20, 82);
}
.icon-mana .path55:before {
  content: '\ec5c';
  margin-left: -1em;
  color: rgb(244, 177, 80);
}
.icon-mana .path56:before {
  content: '\ec5d';
  margin-left: -1em;
  color: rgb(238, 130, 97);
}
.icon-mana .path57:before {
  content: '\ec5e';
  margin-left: -1em;
  color: rgb(242, 157, 101);
}
.icon-mana .path58:before {
  content: '\ec5f';
  margin-left: -1em;
  color: rgb(151, 33, 136);
}
.icon-mana .path59:before {
  content: '\ec60';
  margin-left: -1em;
  color: rgb(233, 90, 87);
}
.icon-mana .path60:before {
  content: '\ec61';
  margin-left: -1em;
  color: rgb(210, 12, 98);
}
.icon-mana .path61:before {
  content: '\ec62';
  margin-left: -1em;
  color: rgb(196, 8, 112);
}
.icon-mana .path62:before {
  content: '\ec63';
  margin-left: -1em;
  color: rgb(137, 40, 138);
}
.icon-mana .path63:before {
  content: '\ec64';
  margin-left: -1em;
  color: rgb(232, 73, 84);
}
.icon-mana .path64:before {
  content: '\ec65';
  margin-left: -1em;
  color: rgb(236, 115, 88);
}
.icon-mana .path65:before {
  content: '\ec66';
  margin-left: -1em;
  color: rgb(231, 69, 84);
}
.icon-mana .path66:before {
  content: '\ec67';
  margin-left: -1em;
  color: rgb(236, 112, 87);
}
.icon-mana .path67:before {
  content: '\ec68';
  margin-left: -1em;
  color: rgb(234, 93, 74);
}
.icon-mana .path68:before {
  content: '\ec69';
  margin-left: -1em;
  color: rgb(231, 69, 84);
}
.icon-mana .path69:before {
  content: '\ec6a';
  margin-left: -1em;
  color: rgb(232, 78, 84);
}
.icon-mana .path70:before {
  content: '\ec6b';
  margin-left: -1em;
  color: rgb(230, 56, 83);
}
.icon-mana .path71:before {
  content: '\ec6c';
  margin-left: -1em;
  color: rgb(230, 56, 83);
}
.icon-mana .path72:before {
  content: '\ec6d';
  margin-left: -1em;
  color: rgb(242, 156, 91);
}
.icon-mana .path73:before {
  content: '\ec6e';
  margin-left: -1em;
  color: rgb(254, 254, 254);
}
.icon-mana .path74:before {
  content: '\ec6f';
  margin-left: -1em;
  color: rgb(243, 162, 102);
}
.icon-mana .path75:before {
  content: '\ec70';
  margin-left: -1em;
  color: rgb(239, 134, 83);
}
.icon-mana .path76:before {
  content: '\ec71';
  margin-left: -1em;
  color: rgb(236, 115, 88);
}
.icon-mana .path77:before {
  content: '\ec72';
  margin-left: -1em;
  color: rgb(235, 104, 76);
}
.icon-mana .path78:before {
  content: '\ec73';
  margin-left: -1em;
  color: rgb(235, 101, 75);
}
.icon-mana .path79:before {
  content: '\ec74';
  margin-left: -1em;
  color: rgb(239, 135, 76);
}
.icon-mana .path80:before {
  content: '\ec75';
  margin-left: -1em;
  color: rgb(234, 99, 74);
}
.icon-mana .path81:before {
  content: '\ec76';
  margin-left: -1em;
  color: rgb(234, 97, 73);
}
.icon-mana .path82:before {
  content: '\ec77';
  margin-left: -1em;
  color: rgb(241, 149, 73);
}
.icon-mana .path83:before {
  content: '\ec78';
  margin-left: -1em;
  color: rgb(235, 104, 74);
}
.icon-mana .path84:before {
  content: '\ec79';
  margin-left: -1em;
  color: rgb(236, 111, 73);
}
.icon-mana .path85:before {
  content: '\ec7a';
  margin-left: -1em;
  color: rgb(236, 114, 74);
}
.icon-mana .path86:before {
  content: '\ec7b';
  margin-left: -1em;
  color: rgb(238, 127, 73);
}
.icon-mana .path87:before {
  content: '\ec7c';
  margin-left: -1em;
  color: rgb(237, 119, 73);
}
.icon-mana .path88:before {
  content: '\ec7d';
  margin-left: -1em;
  color: rgb(237, 120, 74);
}
.icon-mana .path89:before {
  content: '\ec7e';
  margin-left: -1em;
  color: rgb(246, 189, 90);
}
.icon-mana .path90:before {
  content: '\ec7f';
  margin-left: -1em;
  color: rgb(244, 175, 74);
}
.icon-mana .path91:before {
  content: '\ec80';
  margin-left: -1em;
  color: rgb(244, 175, 74);
}
.icon-mana .path92:before {
  content: '\ec81';
  margin-left: -1em;
  color: rgb(244, 174, 74);
}
.icon-mana .path93:before {
  content: '\ec82';
  margin-left: -1em;
  color: rgb(229, 21, 80);
}
.icon-mana .path94:before {
  content: '\ec83';
  margin-left: -1em;
  color: rgb(245, 195, 93);
}
.icon-mana .path95:before {
  content: '\ec84';
  margin-left: -1em;
  color: rgb(230, 47, 82);
}
.icon-mana .path96:before {
  content: '\ec85';
  margin-left: -1em;
  color: rgb(231, 54, 82);
}
.icon-mana .path97:before {
  content: '\ec86';
  margin-left: -1em;
  color: rgb(245, 195, 93);
}
.icon-mana .path98:before {
  content: '\ec87';
  margin-left: -1em;
  color: rgb(234, 94, 85);
}
.icon-mana .path99:before {
  content: '\ec88';
  margin-left: -1em;
  color: rgb(235, 102, 86);
}
.icon-mana .path100:before {
  content: '\ec89';
  margin-left: -1em;
  color: rgb(235, 101, 86);
}
.icon-mana .path101:before {
  content: '\ec8a';
  margin-left: -1em;
  color: rgb(171, 46, 139);
}
.icon-mana .path102:before {
  content: '\ec8b';
  margin-left: -1em;
  color: rgb(164, 50, 141);
}
.icon-mana .path103:before {
  content: '\ec8c';
  margin-left: -1em;
  color: rgb(157, 52, 141);
}
.icon-mana .path104:before {
  content: '\ec8d';
  margin-left: -1em;
  color: rgb(151, 53, 142);
}
.icon-mana .path105:before {
  content: '\ec8e';
  margin-left: -1em;
  color: rgb(145, 55, 143);
}
.icon-mana .path106:before {
  content: '\ec8f';
  margin-left: -1em;
  color: rgb(142, 43, 139);
}
.icon-mana .path107:before {
  content: '\ec90';
  margin-left: -1em;
  color: rgb(233, 83, 74);
}
.icon-mana .path108:before {
  content: '\ec91';
  margin-left: -1em;
  color: rgb(232, 79, 84);
}
.icon-mana .path109:before {
  content: '\ec92';
  margin-left: -1em;
  color: rgb(232, 66, 83);
}
.icon-mana .path110:before {
  content: '\ec93';
  margin-left: -1em;
  color: rgb(232, 79, 83);
}
.icon-mana .path111:before {
  content: '\ec94';
  margin-left: -1em;
  color: rgb(235, 105, 87);
}
.icon-mana .path112:before {
  content: '\ec95';
  margin-left: -1em;
  color: rgb(235, 105, 87);
}
.icon-mana .path113:before {
  content: '\ec96';
  margin-left: -1em;
  color: rgb(233, 88, 74);
}
.icon-mana .path114:before {
  content: '\ec97';
  margin-left: -1em;
  color: rgb(230, 52, 82);
}
.icon-mana .path115:before {
  content: '\ec98';
  margin-left: -1em;
  color: rgb(230, 43, 81);
}
.icon-mana .path116:before {
  content: '\ec99';
  margin-left: -1em;
  color: rgb(231, 59, 84);
}
.icon-mana .path117:before {
  content: '\ec9a';
  margin-left: -1em;
  color: rgb(231, 59, 84);
}
.icon-mana .path118:before {
  content: '\ec9b';
  margin-left: -1em;
  color: rgb(231, 59, 84);
}
.icon-mana .path119:before {
  content: '\ec9c';
  margin-left: -1em;
  color: rgb(231, 59, 84);
}
.icon-mana .path120:before {
  content: '\ec9d';
  margin-left: -1em;
  color: rgb(231, 59, 84);
}
.icon-mana .path121:before {
  content: '\ec9e';
  margin-left: -1em;
  color: rgb(231, 59, 84);
}
.icon-mana .path122:before {
  content: '\ec9f';
  margin-left: -1em;
  color: rgb(231, 59, 84);
}
.icon-mana .path123:before {
  content: '\eca0';
  margin-left: -1em;
  color: rgb(231, 59, 84);
}
.icon-mana .path124:before {
  content: '\eca1';
  margin-left: -1em;
  color: rgb(231, 59, 84);
}
.icon-mana .path125:before {
  content: '\eca2';
  margin-left: -1em;
  color: rgb(231, 59, 84);
}
.icon-mana .path126:before {
  content: '\eca3';
  margin-left: -1em;
  color: rgb(232, 73, 84);
}
.icon-mana .path127:before {
  content: '\eca4';
  margin-left: -1em;
  color: rgb(230, 54, 82);
}
.icon-mana .path128:before {
  content: '\eca5';
  margin-left: -1em;
  color: rgb(231, 58, 83);
}
.icon-mana .path129:before {
  content: '\eca6';
  margin-left: -1em;
  color: rgb(230, 49, 82);
}
.icon-mana .path130:before {
  content: '\eca7';
  margin-left: -1em;
  color: rgb(233, 88, 74);
}
.icon-mana .path131:before {
  content: '\eca8';
  margin-left: -1em;
  color: rgb(230, 53, 83);
}
.icon-mana .path132:before {
  content: '\eca9';
  margin-left: -1em;
  color: rgb(230, 53, 83);
}
.icon-mana .path133:before {
  content: '\ecaa';
  margin-left: -1em;
  color: rgb(236, 111, 87);
}
.icon-mana .path134:before {
  content: '\ecab';
  margin-left: -1em;
  color: rgb(145, 55, 143);
}
.icon-mana .path135:before {
  content: '\ecac';
  margin-left: -1em;
  color: rgb(234, 91, 74);
}
.icon-mana .path136:before {
  content: '\ecad';
  margin-left: -1em;
  color: rgb(234, 92, 76);
}
.icon-mana .path137:before {
  content: '\ecae';
  margin-left: -1em;
  color: rgb(236, 112, 87);
}
.icon-mana .path138:before {
  content: '\ecaf';
  margin-left: -1em;
  color: rgb(238, 128, 88);
}
.icon-mana .path139:before {
  content: '\ecb0';
  margin-left: -1em;
  color: rgb(230, 50, 89);
}
.icon-mana .path140:before {
  content: '\ecb1';
  margin-left: -1em;
  color: rgb(230, 52, 85);
}
.icon-mana .path141:before {
  content: '\ecb2';
  margin-left: -1em;
  color: rgb(225, 50, 94);
}
.icon-mana .path142:before {
  content: '\ecb3';
  margin-left: -1em;
  color: rgb(220, 48, 99);
}
.icon-mana .path143:before {
  content: '\ecb4';
  margin-left: -1em;
  color: rgb(213, 46, 105);
}
.icon-mana .path144:before {
  content: '\ecb5';
  margin-left: -1em;
  color: rgb(209, 47, 109);
}
.icon-mana .path145:before {
  content: '\ecb6';
  margin-left: -1em;
  color: rgb(205, 46, 112);
}
.icon-mana .path146:before {
  content: '\ecb7';
  margin-left: -1em;
  color: rgb(217, 48, 102);
}
.icon-mana .path147:before {
  content: '\ecb8';
  margin-left: -1em;
  color: rgb(201, 44, 117);
}
.icon-mana .path148:before {
  content: '\ecb9';
  margin-left: -1em;
  color: rgb(197, 44, 121);
}
.icon-mana .path149:before {
  content: '\ecba';
  margin-left: -1em;
  color: rgb(193, 43, 125);
}
.icon-mana .path150:before {
  content: '\ecbb';
  margin-left: -1em;
  color: rgb(189, 42, 128);
}
.icon-mana .path151:before {
  content: '\ecbc';
  margin-left: -1em;
  color: rgb(185, 41, 132);
}
.icon-mana .path152:before {
  content: '\ecbd';
  margin-left: -1em;
  color: rgb(178, 43, 138);
}
.icon-mana .path153:before {
  content: '\ecbe';
  margin-left: -1em;
  color: rgb(182, 41, 135);
}
.icon-mana .path154:before {
  content: '\ecbf';
  margin-left: -1em;
  color: rgb(230, 45, 82);
}
.icon-mana .path155:before {
  content: '\ecc0';
  margin-left: -1em;
  color: rgb(233, 90, 85);
}
.icon-mana .path156:before {
  content: '\ecc1';
  margin-left: -1em;
  color: rgb(230, 50, 89);
}
.icon-mana .path157:before {
  content: '\ecc2';
  margin-left: -1em;
  color: rgb(225, 50, 93);
}
.icon-mana .path158:before {
  content: '\ecc3';
  margin-left: -1em;
  color: rgb(230, 52, 85);
}
.icon-mana .path159:before {
  content: '\ecc4';
  margin-left: -1em;
  color: rgb(222, 15, 87);
}
.icon-mana .path160:before {
  content: '\ecc5';
  margin-left: -1em;
  color: rgb(194, 20, 115);
}
.icon-mana .path161:before {
  content: '\ecc6';
  margin-left: -1em;
  color: rgb(212, 0, 98);
}
.icon-mana .path162:before {
  content: '\ecc7';
  margin-left: -1em;
  color: rgb(226, 2, 83);
}
.icon-mana .path163:before {
  content: '\ecc8';
  margin-left: -1em;
  color: rgb(164, 50, 141);
}
.icon-mana .path164:before {
  content: '\ecc9';
  margin-left: -1em;
  color: rgb(158, 52, 141);
}
.icon-mana .path165:before {
  content: '\ecca';
  margin-left: -1em;
  color: rgb(152, 53, 142);
}
.icon-mana .path166:before {
  content: '\eccb';
  margin-left: -1em;
  color: rgb(184, 6, 123);
}
.icon-mana .path167:before {
  content: '\eccc';
  margin-left: -1em;
  color: rgb(163, 24, 133);
}
.icon-mana .path168:before {
  content: '\eccd';
  margin-left: -1em;
  color: rgb(172, 12, 132);
}
.icon-mana .path169:before {
  content: '\ecce';
  margin-left: -1em;
  color: rgb(205, 46, 113);
}
.icon-mana .path170:before {
  content: '\eccf';
  margin-left: -1em;
  color: rgb(209, 47, 110);
}
.icon-mana .path171:before {
  content: '\ecd0';
  margin-left: -1em;
  color: rgb(213, 46, 106);
}
.icon-mana .path172:before {
  content: '\ecd1';
  margin-left: -1em;
  color: rgb(219, 48, 100);
}
.icon-mana .path173:before {
  content: '\ecd2';
  margin-left: -1em;
  color: rgb(215, 47, 103);
}
.icon-mana .path174:before {
  content: '\ecd3';
  margin-left: -1em;
  color: rgb(217, 48, 102);
}
.icon-mana .path175:before {
  content: '\ecd4';
  margin-left: -1em;
  color: rgb(201, 44, 117);
}
.icon-mana .path176:before {
  content: '\ecd5';
  margin-left: -1em;
  color: rgb(197, 44, 121);
}
.icon-mana .path177:before {
  content: '\ecd6';
  margin-left: -1em;
  color: rgb(193, 43, 124);
}
.icon-mana .path178:before {
  content: '\ecd7';
  margin-left: -1em;
  color: rgb(220, 48, 98);
}
.icon-mana .path179:before {
  content: '\ecd8';
  margin-left: -1em;
  color: rgb(158, 35, 136);
}
.icon-mana .path180:before {
  content: '\ecd9';
  margin-left: -1em;
  color: rgb(170, 25, 134);
}
.icon-mana .path181:before {
  content: '\ecda';
  margin-left: -1em;
  color: rgb(233, 85, 74);
}
.icon-mana .path182:before {
  content: '\ecdb';
  margin-left: -1em;
  color: rgb(233, 85, 74);
}
.icon-mana .path183:before {
  content: '\ecdc';
  margin-left: -1em;
  color: rgb(231, 69, 84);
}
.icon-mana .path184:before {
  content: '\ecdd';
  margin-left: -1em;
  color: rgb(234, 97, 86);
}
.icon-mana .path185:before {
  content: '\ecde';
  margin-left: -1em;
  color: rgb(235, 106, 87);
}
.icon-mana .path186:before {
  content: '\ecdf';
  margin-left: -1em;
  color: rgb(235, 106, 87);
}
.icon-mana .path187:before {
  content: '\ece0';
  margin-left: -1em;
  color: rgb(235, 101, 86);
}
.icon-mana .path188:before {
  content: '\ece1';
  margin-left: -1em;
  color: rgb(235, 101, 86);
}
.icon-mana .path189:before {
  content: '\ece2';
  margin-left: -1em;
  color: rgb(235, 101, 86);
}
.icon-mana .path190:before {
  content: '\ece3';
  margin-left: -1em;
  color: rgb(235, 101, 86);
}
.icon-mana .path191:before {
  content: '\ece4';
  margin-left: -1em;
  color: rgb(235, 101, 86);
}
.icon-mana .path192:before {
  content: '\ece5';
  margin-left: -1em;
  color: rgb(235, 101, 86);
}
.icon-mana .path193:before {
  content: '\ece6';
  margin-left: -1em;
  color: rgb(235, 101, 86);
}
.icon-mana .path194:before {
  content: '\ece7';
  margin-left: -1em;
  color: rgb(188, 42, 129);
}
.icon-mana .path195:before {
  content: '\ece8';
  margin-left: -1em;
  color: rgb(185, 41, 133);
}
.icon-mana .path196:before {
  content: '\ece9';
  margin-left: -1em;
  color: rgb(178, 43, 138);
}
.icon-mana .path197:before {
  content: '\ecea';
  margin-left: -1em;
  color: rgb(171, 46, 139);
}
.icon-mana .path198:before {
  content: '\eceb';
  margin-left: -1em;
  color: rgb(182, 42, 136);
}
.icon-mana .path199:before {
  content: '\ecec';
  margin-left: -1em;
  color: rgb(215, 47, 103);
}
.icon-mana .path200:before {
  content: '\eced';
  margin-left: -1em;
  color: rgb(181, 18, 127);
}
.icon-one .path1:before {
  content: '\ecee';
  color: rgb(93, 190, 184);
}
.icon-one .path2:before {
  content: '\ecef';
  margin-left: -1em;
  color: rgb(126, 198, 169);
}
.icon-one .path3:before {
  content: '\ecf0';
  margin-left: -1em;
  color: rgb(131, 200, 166);
}
.icon-one .path4:before {
  content: '\ecf1';
  margin-left: -1em;
  color: rgb(39, 179, 208);
}
.icon-one .path5:before {
  content: '\ecf2';
  margin-left: -1em;
  color: rgb(61, 186, 199);
}
.icon-one .path6:before {
  content: '\ecf3';
  margin-left: -1em;
  color: rgb(52, 185, 202);
}
.icon-one .path7:before {
  content: '\ecf4';
  margin-left: -1em;
  color: rgb(31, 165, 214);
}
.icon-one .path8:before {
  content: '\ecf5';
  margin-left: -1em;
  color: rgb(57, 186, 200);
}
.icon-one .path9:before {
  content: '\ecf6';
  margin-left: -1em;
  color: rgb(105, 193, 178);
}
.icon-one .path10:before {
  content: '\ecf7';
  margin-left: -1em;
  color: rgb(35, 173, 210);
}
.icon-one .path11:before {
  content: '\ecf8';
  margin-left: -1em;
  color: rgb(90, 190, 185);
}
.icon-one .path12:before {
  content: '\ecf9';
  margin-left: -1em;
  color: rgb(63, 186, 197);
}
.icon-one .path13:before {
  content: '\ecfa';
  margin-left: -1em;
  color: rgb(93, 190, 184);
}
.icon-one .path14:before {
  content: '\ecfb';
  margin-left: -1em;
  color: rgb(41, 183, 206);
}
.icon-one .path15:before {
  content: '\ecfc';
  margin-left: -1em;
  color: rgb(97, 191, 182);
}
.icon-one .path16:before {
  content: '\ecfd';
  margin-left: -1em;
  color: rgb(124, 198, 170);
}
.icon-one .path17:before {
  content: '\ecfe';
  margin-left: -1em;
  color: rgb(111, 195, 175);
}
.icon-one .path18:before {
  content: '\ecff';
  margin-left: -1em;
  color: rgb(106, 193, 177);
}
.icon-one .path19:before {
  content: '\ed00';
  margin-left: -1em;
  color: rgb(57, 186, 200);
}
.icon-one .path20:before {
  content: '\ed01';
  margin-left: -1em;
  color: rgb(81, 188, 189);
}
.icon-one .path21:before {
  content: '\ed02';
  margin-left: -1em;
  color: rgb(97, 191, 182);
}
.icon-one .path22:before {
  content: '\ed03';
  margin-left: -1em;
  color: rgb(30, 162, 215);
}
.icon-one .path23:before {
  content: '\ed04';
  margin-left: -1em;
  color: rgb(114, 195, 174);
}
.icon-one .path24:before {
  content: '\ed05';
  margin-left: -1em;
  color: rgb(83, 189, 188);
}
.icon-one .path25:before {
  content: '\ed06';
  margin-left: -1em;
  color: rgb(31, 167, 213);
}
.icon-one .path26:before {
  content: '\ed07';
  margin-left: -1em;
  color: rgb(109, 194, 176);
}
.icon-one .path27:before {
  content: '\ed08';
  margin-left: -1em;
  color: rgb(78, 188, 191);
}
.icon-one .path28:before {
  content: '\ed09';
  margin-left: -1em;
  color: rgb(32, 176, 210);
}
.icon-one .path29:before {
  content: '\ed0a';
  margin-left: -1em;
  color: rgb(28, 166, 214);
}
.icon-one .path30:before {
  content: '\ed0b';
  margin-left: -1em;
  color: rgb(97, 191, 182);
}
.icon-one .path31:before {
  content: '\ed0c';
  margin-left: -1em;
  color: rgb(71, 187, 193);
}
.icon-one .path32:before {
  content: '\ed0d';
  margin-left: -1em;
  color: rgb(100, 192, 180);
}
.icon-one .path33:before {
  content: '\ed0e';
  margin-left: -1em;
  color: rgb(87, 189, 186);
}
.icon-one .path34:before {
  content: '\ed0f';
  margin-left: -1em;
  color: rgb(83, 189, 188);
}
.icon-one .path35:before {
  content: '\ed10';
  margin-left: -1em;
  color: rgb(91, 190, 185);
}
.icon-one .path36:before {
  content: '\ed11';
  margin-left: -1em;
  color: rgb(109, 194, 176);
}
.icon-one .path37:before {
  content: '\ed12';
  margin-left: -1em;
  color: rgb(120, 197, 172);
}
.icon-one .path38:before {
  content: '\ed13';
  margin-left: -1em;
  color: rgb(39, 178, 208);
}
.icon-one .path39:before {
  content: '\ed14';
  margin-left: -1em;
  color: rgb(41, 182, 206);
}
.icon-one .path40:before {
  content: '\ed15';
  margin-left: -1em;
  color: rgb(123, 197, 170);
}
.icon-one .path41:before {
  content: '\ed16';
  margin-left: -1em;
  color: rgb(63, 186, 197);
}
.icon-one .path42:before {
  content: '\ed17';
  margin-left: -1em;
  color: rgb(33, 174, 210);
}
.icon-one .path43:before {
  content: '\ed18';
  margin-left: -1em;
  color: rgb(100, 192, 180);
}
.icon-one .path44:before {
  content: '\ed19';
  margin-left: -1em;
  color: rgb(114, 195, 174);
}
.icon-one .path45:before {
  content: '\ed1a';
  margin-left: -1em;
  color: rgb(57, 186, 200);
}
.icon-one .path46:before {
  content: '\ed1b';
  margin-left: -1em;
  color: rgb(83, 189, 188);
}
.icon-one .path47:before {
  content: '\ed1c';
  margin-left: -1em;
  color: rgb(83, 189, 188);
}
.icon-one .path48:before {
  content: '\ed1d';
  margin-left: -1em;
  color: rgb(93, 191, 184);
}
.icon-one .path49:before {
  content: '\ed1e';
  margin-left: -1em;
  color: rgb(30, 168, 213);
}
.icon-one .path50:before {
  content: '\ed1f';
  margin-left: -1em;
  color: rgb(69, 187, 195);
}
.icon-one .path51:before {
  content: '\ed20';
  margin-left: -1em;
  color: rgb(78, 188, 191);
}
.icon-one .path52:before {
  content: '\ed21';
  margin-left: -1em;
  color: rgb(35, 172, 210);
}
.icon-one .path53:before {
  content: '\ed22';
  margin-left: -1em;
  color: rgb(87, 189, 186);
}
.icon-one .path54:before {
  content: '\ed23';
  margin-left: -1em;
  color: rgb(69, 187, 195);
}
.icon-one .path55:before {
  content: '\ed24';
  margin-left: -1em;
  color: rgb(87, 189, 186);
}
.icon-one .path56:before {
  content: '\ed25';
  margin-left: -1em;
  color: rgb(53, 185, 202);
}
.icon-one .path57:before {
  content: '\ed26';
  margin-left: -1em;
  color: rgb(63, 186, 197);
}
.icon-one .path58:before {
  content: '\ed27';
  margin-left: -1em;
  color: rgb(50, 185, 203);
}
.icon-one .path59:before {
  content: '\ed28';
  margin-left: -1em;
  color: rgb(78, 188, 191);
}
.icon-one .path60:before {
  content: '\ed29';
  margin-left: -1em;
  color: rgb(71, 187, 193);
}
.icon-one .path61:before {
  content: '\ed2a';
  margin-left: -1em;
  color: rgb(78, 188, 191);
}
.icon-one .path62:before {
  content: '\ed2b';
  margin-left: -1em;
  color: rgb(74, 187, 192);
}
.icon-one .path63:before {
  content: '\ed2c';
  margin-left: -1em;
  color: rgb(117, 196, 173);
}
.icon-one .path64:before {
  content: '\ed2d';
  margin-left: -1em;
  color: rgb(87, 189, 186);
}
.icon-one .path65:before {
  content: '\ed2e';
  margin-left: -1em;
  color: rgb(120, 196, 172);
}
.icon-one .path66:before {
  content: '\ed2f';
  margin-left: -1em;
  color: rgb(35, 177, 209);
}
.icon-one .path67:before {
  content: '\ed30';
  margin-left: -1em;
  color: rgb(63, 186, 197);
}
.icon-one .path68:before {
  content: '\ed31';
  margin-left: -1em;
  color: rgb(33, 171, 212);
}
.icon-one .path69:before {
  content: '\ed32';
  margin-left: -1em;
  color: rgb(43, 184, 205);
}
.icon-one .path70:before {
  content: '\ed33';
  margin-left: -1em;
  color: rgb(43, 184, 205);
}
.icon-one .path71:before {
  content: '\ed34';
  margin-left: -1em;
  color: rgb(41, 181, 207);
}
.icon-one .path72:before {
  content: '\ed35';
  margin-left: -1em;
  color: rgb(69, 187, 195);
}
.icon-one .path73:before {
  content: '\ed36';
  margin-left: -1em;
  color: rgb(78, 188, 191);
}
.icon-one .path74:before {
  content: '\ed37';
  margin-left: -1em;
  color: rgb(54, 185, 201);
}
.icon-one .path75:before {
  content: '\ed38';
  margin-left: -1em;
  color: rgb(71, 187, 193);
}
.icon-one .path76:before {
  content: '\ed39';
  margin-left: -1em;
  color: rgb(31, 167, 213);
}
.icon-one .path77:before {
  content: '\ed3a';
  margin-left: -1em;
  color: rgb(50, 185, 203);
}
.icon-one .path78:before {
  content: '\ed3b';
  margin-left: -1em;
  color: rgb(103, 192, 179);
}
.icon-one .path79:before {
  content: '\ed3c';
  margin-left: -1em;
  color: rgb(71, 187, 193);
}
.icon-one .path80:before {
  content: '\ed3d';
  margin-left: -1em;
  color: rgb(117, 196, 173);
}
.icon-one .path81:before {
  content: '\ed3e';
  margin-left: -1em;
  color: rgb(46, 185, 203);
}
.icon-one .path82:before {
  content: '\ed3f';
  margin-left: -1em;
  color: rgb(46, 185, 203);
}
.icon-one .path83:before {
  content: '\ed40';
  margin-left: -1em;
  color: rgb(32, 170, 212);
}
.icon-one .path84:before {
  content: '\ed41';
  margin-left: -1em;
  color: rgb(68, 187, 195);
}
.icon-one .path85:before {
  content: '\ed42';
  margin-left: -1em;
  color: rgb(74, 187, 192);
}
.icon-one .path86:before {
  content: '\ed43';
  margin-left: -1em;
  color: rgb(69, 187, 195);
}
.icon-one .path87:before {
  content: '\ed44';
  margin-left: -1em;
  color: rgb(35, 177, 209);
}
.icon-one .path88:before {
  content: '\ed45';
  margin-left: -1em;
  color: rgb(74, 187, 192);
}
.icon-one .path89:before {
  content: '\ed46';
  margin-left: -1em;
  color: rgb(74, 187, 192);
}
.icon-one .path90:before {
  content: '\ed47';
  margin-left: -1em;
  color: rgb(74, 187, 192);
}
.icon-one .path91:before {
  content: '\ed48';
  margin-left: -1em;
  color: rgb(78, 188, 191);
}
.icon-one .path92:before {
  content: '\ed49';
  margin-left: -1em;
  color: rgb(74, 187, 192);
}
.icon-one .path93:before {
  content: '\ed4a';
  margin-left: -1em;
  color: rgb(74, 187, 192);
}
.icon-one .path94:before {
  content: '\ed4b';
  margin-left: -1em;
  color: rgb(117, 196, 173);
}
.icon-one .path95:before {
  content: '\ed4c';
  margin-left: -1em;
  color: rgb(43, 184, 205);
}
.icon-one .path96:before {
  content: '\ed4d';
  margin-left: -1em;
  color: rgb(126, 198, 169);
}
.icon-one .path97:before {
  content: '\ed4e';
  margin-left: -1em;
  color: rgb(41, 182, 206);
}
.icon-one .path98:before {
  content: '\ed4f';
  margin-left: -1em;
  color: rgb(57, 186, 200);
}
.icon-one .path99:before {
  content: '\ed50';
  margin-left: -1em;
  color: rgb(57, 186, 200);
}
.icon-one .path100:before {
  content: '\ed51';
  margin-left: -1em;
  color: rgb(57, 186, 200);
}
.icon-one .path101:before {
  content: '\ed52';
  margin-left: -1em;
  color: rgb(57, 186, 200);
}
.icon-one .path102:before {
  content: '\ed53';
  margin-left: -1em;
  color: rgb(57, 186, 200);
}
.icon-one .path103:before {
  content: '\ed54';
  margin-left: -1em;
  color: rgb(57, 186, 200);
}
.icon-one .path104:before {
  content: '\ed55';
  margin-left: -1em;
  color: rgb(57, 186, 200);
}
.icon-one .path105:before {
  content: '\ed56';
  margin-left: -1em;
  color: rgb(57, 186, 200);
}
.icon-one .path106:before {
  content: '\ed57';
  margin-left: -1em;
  color: rgb(57, 186, 200);
}
.icon-one .path107:before {
  content: '\ed58';
  margin-left: -1em;
  color: rgb(57, 186, 200);
}
.icon-one .path108:before {
  content: '\ed59';
  margin-left: -1em;
  color: rgb(30, 162, 215);
}
.icon-one .path109:before {
  content: '\ed5a';
  margin-left: -1em;
  color: rgb(30, 162, 215);
}
.icon-one .path110:before {
  content: '\ed5b';
  margin-left: -1em;
  color: rgb(52, 185, 202);
}
.icon-one .path111:before {
  content: '\ed5c';
  margin-left: -1em;
  color: rgb(32, 176, 210);
}
.icon-one .path112:before {
  content: '\ed5d';
  margin-left: -1em;
  color: rgb(93, 190, 184);
}
.icon-one .path113:before {
  content: '\ed5e';
  margin-left: -1em;
  color: rgb(93, 190, 184);
}
.icon-one .path114:before {
  content: '\ed5f';
  margin-left: -1em;
  color: rgb(93, 190, 184);
}
.icon-one .path115:before {
  content: '\ed60';
  margin-left: -1em;
  color: rgb(94, 191, 183);
}
.icon-one .path116:before {
  content: '\ed61';
  margin-left: -1em;
  color: rgb(43, 184, 205);
}
.icon-one .path117:before {
  content: '\ed62';
  margin-left: -1em;
  color: rgb(43, 184, 205);
}
.icon-one .path118:before {
  content: '\ed63';
  margin-left: -1em;
  color: rgb(43, 184, 205);
}
.icon-one .path119:before {
  content: '\ed64';
  margin-left: -1em;
  color: rgb(43, 184, 205);
}
.icon-one .path120:before {
  content: '\ed65';
  margin-left: -1em;
  color: rgb(43, 184, 205);
}
.icon-one .path121:before {
  content: '\ed66';
  margin-left: -1em;
  color: rgb(43, 184, 205);
}
.icon-one .path122:before {
  content: '\ed67';
  margin-left: -1em;
  color: rgb(126, 198, 169);
}
.icon-one .path123:before {
  content: '\ed68';
  margin-left: -1em;
  color: rgb(126, 198, 169);
}
.icon-one .path124:before {
  content: '\ed69';
  margin-left: -1em;
  color: rgb(126, 198, 169);
}
.icon-one .path125:before {
  content: '\ed6a';
  margin-left: -1em;
  color: rgb(109, 194, 176);
}
.icon-one .path126:before {
  content: '\ed6b';
  margin-left: -1em;
  color: rgb(114, 195, 174);
}
.icon-one .path127:before {
  content: '\ed6c';
  margin-left: -1em;
  color: rgb(28, 166, 214);
}
.icon-one .path128:before {
  content: '\ed6d';
  margin-left: -1em;
  color: rgb(105, 193, 178);
}
.icon-one .path129:before {
  content: '\ed6e';
  margin-left: -1em;
  color: rgb(31, 165, 214);
}
.icon-one .path130:before {
  content: '\ed6f';
  margin-left: -1em;
  color: rgb(31, 165, 214);
}
.icon-one .path131:before {
  content: '\ed70';
  margin-left: -1em;
  color: rgb(30, 168, 213);
}
.icon-one .path132:before {
  content: '\ed71';
  margin-left: -1em;
  color: rgb(31, 165, 214);
}
.icon-one .path133:before {
  content: '\ed72';
  margin-left: -1em;
  color: rgb(31, 165, 214);
}
.icon-one .path134:before {
  content: '\ed73';
  margin-left: -1em;
  color: rgb(31, 165, 214);
}
.icon-one .path135:before {
  content: '\ed74';
  margin-left: -1em;
  color: rgb(31, 165, 214);
}
.icon-one .path136:before {
  content: '\ed75';
  margin-left: -1em;
  color: rgb(31, 165, 214);
}
.icon-one .path137:before {
  content: '\ed76';
  margin-left: -1em;
  color: rgb(31, 165, 214);
}
.icon-one .path138:before {
  content: '\ed77';
  margin-left: -1em;
  color: rgb(31, 165, 214);
}
.icon-one .path139:before {
  content: '\ed78';
  margin-left: -1em;
  color: rgb(31, 165, 214);
}
.icon-one .path140:before {
  content: '\ed79';
  margin-left: -1em;
  color: rgb(31, 165, 214);
}
.icon-one .path141:before {
  content: '\ed7a';
  margin-left: -1em;
  color: rgb(74, 187, 192);
}
.icon-one .path142:before {
  content: '\ed7b';
  margin-left: -1em;
  color: rgb(69, 187, 195);
}
.icon-one .path143:before {
  content: '\ed7c';
  margin-left: -1em;
  color: rgb(117, 196, 173);
}
.icon-one .path144:before {
  content: '\ed7d';
  margin-left: -1em;
  color: rgb(117, 196, 173);
}
.icon-one .path145:before {
  content: '\ed7e';
  margin-left: -1em;
  color: rgb(124, 198, 170);
}
.icon-one .path146:before {
  content: '\ed7f';
  margin-left: -1em;
  color: rgb(117, 196, 173);
}
.icon-one .path147:before {
  content: '\ed80';
  margin-left: -1em;
  color: rgb(117, 196, 173);
}
.icon-one .path148:before {
  content: '\ed81';
  margin-left: -1em;
  color: rgb(43, 184, 205);
}
.icon-one .path149:before {
  content: '\ed82';
  margin-left: -1em;
  color: rgb(43, 184, 205);
}
.icon-one .path150:before {
  content: '\ed83';
  margin-left: -1em;
  color: rgb(43, 184, 205);
}
.icon-one .path151:before {
  content: '\ed84';
  margin-left: -1em;
  color: rgb(43, 184, 205);
}
.icon-one .path152:before {
  content: '\ed85';
  margin-left: -1em;
  color: rgb(43, 184, 205);
}
.icon-one .path153:before {
  content: '\ed86';
  margin-left: -1em;
  color: rgb(43, 184, 205);
}
.icon-one .path154:before {
  content: '\ed87';
  margin-left: -1em;
  color: rgb(43, 184, 205);
}
.icon-one .path155:before {
  content: '\ed88';
  margin-left: -1em;
  color: rgb(43, 184, 205);
}
.icon-one .path156:before {
  content: '\ed89';
  margin-left: -1em;
  color: rgb(32, 170, 212);
}
.icon-one .path157:before {
  content: '\ed8a';
  margin-left: -1em;
  color: rgb(32, 170, 212);
}
.icon-one .path158:before {
  content: '\ed8b';
  margin-left: -1em;
  color: rgb(32, 170, 212);
}
.icon-one .path159:before {
  content: '\ed8c';
  margin-left: -1em;
  color: rgb(50, 185, 203);
}
.icon-one .path160:before {
  content: '\ed8d';
  margin-left: -1em;
  color: rgb(50, 185, 203);
}
.icon-one .path161:before {
  content: '\ed8e';
  margin-left: -1em;
  color: rgb(50, 185, 203);
}
.icon-one .path162:before {
  content: '\ed8f';
  margin-left: -1em;
  color: rgb(57, 186, 200);
}
.icon-one .path163:before {
  content: '\ed90';
  margin-left: -1em;
  color: rgb(50, 185, 203);
}
.icon-one .path164:before {
  content: '\ed91';
  margin-left: -1em;
  color: rgb(46, 185, 203);
}
.icon-one .path165:before {
  content: '\ed92';
  margin-left: -1em;
  color: rgb(78, 188, 191);
}
.icon-one .path166:before {
  content: '\ed93';
  margin-left: -1em;
  color: rgb(78, 188, 191);
}
.icon-one .path167:before {
  content: '\ed94';
  margin-left: -1em;
  color: rgb(50, 185, 203);
}
.icon-one .path168:before {
  content: '\ed95';
  margin-left: -1em;
  color: rgb(50, 185, 203);
}
.icon-one .path169:before {
  content: '\ed96';
  margin-left: -1em;
  color: rgb(69, 187, 195);
}
.icon-one .path170:before {
  content: '\ed97';
  margin-left: -1em;
  color: rgb(30, 168, 213);
}
.icon-one .path171:before {
  content: '\ed98';
  margin-left: -1em;
  color: rgb(30, 168, 213);
}
.icon-one .path172:before {
  content: '\ed99';
  margin-left: -1em;
  color: rgb(30, 168, 213);
}
.icon-one .path173:before {
  content: '\ed9a';
  margin-left: -1em;
  color: rgb(46, 185, 203);
}
.icon-one .path174:before {
  content: '\ed9b';
  margin-left: -1em;
  color: rgb(78, 188, 191);
}
.icon-one .path175:before {
  content: '\ed9c';
  margin-left: -1em;
  color: rgb(78, 188, 191);
}
.icon-one .path176:before {
  content: '\ed9d';
  margin-left: -1em;
  color: rgb(71, 187, 193);
}
.icon-one .path177:before {
  content: '\ed9e';
  margin-left: -1em;
  color: rgb(78, 188, 191);
}
.icon-one .path178:before {
  content: '\ed9f';
  margin-left: -1em;
  color: rgb(43, 184, 205);
}
.icon-one .path179:before {
  content: '\eda0';
  margin-left: -1em;
  color: rgb(46, 185, 203);
}
.icon-one .path180:before {
  content: '\eda1';
  margin-left: -1em;
  color: rgb(49, 185, 203);
}
.icon-one .path181:before {
  content: '\eda2';
  margin-left: -1em;
  color: rgb(97, 191, 182);
}
.icon-one .path182:before {
  content: '\eda3';
  margin-left: -1em;
  color: rgb(100, 192, 180);
}
.icon-one .path183:before {
  content: '\eda4';
  margin-left: -1em;
  color: rgb(103, 192, 179);
}
.icon-one .path184:before {
  content: '\eda5';
  margin-left: -1em;
  color: rgb(43, 184, 205);
}
.icon-one .path185:before {
  content: '\eda6';
  margin-left: -1em;
  color: rgb(43, 184, 205);
}
.icon-one .path186:before {
  content: '\eda7';
  margin-left: -1em;
  color: rgb(43, 184, 205);
}
.icon-one .path187:before {
  content: '\eda8';
  margin-left: -1em;
  color: rgb(43, 184, 205);
}
.icon-one .path188:before {
  content: '\eda9';
  margin-left: -1em;
  color: rgb(43, 184, 205);
}
.icon-one .path189:before {
  content: '\edaa';
  margin-left: -1em;
  color: rgb(43, 184, 205);
}
.icon-one .path190:before {
  content: '\edab';
  margin-left: -1em;
  color: rgb(49, 185, 203);
}
.icon-one .path191:before {
  content: '\edac';
  margin-left: -1em;
  color: rgb(74, 187, 192);
}
.icon-one .path192:before {
  content: '\edad';
  margin-left: -1em;
  color: rgb(74, 187, 192);
}
.icon-one .path193:before {
  content: '\edae';
  margin-left: -1em;
  color: rgb(74, 187, 192);
}
.icon-one .path194:before {
  content: '\edaf';
  margin-left: -1em;
  color: rgb(50, 185, 203);
}
.icon-one .path195:before {
  content: '\edb0';
  margin-left: -1em;
  color: rgb(49, 185, 203);
}
.icon-one .path196:before {
  content: '\edb1';
  margin-left: -1em;
  color: rgb(46, 185, 203);
}
.icon-ksm .path1:before {
  content: '\edc2';
  color: rgb(0, 0, 0);
}
.icon-ksm .path2:before {
  content: '\edc3';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-algo:before {
  content: '\edc4';
}
.icon-luna .path1:before {
  content: '\ebbb';
  color: rgb(23, 40, 82);
}
.icon-luna .path2:before {
  content: '\ebbc';
  margin-left: -1em;
  color: rgb(255, 216, 61);
}
.icon-luna .path3:before {
  content: '\ebbd';
  margin-left: -1em;
  color: rgb(255, 216, 61);
}
.icon-luna .path4:before {
  content: '\ebbe';
  margin-left: -1em;
  color: rgb(255, 111, 3);
}
.icon-xym .path1:before {
  content: '\ebbf';
  color: rgb(242, 156, 91);
}
.icon-xym .path2:before {
  content: '\ebc0';
  margin-left: -1em;
  color: rgb(68, 0, 78);
}
.icon-xym .path3:before {
  content: '\ebc1';
  margin-left: -1em;
  color: rgb(247, 0, 247);
}
.icon-xym .path4:before {
  content: '\ebc2';
  margin-left: -1em;
  color: rgb(247, 1, 248);
}
.icon-xym .path5:before {
  content: '\ebc3';
  margin-left: -1em;
  color: rgb(247, 1, 247);
}
.icon-xym .path6:before {
  content: '\ebc4';
  margin-left: -1em;
  color: rgb(197, 0, 200);
}
.icon-xym .path7:before {
  content: '\ebc5';
  margin-left: -1em;
  color: rgb(210, 0, 213);
}
.icon-xym .path8:before {
  content: '\ebc6';
  margin-left: -1em;
  color: rgb(235, 0, 236);
}
.icon-xym .path9:before {
  content: '\ebc7';
  margin-left: -1em;
  color: rgb(199, 0, 202);
}
.icon-xym .path10:before {
  content: '\ebc8';
  margin-left: -1em;
  color: rgb(152, 0, 158);
}
.icon-xym .path11:before {
  content: '\ebc9';
  margin-left: -1em;
  color: rgb(239, 0, 240);
}
.icon-xym .path12:before {
  content: '\ebca';
  margin-left: -1em;
  color: rgb(235, 0, 236);
}
.icon-xym .path13:before {
  content: '\ebcb';
  margin-left: -1em;
  color: rgb(188, 0, 192);
}
.icon-xym .path14:before {
  content: '\ebcc';
  margin-left: -1em;
  color: rgb(188, 1, 192);
}
.icon-xym .path15:before {
  content: '\ebcd';
  margin-left: -1em;
  color: rgb(234, 0, 235);
}
.icon-xym .path16:before {
  content: '\ebce';
  margin-left: -1em;
  color: rgb(163, 0, 169);
}
.icon-xym .path17:before {
  content: '\ebcf';
  margin-left: -1em;
  color: rgb(243, 0, 244);
}
.icon-xym .path18:before {
  content: '\ebd0';
  margin-left: -1em;
  color: rgb(199, 0, 202);
}
.icon-xym .path19:before {
  content: '\ebd1';
  margin-left: -1em;
  color: rgb(141, 0, 147);
}
.icon-xym .path20:before {
  content: '\ebd2';
  margin-left: -1em;
  color: rgb(208, 0, 210);
}
.icon-xym .path21:before {
  content: '\ebd3';
  margin-left: -1em;
  color: rgb(238, 1, 239);
}
.icon-xym .path22:before {
  content: '\ebd4';
  margin-left: -1em;
  color: rgb(241, 0, 241);
}
.icon-xym .path23:before {
  content: '\ebd5';
  margin-left: -1em;
  color: rgb(129, 0, 136);
}
.icon-xym .path24:before {
  content: '\ebd6';
  margin-left: -1em;
  color: rgb(125, 0, 132);
}
.icon-xym .path25:before {
  content: '\ebd7';
  margin-left: -1em;
  color: rgb(179, 0, 183);
}
.icon-xym .path26:before {
  content: '\ebd8';
  margin-left: -1em;
  color: rgb(237, 0, 238);
}
.icon-xym .path27:before {
  content: '\ebd9';
  margin-left: -1em;
  color: rgb(116, 1, 124);
}
.icon-xym .path28:before {
  content: '\ebda';
  margin-left: -1em;
  color: rgb(221, 0, 223);
}
.icon-xym .path29:before {
  content: '\ebdb';
  margin-left: -1em;
  color: rgb(143, 0, 149);
}
.icon-xym .path30:before {
  content: '\ebdc';
  margin-left: -1em;
  color: rgb(76, 3, 86);
}
.icon-xym .path31:before {
  content: '\ebdd';
  margin-left: -1em;
  color: rgb(129, 0, 136);
}
.icon-xym .path32:before {
  content: '\ebde';
  margin-left: -1em;
  color: rgb(102, 0, 111);
}
.icon-xym .path33:before {
  content: '\ebdf';
  margin-left: -1em;
  color: rgb(242, 0, 243);
}
.icon-xym .path34:before {
  content: '\ebe0';
  margin-left: -1em;
  color: rgb(151, 0, 157);
}
.icon-xym .path35:before {
  content: '\ebe1';
  margin-left: -1em;
  color: rgb(180, 0, 184);
}
.icon-xym .path36:before {
  content: '\ebe2';
  margin-left: -1em;
  color: rgb(206, 1, 209);
}
.icon-xym .path37:before {
  content: '\ebe3';
  margin-left: -1em;
  color: rgb(121, 1, 129);
}
.icon-xym .path38:before {
  content: '\ebe4';
  margin-left: -1em;
  color: rgb(169, 0, 174);
}
.icon-xym .path39:before {
  content: '\ebe5';
  margin-left: -1em;
  color: rgb(228, 1, 229);
}
.icon-xym .path40:before {
  content: '\ebe6';
  margin-left: -1em;
  color: rgb(104, 1, 112);
}
.icon-xym .path41:before {
  content: '\ebe7';
  margin-left: -1em;
  color: rgb(215, 1, 217);
}
.icon-xym .path42:before {
  content: '\ebe8';
  margin-left: -1em;
  color: rgb(244, 1, 245);
}
.icon-xym .path43:before {
  content: '\ebe9';
  margin-left: -1em;
  color: rgb(163, 0, 168);
}
.icon-xym .path44:before {
  content: '\ebea';
  margin-left: -1em;
  color: rgb(217, 0, 219);
}
.icon-xym .path45:before {
  content: '\ebeb';
  margin-left: -1em;
  color: rgb(186, 0, 190);
}
.icon-xym .path46:before {
  content: '\ebec';
  margin-left: -1em;
  color: rgb(228, 0, 229);
}
.icon-xym .path47:before {
  content: '\ebed';
  margin-left: -1em;
  color: rgb(180, 1, 184);
}
.icon-xym .path48:before {
  content: '\ebee';
  margin-left: -1em;
  color: rgb(125, 0, 132);
}
.icon-xym .path49:before {
  content: '\ebef';
  margin-left: -1em;
  color: rgb(109, 1, 117);
}
.icon-xym .path50:before {
  content: '\ebf0';
  margin-left: -1em;
  color: rgb(98, 1, 106);
}
.icon-xym .path51:before {
  content: '\ebf1';
  margin-left: -1em;
  color: rgb(229, 1, 231);
}
.icon-xym .path52:before {
  content: '\ebf2';
  margin-left: -1em;
  color: rgb(121, 0, 129);
}
.icon-xym .path53:before {
  content: '\ebf3';
  margin-left: -1em;
  color: rgb(117, 0, 125);
}
.icon-xym .path54:before {
  content: '\ebf4';
  margin-left: -1em;
  color: rgb(125, 1, 132);
}
.icon-xym .path55:before {
  content: '\ebf5';
  margin-left: -1em;
  color: rgb(136, 1, 142);
}
.icon-xym .path56:before {
  content: '\ebf6';
  margin-left: -1em;
  color: rgb(77, 3, 87);
}
.icon-xym .path57:before {
  content: '\ebf7';
  margin-left: -1em;
  color: rgb(113, 0, 121);
}
.icon-xym .path58:before {
  content: '\ebf8';
  margin-left: -1em;
  color: rgb(91, 1, 100);
}
.icon-xym .path59:before {
  content: '\ebf9';
  margin-left: -1em;
  color: rgb(191, 0, 195);
}
.icon-xym .path60:before {
  content: '\ebfa';
  margin-left: -1em;
  color: rgb(211, 0, 214);
}
.icon-xym .path61:before {
  content: '\ebfb';
  margin-left: -1em;
  color: rgb(157, 1, 163);
}
.icon-xym .path62:before {
  content: '\ebfc';
  margin-left: -1em;
  color: rgb(170, 1, 174);
}
.icon-xym .path63:before {
  content: '\ebfd';
  margin-left: -1em;
  color: rgb(175, 0, 180);
}
.icon-xym .path64:before {
  content: '\ebfe';
  margin-left: -1em;
  color: rgb(224, 0, 225);
}
.icon-xym .path65:before {
  content: '\ebff';
  margin-left: -1em;
  color: rgb(223, 0, 225);
}
.icon-xym .path66:before {
  content: '\ec00';
  margin-left: -1em;
  color: rgb(92, 1, 101);
}
.icon-xym .path67:before {
  content: '\ec01';
  margin-left: -1em;
  color: rgb(97, 1, 105);
}
.icon-xym .path68:before {
  content: '\ec02';
  margin-left: -1em;
  color: rgb(112, 0, 120);
}
.icon-xym .path69:before {
  content: '\ec03';
  margin-left: -1em;
  color: rgb(105, 1, 114);
}
.icon-xym .path70:before {
  content: '\ec04';
  margin-left: -1em;
  color: rgb(78, 4, 87);
}
.icon-xym .path71:before {
  content: '\ec05';
  margin-left: -1em;
  color: rgb(174, 0, 178);
}
.icon-xym .path72:before {
  content: '\ec06';
  margin-left: -1em;
  color: rgb(84, 1, 94);
}
.icon-xym .path73:before {
  content: '\ec07';
  margin-left: -1em;
  color: rgb(245, 1, 245);
}
.icon-xym .path74:before {
  content: '\ec08';
  margin-left: -1em;
  color: rgb(134, 1, 141);
}
.icon-xym .path75:before {
  content: '\ec09';
  margin-left: -1em;
  color: rgb(87, 1, 96);
}
.icon-xym .path76:before {
  content: '\ec0a';
  margin-left: -1em;
  color: rgb(149, 0, 155);
}
.icon-xym .path77:before {
  content: '\ec0b';
  margin-left: -1em;
  color: rgb(102, 1, 110);
}
.icon-xym .path78:before {
  content: '\ec0c';
  margin-left: -1em;
  color: rgb(219, 0, 221);
}
.icon-xym .path79:before {
  content: '\ec0d';
  margin-left: -1em;
  color: rgb(82, 3, 91);
}
.icon-xym .path80:before {
  content: '\ec0e';
  margin-left: -1em;
  color: rgb(148, 1, 154);
}
.icon-xym .path81:before {
  content: '\ec0f';
  margin-left: -1em;
  color: rgb(169, 0, 174);
}
.icon-xym .path82:before {
  content: '\ec10';
  margin-left: -1em;
  color: rgb(169, 0, 174);
}
.icon-xym .path83:before {
  content: '\ec11';
  margin-left: -1em;
  color: rgb(157, 0, 163);
}
.icon-xym .path84:before {
  content: '\ec12';
  margin-left: -1em;
  color: rgb(112, 0, 120);
}
.icon-xym .path85:before {
  content: '\ec13';
  margin-left: -1em;
  color: rgb(129, 0, 136);
}
.icon-xym .path86:before {
  content: '\ec14';
  margin-left: -1em;
  color: rgb(117, 0, 124);
}
.icon-xym .path87:before {
  content: '\ec15';
  margin-left: -1em;
  color: rgb(121, 0, 128);
}
.icon-xym .path88:before {
  content: '\ec16';
  margin-left: -1em;
  color: rgb(107, 0, 115);
}
.icon-xym .path89:before {
  content: '\ec17';
  margin-left: -1em;
  color: rgb(97, 0, 106);
}
.icon-xym .path90:before {
  content: '\ec18';
  margin-left: -1em;
  color: rgb(92, 0, 101);
}
.icon-xym .path91:before {
  content: '\ec19';
  margin-left: -1em;
  color: rgb(143, 0, 149);
}
.icon-xym .path92:before {
  content: '\ec1a';
  margin-left: -1em;
  color: rgb(134, 0, 141);
}
.icon-xym .path93:before {
  content: '\ec1b';
  margin-left: -1em;
  color: rgb(109, 2, 117);
}
.icon-xym .path94:before {
  content: '\ec1c';
  margin-left: -1em;
  color: rgb(87, 0, 97);
}
.icon-xym .path95:before {
  content: '\ec1d';
  margin-left: -1em;
  color: rgb(82, 0, 92);
}
.icon-xym .path96:before {
  content: '\ec1e';
  margin-left: -1em;
  color: rgb(169, 0, 174);
}
.icon-xym .path97:before {
  content: '\ec1f';
  margin-left: -1em;
  color: rgb(182, 0, 186);
}
.icon-xym .path98:before {
  content: '\ec20';
  margin-left: -1em;
  color: rgb(215, 0, 217);
}
.icon-xym .path99:before {
  content: '\ec21';
  margin-left: -1em;
  color: rgb(139, 0, 145);
}
.icon-xym .path100:before {
  content: '\ec22';
  margin-left: -1em;
  color: rgb(148, 1, 154);
}
.icon-xym .path101:before {
  content: '\ec23';
  margin-left: -1em;
  color: rgb(148, 1, 154);
}
.icon-xym .path102:before {
  content: '\ec24';
  margin-left: -1em;
  color: rgb(148, 1, 154);
}
.icon-xym .path103:before {
  content: '\ec25';
  margin-left: -1em;
  color: rgb(164, 0, 169);
}
.icon-shib .path1:before {
  content: '\edb2';
  color: rgb(240, 5, 0);
}
.icon-shib .path2:before {
  content: '\edb3';
  margin-left: -0.98828125em;
  color: rgb(240, 5, 0);
}
.icon-shib .path3:before {
  content: '\edb4';
  margin-left: -0.98828125em;
  color: rgb(0, 0, 0);
}
.icon-shib .path4:before {
  content: '\edb5';
  margin-left: -0.98828125em;
  color: rgb(0, 0, 0);
}
.icon-shib .path5:before {
  content: '\edb6';
  margin-left: -0.98828125em;
  color: rgb(0, 0, 0);
}
.icon-shib .path6:before {
  content: '\edb7';
  margin-left: -0.98828125em;
  color: rgb(0, 0, 0);
}
.icon-shib .path7:before {
  content: '\edb8';
  margin-left: -0.98828125em;
  color: rgb(255, 255, 255);
}
.icon-shib .path8:before {
  content: '\edb9';
  margin-left: -0.98828125em;
  color: rgb(255, 164, 9);
}
.icon-shib .path9:before {
  content: '\edba';
  margin-left: -0.98828125em;
  color: rgb(255, 147, 0);
}
.icon-shib .path10:before {
  content: '\edbb';
  margin-left: -0.98828125em;
  color: rgb(255, 131, 0);
}
.icon-shib .path11:before {
  content: '\edbc';
  margin-left: -0.98828125em;
  color: rgb(255, 131, 0);
}
.icon-shib .path12:before {
  content: '\edbd';
  margin-left: -0.98828125em;
  color: rgb(255, 147, 0);
}
.icon-shib .path13:before {
  content: '\edbe';
  margin-left: -0.98828125em;
  color: rgb(255, 131, 0);
}
.icon-shib .path14:before {
  content: '\edbf';
  margin-left: -0.98828125em;
  color: rgb(255, 131, 0);
}
.icon-shib .path15:before {
  content: '\edc0';
  margin-left: -0.98828125em;
  color: rgb(255, 255, 255);
}
.icon-shib .path16:before {
  content: '\edc1';
  margin-left: -0.98828125em;
  color: rgb(255, 255, 255);
}
.icon-usdc .path1:before {
  content: '\e91b';
  color: rgb(39, 117, 202);
}
.icon-usdc .path2:before {
  content: '\e91c';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-usdc .path3:before {
  content: '\e91d';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-dep .path1:before {
  content: '\edca';
  color: rgb(207, 156, 61);
}
.icon-dep .path2:before {
  content: '\edcb';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-dep .path3:before {
  content: '\edcc';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-dep .path4:before {
  content: '\edcd';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-dep .path5:before {
  content: '\edce';
  margin-left: -1em;
  color: rgb(207, 156, 61);
}
.icon-dep .path6:before {
  content: '\edcf';
  margin-left: -1em;
  color: rgb(207, 156, 61);
}
.icon-dep .path7:before {
  content: '\edd0';
  margin-left: -1em;
  color: rgb(207, 156, 61);
}
.icon-dep .path8:before {
  content: '\edd1';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-plt .path1:before {
  content: '\eb82';
  color: rgb(77, 165, 198);
}
.icon-plt .path2:before {
  content: '\eb83';
  margin-left: -1em;
  color: rgb(253, 253, 254);
}
.icon-plt .path3:before {
  content: '\eb84';
  margin-left: -1em;
  color: rgb(253, 254, 254);
}
.icon-plt .path4:before {
  content: '\eb85';
  margin-left: -1em;
  color: rgb(253, 253, 254);
}
.icon-plt .path5:before {
  content: '\eb86';
  margin-left: -1em;
  color: rgb(165, 209, 226);
}
.icon-plt .path6:before {
  content: '\eb87';
  margin-left: -1em;
  color: rgb(77, 165, 198);
}
.icon-plt .path7:before {
  content: '\eb88';
  margin-left: -1em;
  color: rgb(253, 253, 254);
}
.icon-boba .path1:before {
  content: '\edd2';
  color: rgb(0, 0, 0);
}
.icon-boba .path2:before {
  content: '\edd3';
  margin-left: -1.0107421875em;
  color: rgb(28, 215, 209);
}
.icon-boba .path3:before {
  content: '\edd4';
  margin-left: -1.0107421875em;
  color: rgb(28, 215, 209);
}
.icon-boba .path4:before {
  content: '\edd5';
  margin-left: -1.0107421875em;
  color: rgb(28, 215, 209);
}
.icon-boba .path5:before {
  content: '\edd6';
  margin-left: -1.0107421875em;
  color: rgb(253, 253, 253);
}
.icon-boba .path6:before {
  content: '\edd7';
  margin-left: -1.0107421875em;
  color: rgb(249, 254, 232);
}
.icon-boba .path7:before {
  content: '\edd8';
  margin-left: -1.0107421875em;
  color: rgb(253, 253, 253);
}
.icon-boba .path8:before {
  content: '\edd9';
  margin-left: -1.0107421875em;
  color: rgb(246, 254, 214);
}
.icon-boba .path9:before {
  content: '\edda';
  margin-left: -1.0107421875em;
  color: rgb(253, 253, 253);
}
.icon-boba .path10:before {
  content: '\eddb';
  margin-left: -1.0107421875em;
  color: rgb(253, 253, 253);
}
.icon-boba .path11:before {
  content: '\eddc';
  margin-left: -1.0107421875em;
  color: rgb(220, 248, 247);
}
.icon-boba .path12:before {
  content: '\eddd';
  margin-left: -1.0107421875em;
  color: rgb(240, 254, 185);
}
.icon-boba .path13:before {
  content: '\edde';
  margin-left: -1.0107421875em;
  color: rgb(247, 254, 217);
}
.icon-boba .path14:before {
  content: '\eddf';
  margin-left: -1.0107421875em;
  color: rgb(238, 254, 176);
}
.icon-boba .path15:before {
  content: '\ede0';
  margin-left: -1.0107421875em;
  color: rgb(244, 254, 205);
}
.icon-boba .path16:before {
  content: '\ede1';
  margin-left: -1.0107421875em;
  color: rgb(221, 248, 248);
}
.icon-boba .path17:before {
  content: '\ede2';
  margin-left: -1.0107421875em;
  color: rgb(209, 246, 245);
}
.icon-boba .path18:before {
  content: '\ede3';
  margin-left: -1.0107421875em;
  color: rgb(253, 253, 253);
}
.icon-boba .path19:before {
  content: '\ede4';
  margin-left: -1.0107421875em;
  color: rgb(249, 254, 232);
}
.icon-boba .path20:before {
  content: '\ede5';
  margin-left: -1.0107421875em;
  color: rgb(246, 254, 214);
}
.icon-boba .path21:before {
  content: '\ede6';
  margin-left: -1.0107421875em;
  color: rgb(230, 250, 249);
}
.icon-boba .path22:before {
  content: '\ede7';
  margin-left: -1.0107421875em;
  color: rgb(253, 253, 253);
}
.icon-boba .path23:before {
  content: '\ede8';
  margin-left: -1.0107421875em;
  color: rgb(179, 241, 239);
}
.icon-boba .path24:before {
  content: '\ede9';
  margin-left: -1.0107421875em;
  color: rgb(247, 254, 218);
}
.icon-boba .path25:before {
  content: '\edea';
  margin-left: -1.0107421875em;
  color: rgb(225, 249, 249);
}
.icon-boba .path26:before {
  content: '\edeb';
  margin-left: -1.0107421875em;
  color: rgb(230, 250, 249);
}
.icon-boba .path27:before {
  content: '\edec';
  margin-left: -1.0107421875em;
  color: rgb(253, 253, 253);
}
.icon-boba .path28:before {
  content: '\eded';
  margin-left: -1.0107421875em;
  color: rgb(242, 254, 193);
}
.icon-boba .path29:before {
  content: '\edee';
  margin-left: -1.0107421875em;
  color: rgb(246, 254, 214);
}
.icon-boba .path30:before {
  content: '\edef';
  margin-left: -1.0107421875em;
  color: rgb(247, 254, 218);
}
.icon-boba .path31:before {
  content: '\edf0';
  margin-left: -1.0107421875em;
  color: rgb(253, 253, 253);
}
.icon-boba .path32:before {
  content: '\edf1';
  margin-left: -1.0107421875em;
  color: rgb(247, 254, 218);
}
.icon-boba .path33:before {
  content: '\edf2';
  margin-left: -1.0107421875em;
  color: rgb(212, 254, 44);
}
.icon-boba .path34:before {
  content: '\edf3';
  margin-left: -1.0107421875em;
  color: rgb(242, 254, 195);
}
.icon-boba .path35:before {
  content: '\edf4';
  margin-left: -1.0107421875em;
  color: rgb(242, 254, 195);
}
.icon-boba .path36:before {
  content: '\edf5';
  margin-left: -1.0107421875em;
  color: rgb(247, 254, 217);
}
.icon-boba .path37:before {
  content: '\edf6';
  margin-left: -1.0107421875em;
  color: rgb(242, 254, 196);
}
.icon-boba .path38:before {
  content: '\edf7';
  margin-left: -1.0107421875em;
  color: rgb(249, 254, 232);
}
.icon-boba .path39:before {
  content: '\edf8';
  margin-left: -1.0107421875em;
  color: rgb(163, 238, 236);
}
.icon-boba .path40:before {
  content: '\edf9';
  margin-left: -1.0107421875em;
  color: rgb(64, 221, 216);
}
.icon-boba .path41:before {
  content: '\edfa';
  margin-left: -1.0107421875em;
  color: rgb(156, 237, 235);
}
.icon-boba .path42:before {
  content: '\edfb';
  margin-left: -1.0107421875em;
  color: rgb(163, 238, 236);
}
.icon-boba .path43:before {
  content: '\edfc';
  margin-left: -1.0107421875em;
  color: rgb(147, 236, 233);
}
.icon-boba .path44:before {
  content: '\edfd';
  margin-left: -1.0107421875em;
  color: rgb(244, 254, 205);
}
.icon-boba .path45:before {
  content: '\edfe';
  margin-left: -1.0107421875em;
  color: rgb(234, 254, 152);
}
.icon-boba .path46:before {
  content: '\edff';
  margin-left: -1.0107421875em;
  color: rgb(234, 254, 152);
}
.icon-boba .path47:before {
  content: '\ee00';
  margin-left: -1.0107421875em;
  color: rgb(242, 254, 193);
}
.icon-boba .path48:before {
  content: '\ee01';
  margin-left: -1.0107421875em;
  color: rgb(234, 254, 152);
}
.icon-boba .path49:before {
  content: '\ee02';
  margin-left: -1.0107421875em;
  color: rgb(243, 254, 201);
}
.icon-boba .path50:before {
  content: '\ee03';
  margin-left: -1.0107421875em;
  color: rgb(242, 254, 193);
}
.icon-boba .path51:before {
  content: '\ee04';
  margin-left: -1.0107421875em;
  color: rgb(243, 254, 201);
}
.icon-boba .path52:before {
  content: '\ee05';
  margin-left: -1.0107421875em;
  color: rgb(243, 254, 201);
}
.icon-boba .path53:before {
  content: '\ee06';
  margin-left: -1.0107421875em;
  color: rgb(234, 254, 152);
}
.icon-boba .path54:before {
  content: '\ee07';
  margin-left: -1.0107421875em;
  color: rgb(241, 254, 188);
}
.icon-boba .path55:before {
  content: '\ee08';
  margin-left: -1.0107421875em;
  color: rgb(234, 254, 152);
}
.icon-boba .path56:before {
  content: '\ee09';
  margin-left: -1.0107421875em;
  color: rgb(241, 254, 188);
}
.icon-boba .path57:before {
  content: '\ee0a';
  margin-left: -1.0107421875em;
  color: rgb(234, 254, 152);
}
.icon-boba .path58:before {
  content: '\ee0b';
  margin-left: -1.0107421875em;
  color: rgb(241, 254, 188);
}
.icon-boba .path59:before {
  content: '\ee0c';
  margin-left: -1.0107421875em;
  color: rgb(234, 254, 152);
}
.icon-boba .path60:before {
  content: '\ee0d';
  margin-left: -1.0107421875em;
  color: rgb(241, 254, 188);
}
.icon-boba .path61:before {
  content: '\ee0e';
  margin-left: -1.0107421875em;
  color: rgb(234, 254, 152);
}
.icon-boba .path62:before {
  content: '\ee0f';
  margin-left: -1.0107421875em;
  color: rgb(241, 254, 188);
}
.icon-boba .path63:before {
  content: '\ee10';
  margin-left: -1.0107421875em;
  color: rgb(241, 254, 188);
}
.icon-boba .path64:before {
  content: '\ee11';
  margin-left: -1.0107421875em;
  color: rgb(241, 254, 188);
}
.icon-boba .path65:before {
  content: '\ee12';
  margin-left: -1.0107421875em;
  color: rgb(240, 254, 185);
}
.icon-boba .path66:before {
  content: '\ee13';
  margin-left: -1.0107421875em;
  color: rgb(234, 254, 154);
}
.icon-boba .path67:before {
  content: '\ee14';
  margin-left: -1.0107421875em;
  color: rgb(245, 254, 210);
}
.icon-boba .path68:before {
  content: '\ee15';
  margin-left: -1.0107421875em;
  color: rgb(247, 254, 220);
}
.icon-boba .path69:before {
  content: '\ee16';
  margin-left: -1.0107421875em;
  color: rgb(245, 254, 210);
}
.icon-boba .path70:before {
  content: '\ee17';
  margin-left: -1.0107421875em;
  color: rgb(203, 254, 0);
}
.icon-boba .path71:before {
  content: '\ee18';
  margin-left: -1.0107421875em;
  color: rgb(214, 254, 54);
}
.icon-boba .path72:before {
  content: '\ee19';
  margin-left: -1.0107421875em;
  color: rgb(219, 254, 79);
}
.icon-boba .path73:before {
  content: '\ee1a';
  margin-left: -1.0107421875em;
  color: rgb(216, 254, 66);
}
.icon-boba .path74:before {
  content: '\ee1b';
  margin-left: -1.0107421875em;
  color: rgb(246, 254, 216);
}
.icon-boba .path75:before {
  content: '\ee1c';
  margin-left: -1.0107421875em;
  color: rgb(238, 254, 176);
}
.icon-boba .path76:before {
  content: '\ee1d';
  margin-left: -1.0107421875em;
  color: rgb(238, 254, 176);
}
.icon-boba .path77:before {
  content: '\ee1e';
  margin-left: -1.0107421875em;
  color: rgb(238, 254, 176);
}
.icon-boba .path78:before {
  content: '\ee1f';
  margin-left: -1.0107421875em;
  color: rgb(238, 254, 176);
}
.icon-boba .path79:before {
  content: '\ee20';
  margin-left: -1.0107421875em;
  color: rgb(238, 254, 176);
}
.icon-boba .path80:before {
  content: '\ee21';
  margin-left: -1.0107421875em;
  color: rgb(238, 254, 176);
}
.icon-boba .path81:before {
  content: '\ee22';
  margin-left: -1.0107421875em;
  color: rgb(238, 254, 176);
}
.icon-boba .path82:before {
  content: '\ee23';
  margin-left: -1.0107421875em;
  color: rgb(242, 254, 197);
}
.icon-boba .path83:before {
  content: '\ee24';
  margin-left: -1.0107421875em;
  color: rgb(238, 254, 176);
}
.icon-boba .path84:before {
  content: '\ee25';
  margin-left: -1.0107421875em;
  color: rgb(238, 254, 176);
}
.icon-boba .path85:before {
  content: '\ee26';
  margin-left: -1.0107421875em;
  color: rgb(238, 254, 176);
}
.icon-boba .path86:before {
  content: '\ee27';
  margin-left: -1.0107421875em;
  color: rgb(238, 254, 176);
}
.icon-boba .path87:before {
  content: '\ee28';
  margin-left: -1.0107421875em;
  color: rgb(238, 254, 176);
}
.icon-boba .path88:before {
  content: '\ee29';
  margin-left: -1.0107421875em;
  color: rgb(248, 254, 225);
}
.icon-boba .path89:before {
  content: '\ee2a';
  margin-left: -1.0107421875em;
  color: rgb(203, 254, 0);
}
.icon-boba .path90:before {
  content: '\ee2b';
  margin-left: -1.0107421875em;
  color: rgb(215, 254, 60);
}
.icon-boba .path91:before {
  content: '\ee2c';
  margin-left: -1.0107421875em;
  color: rgb(214, 254, 56);
}
.icon-boba .path92:before {
  content: '\ee2d';
  margin-left: -1.0107421875em;
  color: rgb(229, 254, 131);
}
.icon-boba .path93:before {
  content: '\ee2e';
  margin-left: -1.0107421875em;
  color: rgb(242, 254, 195);
}
.icon-boba .path94:before {
  content: '\ee2f';
  margin-left: -1.0107421875em;
  color: rgb(231, 254, 141);
}
.icon-boba .path95:before {
  content: '\ee30';
  margin-left: -1.0107421875em;
  color: rgb(243, 254, 196);
}
.icon-boba .path96:before {
  content: '\ee31';
  margin-left: -1.0107421875em;
  color: rgb(215, 254, 60);
}
.icon-boba .path97:before {
  content: '\ee32';
  margin-left: -1.0107421875em;
  color: rgb(236, 254, 167);
}
.icon-boba .path98:before {
  content: '\ee33';
  margin-left: -1.0107421875em;
  color: rgb(220, 254, 85);
}
.icon-boba .path99:before {
  content: '\ee34';
  margin-left: -1.0107421875em;
  color: rgb(220, 254, 85);
}
.icon-boba .path100:before {
  content: '\ee35';
  margin-left: -1.0107421875em;
  color: rgb(215, 254, 60);
}
.icon-boba .path101:before {
  content: '\ee36';
  margin-left: -1.0107421875em;
  color: rgb(220, 254, 85);
}
.icon-boba .path102:before {
  content: '\ee37';
  margin-left: -1.0107421875em;
  color: rgb(215, 254, 60);
}
.icon-boba .path103:before {
  content: '\ee38';
  margin-left: -1.0107421875em;
  color: rgb(220, 254, 85);
}
.icon-boba .path104:before {
  content: '\ee39';
  margin-left: -1.0107421875em;
  color: rgb(179, 241, 239);
}
.icon-boba .path105:before {
  content: '\ee3a';
  margin-left: -1.0107421875em;
  color: rgb(229, 254, 131);
}
.icon-boba .path106:before {
  content: '\ee3b';
  margin-left: -1.0107421875em;
  color: rgb(213, 254, 50);
}
.icon-boba .path107:before {
  content: '\ee3c';
  margin-left: -1.0107421875em;
  color: rgb(220, 254, 85);
}
.icon-boba .path108:before {
  content: '\ee3d';
  margin-left: -1.0107421875em;
  color: rgb(220, 254, 85);
}
.icon-boba .path109:before {
  content: '\ee3e';
  margin-left: -1.0107421875em;
  color: rgb(215, 254, 60);
}
.icon-boba .path110:before {
  content: '\ee3f';
  margin-left: -1.0107421875em;
  color: rgb(220, 254, 85);
}
.icon-boba .path111:before {
  content: '\ee40';
  margin-left: -1.0107421875em;
  color: rgb(220, 254, 85);
}
.icon-boba .path112:before {
  content: '\ee41';
  margin-left: -1.0107421875em;
  color: rgb(215, 254, 60);
}
.icon-boba .path113:before {
  content: '\ee42';
  margin-left: -1.0107421875em;
  color: rgb(215, 254, 60);
}
.icon-boba .path114:before {
  content: '\ee43';
  margin-left: -1.0107421875em;
  color: rgb(220, 254, 85);
}
.icon-boba .path115:before {
  content: '\ee44';
  margin-left: -1.0107421875em;
  color: rgb(215, 254, 60);
}
.icon-boba .path116:before {
  content: '\ee45';
  margin-left: -1.0107421875em;
  color: rgb(220, 254, 85);
}
.icon-boba .path117:before {
  content: '\ee46';
  margin-left: -1.0107421875em;
  color: rgb(220, 254, 85);
}
.icon-boba .path118:before {
  content: '\ee47';
  margin-left: -1.0107421875em;
  color: rgb(215, 254, 60);
}
.icon-boba .path119:before {
  content: '\ee48';
  margin-left: -1.0107421875em;
  color: rgb(215, 254, 60);
}
.icon-boba .path120:before {
  content: '\ee49';
  margin-left: -1.0107421875em;
  color: rgb(220, 254, 85);
}
.icon-boba .path121:before {
  content: '\ee4a';
  margin-left: -1.0107421875em;
  color: rgb(220, 254, 85);
}
.icon-boba .path122:before {
  content: '\ee4b';
  margin-left: -1.0107421875em;
  color: rgb(220, 254, 85);
}
.icon-boba .path123:before {
  content: '\ee4c';
  margin-left: -1.0107421875em;
  color: rgb(220, 254, 85);
}
.icon-boba .path124:before {
  content: '\ee4d';
  margin-left: -1.0107421875em;
  color: rgb(220, 254, 85);
}
.icon-boba .path125:before {
  content: '\ee4e';
  margin-left: -1.0107421875em;
  color: rgb(215, 254, 60);
}
.icon-boba .path126:before {
  content: '\ee4f';
  margin-left: -1.0107421875em;
  color: rgb(215, 254, 60);
}
.icon-boba .path127:before {
  content: '\ee50';
  margin-left: -1.0107421875em;
  color: rgb(220, 254, 85);
}
.icon-boba .path128:before {
  content: '\ee51';
  margin-left: -1.0107421875em;
  color: rgb(234, 254, 152);
}
.icon-boba .path129:before {
  content: '\ee52';
  margin-left: -1.0107421875em;
  color: rgb(239, 254, 179);
}
.icon-boba .path130:before {
  content: '\ee53';
  margin-left: -1.0107421875em;
  color: rgb(219, 254, 78);
}
.icon-boba .path131:before {
  content: '\ee54';
  margin-left: -1.0107421875em;
  color: rgb(236, 254, 167);
}
.icon-boba .path132:before {
  content: '\ee55';
  margin-left: -1.0107421875em;
  color: rgb(234, 254, 152);
}
.icon-boba .path133:before {
  content: '\ee56';
  margin-left: -1.0107421875em;
  color: rgb(230, 254, 132);
}
.icon-boba .path134:before {
  content: '\ee57';
  margin-left: -1.0107421875em;
  color: rgb(224, 254, 106);
}
.icon-boba .path135:before {
  content: '\ee58';
  margin-left: -1.0107421875em;
  color: rgb(213, 254, 50);
}
.icon-boba .path136:before {
  content: '\ee59';
  margin-left: -1.0107421875em;
  color: rgb(211, 254, 39);
}
.icon-boba .path137:before {
  content: '\ee5a';
  margin-left: -1.0107421875em;
  color: rgb(212, 254, 48);
}
.icon-boba .path138:before {
  content: '\ee5b';
  margin-left: -1.0107421875em;
  color: rgb(216, 254, 67);
}
.icon-boba .path139:before {
  content: '\ee5c';
  margin-left: -1.0107421875em;
  color: rgb(229, 254, 133);
}
.icon-boba .path140:before {
  content: '\ee5d';
  margin-left: -1.0107421875em;
  color: rgb(235, 254, 159);
}
.icon-boba .path141:before {
  content: '\ee5e';
  margin-left: -1.0107421875em;
  color: rgb(213, 254, 49);
}
.icon-boba .path142:before {
  content: '\ee5f';
  margin-left: -1.0107421875em;
  color: rgb(212, 254, 48);
}
.icon-boba .path143:before {
  content: '\ee60';
  margin-left: -1.0107421875em;
  color: rgb(234, 254, 152);
}
.icon-boba .path144:before {
  content: '\ee61';
  margin-left: -1.0107421875em;
  color: rgb(213, 254, 50);
}
.icon-boba .path145:before {
  content: '\ee62';
  margin-left: -1.0107421875em;
  color: rgb(213, 254, 50);
}
.icon-boba .path146:before {
  content: '\ee63';
  margin-left: -1.0107421875em;
  color: rgb(213, 254, 50);
}
.icon-boba .path147:before {
  content: '\ee64';
  margin-left: -1.0107421875em;
  color: rgb(213, 254, 50);
}
.icon-boba .path148:before {
  content: '\ee65';
  margin-left: -1.0107421875em;
  color: rgb(213, 254, 50);
}
.icon-boba .path149:before {
  content: '\ee66';
  margin-left: -1.0107421875em;
  color: rgb(231, 254, 141);
}
.icon-boba .path150:before {
  content: '\ee67';
  margin-left: -1.0107421875em;
  color: rgb(231, 254, 141);
}
.icon-boba .path151:before {
  content: '\ee68';
  margin-left: -1.0107421875em;
  color: rgb(223, 254, 101);
}
.icon-boba .path152:before {
  content: '\ee69';
  margin-left: -1.0107421875em;
  color: rgb(228, 254, 121);
}
.icon-boba .path153:before {
  content: '\ee6a';
  margin-left: -1.0107421875em;
  color: rgb(221, 254, 90);
}
.icon-boba .path154:before {
  content: '\ee6b';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path155:before {
  content: '\ee6c';
  margin-left: -1.0107421875em;
  color: rgb(234, 254, 152);
}
.icon-boba .path156:before {
  content: '\ee6d';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path157:before {
  content: '\ee6e';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path158:before {
  content: '\ee6f';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path159:before {
  content: '\ee70';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path160:before {
  content: '\ee71';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path161:before {
  content: '\ee72';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path162:before {
  content: '\ee73';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path163:before {
  content: '\ee74';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path164:before {
  content: '\ee75';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path165:before {
  content: '\ee76';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path166:before {
  content: '\ee77';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path167:before {
  content: '\ee78';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path168:before {
  content: '\ee79';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path169:before {
  content: '\ee7a';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path170:before {
  content: '\ee7b';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path171:before {
  content: '\ee7c';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path172:before {
  content: '\ee7d';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path173:before {
  content: '\ee7e';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path174:before {
  content: '\ee7f';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path175:before {
  content: '\ee80';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path176:before {
  content: '\ee81';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path177:before {
  content: '\ee82';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path178:before {
  content: '\ee83';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path179:before {
  content: '\ee84';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path180:before {
  content: '\ee85';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path181:before {
  content: '\ee86';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path182:before {
  content: '\ee87';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path183:before {
  content: '\ee88';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path184:before {
  content: '\ee89';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path185:before {
  content: '\ee8a';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path186:before {
  content: '\ee8b';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path187:before {
  content: '\ee8c';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path188:before {
  content: '\ee8d';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path189:before {
  content: '\ee8e';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path190:before {
  content: '\ee8f';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-boba .path191:before {
  content: '\ee90';
  margin-left: -1.0107421875em;
  color: rgb(226, 254, 114);
}
.icon-sand .path1:before {
  content: '\ee93';
  color: rgb(0, 147, 249);
}
.icon-sand .path2:before {
  content: '\ee94';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gmt .path1:before {
  content: '\ee95';
  color: rgb(244, 197, 86);
}
.icon-gmt .path2:before {
  content: '\ee96';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gmt .path3:before {
  content: '\ee97';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gmt .path4:before {
  content: '\ee98';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gmt .path5:before {
  content: '\ee99';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gmt .path6:before {
  content: '\ee9a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gmt .path7:before {
  content: '\ee9b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gmt .path8:before {
  content: '\ee9c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gmt .path9:before {
  content: '\ee9d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gmt .path10:before {
  content: '\ee9e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gmt .path11:before {
  content: '\ee9f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gmt .path12:before {
  content: '\eea0';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gmt .path13:before {
  content: '\eea1';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gmt .path14:before {
  content: '\eea2';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gmt .path15:before {
  content: '\eea3';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gmt .path16:before {
  content: '\eea4';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gmt .path17:before {
  content: '\eea5';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gmt .path18:before {
  content: '\eea6';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gmt .path19:before {
  content: '\eea7';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gmt .path20:before {
  content: '\eea8';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gmt .path21:before {
  content: '\eea9';
  margin-left: -1em;
  color: rgb(5, 5, 5);
}
.icon-gmt .path22:before {
  content: '\eeaa';
  margin-left: -1em;
  color: rgb(5, 5, 5);
}
.icon-gmt .path23:before {
  content: '\eeab';
  margin-left: -1em;
  color: rgb(5, 5, 5);
}
.icon-gmt .path24:before {
  content: '\eeac';
  margin-left: -1em;
  color: rgb(5, 5, 5);
}
.icon-gmt .path25:before {
  content: '\eead';
  margin-left: -1em;
  color: rgb(5, 5, 5);
}
.icon-gmt .path26:before {
  content: '\eeae';
  margin-left: -1em;
  color: rgb(5, 5, 5);
}
.icon-gmt .path27:before {
  content: '\eeaf';
  margin-left: -1em;
  color: rgb(5, 5, 5);
}
.icon-gmt .path28:before {
  content: '\eeb0';
  margin-left: -1em;
  color: rgb(5, 5, 5);
}
.icon-gst .path1:before {
  content: '\eeb1';
  color: rgb(255, 255, 255);
}
.icon-gst .path2:before {
  content: '\eeb2';
  margin-left: -1em;
  color: rgb(174, 174, 174);
}
.icon-gst .path3:before {
  content: '\eeb3';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path4:before {
  content: '\eeb4';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path5:before {
  content: '\eeb5';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path6:before {
  content: '\eeb6';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path7:before {
  content: '\eeb7';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path8:before {
  content: '\eeb8';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path9:before {
  content: '\eeb9';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path10:before {
  content: '\eeba';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path11:before {
  content: '\eebb';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path12:before {
  content: '\eebc';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path13:before {
  content: '\eebd';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path14:before {
  content: '\eebe';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path15:before {
  content: '\eebf';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path16:before {
  content: '\eec0';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path17:before {
  content: '\eec1';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path18:before {
  content: '\eec2';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path19:before {
  content: '\eec3';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path20:before {
  content: '\eec4';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path21:before {
  content: '\eec5';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path22:before {
  content: '\eec6';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path23:before {
  content: '\eec7';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path24:before {
  content: '\eec8';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path25:before {
  content: '\eec9';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path26:before {
  content: '\eeca';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path27:before {
  content: '\eecb';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path28:before {
  content: '\eecc';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path29:before {
  content: '\eecd';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path30:before {
  content: '\eece';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path31:before {
  content: '\eecf';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path32:before {
  content: '\eed0';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path33:before {
  content: '\eed1';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path34:before {
  content: '\eed2';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path35:before {
  content: '\eed3';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path36:before {
  content: '\eed4';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path37:before {
  content: '\eed5';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path38:before {
  content: '\eed6';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path39:before {
  content: '\eed7';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path40:before {
  content: '\eed8';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path41:before {
  content: '\eed9';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path42:before {
  content: '\eeda';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-gst .path43:before {
  content: '\eedb';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-klay .path1:before {
  content: '\eaf1';
  color: rgb(250, 66, 18);
}
.icon-klay .path2:before {
  content: '\eaf2';
  margin-left: -1.0126953125em;
  color: rgb(189, 7, 47);
}
.icon-klay .path3:before {
  content: '\eaf3';
  margin-left: -1.0126953125em;
  color: rgb(255, 128, 0);
}
.icon-klay .path4:before {
  content: '\eaf4';
  margin-left: -1.0126953125em;
  color: rgb(183, 12, 2);
}
.icon-astar .path1:before {
  content: '\ea00';
  color: rgb(6, 196, 252);
}
.icon-astar .path2:before {
  content: '\eaf5';
  margin-left: -1em;
  color: rgb(1, 107, 180);
}
.icon-astar .path3:before {
  content: '\eaf6';
  margin-left: -1em;
  color: rgb(166, 203, 242);
}
.icon-astar .path4:before {
  content: '\eaf7';
  margin-left: -1em;
  color: rgb(82, 146, 238);
}
.icon-astar .path5:before {
  content: '\eaf8';
  margin-left: -1em;
  color: rgb(184, 21, 147);
}
.icon-astar .path6:before {
  content: '\eef3';
  margin-left: -1em;
  color: rgb(2, 138, 242);
}
.icon-astar .path7:before {
  content: '\eef4';
  margin-left: -1em;
  color: rgb(154, 8, 99);
}
.icon-astar .path8:before {
  content: '\eef5';
  margin-left: -1em;
  color: rgb(1, 115, 236);
}
.icon-astar .path9:before {
  content: '\eef6';
  margin-left: -1em;
  color: rgb(1, 84, 158);
}
.icon-astar .path10:before {
  content: '\eef7';
  margin-left: -1em;
  color: rgb(166, 203, 242);
}
.icon-astar .path11:before {
  content: '\eef8';
  margin-left: -1em;
  color: rgb(166, 203, 242);
}
.icon-astar .path12:before {
  content: '\eef9';
  margin-left: -1em;
  color: rgb(2, 129, 205);
}
.icon-astar .path13:before {
  content: '\eefa';
  margin-left: -1em;
  color: rgb(166, 203, 242);
}
.icon-astar .path14:before {
  content: '\eefb';
  margin-left: -1em;
  color: rgb(166, 203, 242);
}
.icon-astar .path15:before {
  content: '\eefc';
  margin-left: -1em;
  color: rgb(82, 146, 238);
}
.icon-astar .path16:before {
  content: '\eefd';
  margin-left: -1em;
  color: rgb(166, 203, 242);
}
.icon-astar .path17:before {
  content: '\eefe';
  margin-left: -1em;
  color: rgb(50, 139, 237);
}
.icon-astar .path18:before {
  content: '\eeff';
  margin-left: -1em;
  color: rgb(82, 146, 238);
}
.icon-astar .path19:before {
  content: '\ef00';
  margin-left: -1em;
  color: rgb(166, 203, 242);
}
.icon-astar .path20:before {
  content: '\ef01';
  margin-left: -1em;
  color: rgb(166, 203, 242);
}
.icon-astar .path21:before {
  content: '\ef02';
  margin-left: -1em;
  color: rgb(166, 203, 242);
}
.icon-astar .path22:before {
  content: '\ef03';
  margin-left: -1em;
  color: rgb(166, 203, 242);
}
.icon-astar .path23:before {
  content: '\ef04';
  margin-left: -1em;
  color: rgb(166, 203, 242);
}
.icon-astar .path24:before {
  content: '\ef05';
  margin-left: -1em;
  color: rgb(166, 203, 242);
}
.icon-astar .path25:before {
  content: '\ef06';
  margin-left: -1em;
  color: rgb(82, 146, 238);
}
.icon-astar .path26:before {
  content: '\ef07';
  margin-left: -1em;
  color: rgb(8, 215, 253);
}
.icon-astar .path27:before {
  content: '\ef08';
  margin-left: -1em;
  color: rgb(166, 203, 242);
}
.icon-astar .path28:before {
  content: '\ef09';
  margin-left: -1em;
  color: rgb(6, 182, 251);
}
.icon-astar .path29:before {
  content: '\ef0a';
  margin-left: -1em;
  color: rgb(2, 129, 205);
}
.icon-astar .path30:before {
  content: '\ef0b';
  margin-left: -1em;
  color: rgb(6, 196, 252);
}
.icon-astar .path31:before {
  content: '\ef0c';
  margin-left: -1em;
  color: rgb(6, 182, 251);
}
.icon-astar .path32:before {
  content: '\ef0d';
  margin-left: -1em;
  color: rgb(2, 129, 205);
}
.icon-astar .path33:before {
  content: '\ef0e';
  margin-left: -1em;
  color: rgb(10, 224, 254);
}
.icon-astar .path34:before {
  content: '\ef0f';
  margin-left: -1em;
  color: rgb(162, 35, 164);
}
.icon-astar .path35:before {
  content: '\ef10';
  margin-left: -1em;
  color: rgb(210, 8, 131);
}
.icon-astar .path36:before {
  content: '\ef11';
  margin-left: -1em;
  color: rgb(162, 35, 164);
}
.icon-astar .path37:before {
  content: '\ef12';
  margin-left: -1em;
  color: rgb(210, 8, 131);
}
.icon-astar .path38:before {
  content: '\ef13';
  margin-left: -1em;
  color: rgb(153, 86, 218);
}
.icon-astar .path39:before {
  content: '\ef14';
  margin-left: -1em;
  color: rgb(1, 124, 240);
}
.icon-astar .path40:before {
  content: '\ef15';
  margin-left: -1em;
  color: rgb(2, 142, 247);
}
.icon-astar .path41:before {
  content: '\ef16';
  margin-left: -1em;
  color: rgb(166, 203, 242);
}
.icon-astar .path42:before {
  content: '\ef17';
  margin-left: -1em;
  color: rgb(14, 106, 230);
}
.icon-astar .path43:before {
  content: '\ef18';
  margin-left: -1em;
  color: rgb(1, 107, 180);
}
.icon-astar .path44:before {
  content: '\ef19';
  margin-left: -1em;
  color: rgb(112, 61, 195);
}
.icon-astar .path45:before {
  content: '\ef1a';
  margin-left: -1em;
  color: rgb(148, 41, 172);
}
.icon-astar .path46:before {
  content: '\ef1b';
  margin-left: -1em;
  color: rgb(68, 80, 210);
}
.icon-astar .path47:before {
  content: '\ef1c';
  margin-left: -1em;
  color: rgb(90, 69, 201);
}
.icon-astar .path48:before {
  content: '\ef1d';
  margin-left: -1em;
  color: rgb(39, 95, 220);
}
.icon-astar .path49:before {
  content: '\ef1e';
  margin-left: -1em;
  color: rgb(14, 106, 230);
}
.icon-astar .path50:before {
  content: '\ef1f';
  margin-left: -1em;
  color: rgb(68, 80, 210);
}
.icon-astar .path51:before {
  content: '\ef20';
  margin-left: -1em;
  color: rgb(39, 95, 220);
}
.icon-astar .path52:before {
  content: '\ef21';
  margin-left: -1em;
  color: rgb(112, 61, 195);
}
.icon-astar .path53:before {
  content: '\ef22';
  margin-left: -1em;
  color: rgb(148, 41, 172);
}
.icon-astar .path54:before {
  content: '\ef23';
  margin-left: -1em;
  color: rgb(90, 69, 201);
}
.icon-astar .path55:before {
  content: '\ef24';
  margin-left: -1em;
  color: rgb(2, 142, 247);
}
.icon-astar .path56:before {
  content: '\ef25';
  margin-left: -1em;
  color: rgb(1, 124, 240);
}
.icon-astar .path57:before {
  content: '\ef26';
  margin-left: -1em;
  color: rgb(1, 115, 236);
}
.icon-astar .path58:before {
  content: '\ef27';
  margin-left: -1em;
  color: rgb(2, 129, 205);
}
.icon-astar .path59:before {
  content: '\ef28';
  margin-left: -1em;
  color: rgb(2, 159, 245);
}
.icon-astar .path60:before {
  content: '\ef29';
  margin-left: -1em;
  color: rgb(2, 138, 242);
}
.icon-astar .path61:before {
  content: '\ef2a';
  margin-left: -1em;
  color: rgb(6, 196, 252);
}
.icon-astar .path62:before {
  content: '\ef2b';
  margin-left: -1em;
  color: rgb(6, 196, 252);
}
.icon-astar .path63:before {
  content: '\ef2c';
  margin-left: -1em;
  color: rgb(6, 182, 251);
}
.icon-astar .path64:before {
  content: '\ef2d';
  margin-left: -1em;
  color: rgb(8, 215, 253);
}
.icon-astar .path65:before {
  content: '\ef2e';
  margin-left: -1em;
  color: rgb(10, 224, 254);
}
.icon-astar .path66:before {
  content: '\ef2f';
  margin-left: -1em;
  color: rgb(2, 138, 242);
}
.icon-astar .path67:before {
  content: '\ef30';
  margin-left: -1em;
  color: rgb(6, 182, 251);
}
.icon-astar .path68:before {
  content: '\ef31';
  margin-left: -1em;
  color: rgb(2, 142, 247);
}
.icon-astar .path69:before {
  content: '\ef32';
  margin-left: -1em;
  color: rgb(2, 129, 205);
}
.icon-astar .path70:before {
  content: '\ef33';
  margin-left: -1em;
  color: rgb(1, 84, 158);
}
.icon-astar .path71:before {
  content: '\ef34';
  margin-left: -1em;
  color: rgb(2, 129, 205);
}
.icon-astar .path72:before {
  content: '\ef35';
  margin-left: -1em;
  color: rgb(2, 159, 245);
}
.icon-astar .path73:before {
  content: '\ef36';
  margin-left: -1em;
  color: rgb(2, 142, 247);
}
.icon-astar .path74:before {
  content: '\ef37';
  margin-left: -1em;
  color: rgb(2, 129, 205);
}
.icon-astar .path75:before {
  content: '\ef38';
  margin-left: -1em;
  color: rgb(6, 182, 251);
}
.icon-astar .path76:before {
  content: '\ef39';
  margin-left: -1em;
  color: rgb(2, 129, 205);
}
.icon-astar .path77:before {
  content: '\ef3a';
  margin-left: -1em;
  color: rgb(2, 138, 242);
}
.icon-astar .path78:before {
  content: '\ef3b';
  margin-left: -1em;
  color: rgb(2, 129, 205);
}
.icon-astar .path79:before {
  content: '\ef3c';
  margin-left: -1em;
  color: rgb(2, 159, 245);
}
.icon-astar .path80:before {
  content: '\ef3d';
  margin-left: -1em;
  color: rgb(1, 107, 180);
}
.icon-astar .path81:before {
  content: '\ef3e';
  margin-left: -1em;
  color: rgb(1, 107, 180);
}
.icon-astar .path82:before {
  content: '\ef3f';
  margin-left: -1em;
  color: rgb(1, 107, 180);
}
.icon-astar .path83:before {
  content: '\ef40';
  margin-left: -1em;
  color: rgb(1, 84, 158);
}
.icon-astar .path84:before {
  content: '\ef41';
  margin-left: -1em;
  color: rgb(2, 159, 245);
}
.icon-astar .path85:before {
  content: '\ef42';
  margin-left: -1em;
  color: rgb(6, 182, 251);
}
.icon-astar .path86:before {
  content: '\ef43';
  margin-left: -1em;
  color: rgb(2, 159, 245);
}
.icon-astar .path87:before {
  content: '\ef44';
  margin-left: -1em;
  color: rgb(1, 124, 240);
}
.icon-astar .path88:before {
  content: '\ef45';
  margin-left: -1em;
  color: rgb(1, 124, 240);
}
.icon-astar .path89:before {
  content: '\ef46';
  margin-left: -1em;
  color: rgb(1, 115, 236);
}
.icon-astar .path90:before {
  content: '\ef47';
  margin-left: -1em;
  color: rgb(1, 107, 180);
}
.icon-astar .path91:before {
  content: '\ef48';
  margin-left: -1em;
  color: rgb(1, 107, 180);
}
.icon-astar .path92:before {
  content: '\ef49';
  margin-left: -1em;
  color: rgb(2, 159, 245);
}
.icon-astar .path93:before {
  content: '\ef4a';
  margin-left: -1em;
  color: rgb(6, 182, 251);
}
.icon-astar .path94:before {
  content: '\ef4b';
  margin-left: -1em;
  color: rgb(8, 215, 253);
}
.icon-astar .path95:before {
  content: '\ef4c';
  margin-left: -1em;
  color: rgb(63, 53, 153);
}
.icon-astar .path96:before {
  content: '\ef4d';
  margin-left: -1em;
  color: rgb(153, 86, 218);
}
.icon-astar .path97:before {
  content: '\ef4e';
  margin-left: -1em;
  color: rgb(148, 41, 172);
}
.icon-astar .path98:before {
  content: '\ef4f';
  margin-left: -1em;
  color: rgb(148, 41, 172);
}
.icon-astar .path99:before {
  content: '\ef50';
  margin-left: -1em;
  color: rgb(166, 203, 242);
}
.icon-astar .path100:before {
  content: '\ef51';
  margin-left: -1em;
  color: rgb(50, 139, 237);
}
.icon-astar .path101:before {
  content: '\ef52';
  margin-left: -1em;
  color: rgb(68, 80, 210);
}
.icon-astar .path102:before {
  content: '\ef53';
  margin-left: -1em;
  color: rgb(1, 107, 180);
}
.icon-astar .path103:before {
  content: '\ef54';
  margin-left: -1em;
  color: rgb(90, 69, 201);
}
.icon-astar .path104:before {
  content: '\ef55';
  margin-left: -1em;
  color: rgb(6, 182, 251);
}
.icon-astar .path105:before {
  content: '\ef56';
  margin-left: -1em;
  color: rgb(112, 61, 195);
}
.icon-astar .path106:before {
  content: '\ef57';
  margin-left: -1em;
  color: rgb(2, 138, 242);
}
.icon-astar .path107:before {
  content: '\ef58';
  margin-left: -1em;
  color: rgb(2, 159, 245);
}
.icon-astar .path108:before {
  content: '\ef59';
  margin-left: -1em;
  color: rgb(166, 203, 242);
}
.icon-astar .path109:before {
  content: '\ef5a';
  margin-left: -1em;
  color: rgb(24, 87, 198);
}
.icon-astar .path110:before {
  content: '\ef5b';
  margin-left: -1em;
  color: rgb(1, 107, 180);
}
.icon-astar .path111:before {
  content: '\ef5c';
  margin-left: -1em;
  color: rgb(82, 146, 238);
}
.icon-astar .path112:before {
  content: '\ef5d';
  margin-left: -1em;
  color: rgb(166, 203, 242);
}
.icon-astar .path113:before {
  content: '\ef5e';
  margin-left: -1em;
  color: rgb(6, 196, 252);
}
.icon-astar .path114:before {
  content: '\ef5f';
  margin-left: -1em;
  color: rgb(166, 203, 242);
}
.icon-astar .path115:before {
  content: '\ef60';
  margin-left: -1em;
  color: rgb(2, 138, 242);
}
.icon-astar .path116:before {
  content: '\ef61';
  margin-left: -1em;
  color: rgb(1, 107, 180);
}
.icon-astar .path117:before {
  content: '\ef62';
  margin-left: -1em;
  color: rgb(6, 196, 252);
}
.icon-astar .path118:before {
  content: '\ef63';
  margin-left: -1em;
  color: rgb(2, 138, 242);
}
.icon-astar .path119:before {
  content: '\ef64';
  margin-left: -1em;
  color: rgb(2, 159, 245);
}
.icon-astar .path120:before {
  content: '\ef65';
  margin-left: -1em;
  color: rgb(6, 182, 251);
}
.icon-astar .path121:before {
  content: '\ef66';
  margin-left: -1em;
  color: rgb(2, 138, 242);
}
.icon-astar .path122:before {
  content: '\ef67';
  margin-left: -1em;
  color: rgb(6, 196, 252);
}
.icon-astar .path123:before {
  content: '\ef68';
  margin-left: -1em;
  color: rgb(2, 159, 245);
}
.icon-astar .path124:before {
  content: '\ef69';
  margin-left: -1em;
  color: rgb(8, 215, 253);
}
.icon-astar .path125:before {
  content: '\ef6a';
  margin-left: -1em;
  color: rgb(6, 196, 252);
}
.icon-astar .path126:before {
  content: '\ef6b';
  margin-left: -1em;
  color: rgb(6, 196, 252);
}
.icon-astar .path127:before {
  content: '\ef6c';
  margin-left: -1em;
  color: rgb(6, 182, 251);
}
.icon-astar .path128:before {
  content: '\ef6d';
  margin-left: -1em;
  color: rgb(2, 159, 245);
}
.icon-astar .path129:before {
  content: '\ef6e';
  margin-left: -1em;
  color: rgb(1, 107, 180);
}
.icon-astar .path130:before {
  content: '\ef6f';
  margin-left: -1em;
  color: rgb(10, 224, 254);
}
.icon-astar .path131:before {
  content: '\ef70';
  margin-left: -1em;
  color: rgb(6, 196, 252);
}
.icon-astar .path132:before {
  content: '\ef71';
  margin-left: -1em;
  color: rgb(166, 203, 242);
}
.icon-astar .path133:before {
  content: '\ef72';
  margin-left: -1em;
  color: rgb(1, 107, 180);
}
.icon-astar .path134:before {
  content: '\ef73';
  margin-left: -1em;
  color: rgb(1, 124, 240);
}
.icon-astar .path135:before {
  content: '\ef74';
  margin-left: -1em;
  color: rgb(6, 196, 252);
}
.icon-astar .path136:before {
  content: '\ef75';
  margin-left: -1em;
  color: rgb(90, 69, 201);
}
.icon-astar .path137:before {
  content: '\ef76';
  margin-left: -1em;
  color: rgb(112, 61, 195);
}
.icon-astar .path138:before {
  content: '\ef77';
  margin-left: -1em;
  color: rgb(166, 203, 242);
}
.icon-astar .path139:before {
  content: '\ef78';
  margin-left: -1em;
  color: rgb(1, 84, 158);
}
.icon-astar .path140:before {
  content: '\ef79';
  margin-left: -1em;
  color: rgb(1, 107, 180);
}
.icon-astar .path141:before {
  content: '\ef7a';
  margin-left: -1em;
  color: rgb(82, 146, 238);
}
.icon-astar .path142:before {
  content: '\ef7b';
  margin-left: -1em;
  color: rgb(82, 146, 238);
}
.icon-astar .path143:before {
  content: '\ef7c';
  margin-left: -1em;
  color: rgb(82, 146, 238);
}
.icon-astar .path144:before {
  content: '\ef7d';
  margin-left: -1em;
  color: rgb(166, 203, 242);
}
.icon-astar .path145:before {
  content: '\ef7e';
  margin-left: -1em;
  color: rgb(166, 203, 242);
}
.icon-astar .path146:before {
  content: '\ef7f';
  margin-left: -1em;
  color: rgb(166, 203, 242);
}
.icon-astar .path147:before {
  content: '\ef80';
  margin-left: -1em;
  color: rgb(82, 146, 238);
}
.icon-astar .path148:before {
  content: '\ef81';
  margin-left: -1em;
  color: rgb(50, 139, 237);
}
.icon-astar .path149:before {
  content: '\ef82';
  margin-left: -1em;
  color: rgb(82, 146, 238);
}
.icon-astar .path150:before {
  content: '\ef83';
  margin-left: -1em;
  color: rgb(24, 87, 198);
}
.icon-astar .path151:before {
  content: '\ef84';
  margin-left: -1em;
  color: rgb(153, 86, 218);
}
.icon-astar .path152:before {
  content: '\ef85';
  margin-left: -1em;
  color: rgb(39, 95, 220);
}
.icon-astar .path153:before {
  content: '\ef86';
  margin-left: -1em;
  color: rgb(1, 84, 158);
}
.icon-astar .path154:before {
  content: '\ef87';
  margin-left: -1em;
  color: rgb(166, 203, 242);
}
.icon-astar .path155:before {
  content: '\ef88';
  margin-left: -1em;
  color: rgb(39, 95, 220);
}
.icon-astar .path156:before {
  content: '\ef89';
  margin-left: -1em;
  color: rgb(14, 106, 230);
}
.icon-astar .path157:before {
  content: '\ef8a';
  margin-left: -1em;
  color: rgb(82, 146, 238);
}
.icon-astar .path158:before {
  content: '\ef8b';
  margin-left: -1em;
  color: rgb(63, 53, 153);
}
.icon-astar .path159:before {
  content: '\ef8c';
  margin-left: -1em;
  color: rgb(2, 129, 205);
}
.icon-astar .path160:before {
  content: '\ef8d';
  margin-left: -1em;
  color: rgb(1, 124, 240);
}
.icon-astar .path161:before {
  content: '\ef8e';
  margin-left: -1em;
  color: rgb(1, 124, 240);
}
.icon-astar .path162:before {
  content: '\ef8f';
  margin-left: -1em;
  color: rgb(2, 159, 245);
}
.icon-astar .path163:before {
  content: '\ef90';
  margin-left: -1em;
  color: rgb(2, 138, 242);
}
.icon-astar .path164:before {
  content: '\ef91';
  margin-left: -1em;
  color: rgb(8, 215, 253);
}
.icon-astar .path165:before {
  content: '\ef92';
  margin-left: -1em;
  color: rgb(2, 138, 242);
}
.icon-astar .path166:before {
  content: '\ef93';
  margin-left: -1em;
  color: rgb(6, 196, 252);
}
.icon-astar .path167:before {
  content: '\ef94';
  margin-left: -1em;
  color: rgb(1, 115, 236);
}
.icon-astar .path168:before {
  content: '\ef95';
  margin-left: -1em;
  color: rgb(14, 106, 230);
}
.icon-astar .path169:before {
  content: '\ef96';
  margin-left: -1em;
  color: rgb(1, 124, 240);
}
.icon-astar .path170:before {
  content: '\ef97';
  margin-left: -1em;
  color: rgb(1, 115, 236);
}
.icon-astar .path171:before {
  content: '\ef98';
  margin-left: -1em;
  color: rgb(1, 107, 180);
}
.icon-astar .path172:before {
  content: '\ef99';
  margin-left: -1em;
  color: rgb(1, 107, 180);
}
.icon-astar .path173:before {
  content: '\ef9a';
  margin-left: -1em;
  color: rgb(2, 129, 205);
}
.icon-astar .path174:before {
  content: '\ef9b';
  margin-left: -1em;
  color: rgb(1, 115, 236);
}
.icon-astar .path175:before {
  content: '\ef9c';
  margin-left: -1em;
  color: rgb(63, 53, 153);
}
.icon-astar .path176:before {
  content: '\ef9d';
  margin-left: -1em;
  color: rgb(68, 80, 210);
}
.icon-astar .path177:before {
  content: '\ef9e';
  margin-left: -1em;
  color: rgb(14, 106, 230);
}
.icon-astar .path178:before {
  content: '\ef9f';
  margin-left: -1em;
  color: rgb(39, 95, 220);
}
.icon-astar .path179:before {
  content: '\efa0';
  margin-left: -1em;
  color: rgb(1, 84, 158);
}
.icon-astar .path180:before {
  content: '\efa1';
  margin-left: -1em;
  color: rgb(2, 129, 205);
}
.icon-astar .path181:before {
  content: '\efa2';
  margin-left: -1em;
  color: rgb(1, 124, 240);
}
.icon-astar .path182:before {
  content: '\efa3';
  margin-left: -1em;
  color: rgb(6, 196, 252);
}
.icon-astar .path183:before {
  content: '\efa4';
  margin-left: -1em;
  color: rgb(2, 129, 205);
}
.icon-astar .path184:before {
  content: '\efa5';
  margin-left: -1em;
  color: rgb(1, 115, 236);
}
.icon-astar .path185:before {
  content: '\efa6';
  margin-left: -1em;
  color: rgb(1, 115, 236);
}
.icon-astar .path186:before {
  content: '\efa7';
  margin-left: -1em;
  color: rgb(1, 107, 180);
}
.icon-astar .path187:before {
  content: '\efa8';
  margin-left: -1em;
  color: rgb(6, 182, 251);
}
.icon-astar .path188:before {
  content: '\efa9';
  margin-left: -1em;
  color: rgb(14, 106, 230);
}
.icon-astar .path189:before {
  content: '\efaa';
  margin-left: -1em;
  color: rgb(2, 129, 205);
}
.icon-astar .path190:before {
  content: '\efab';
  margin-left: -1em;
  color: rgb(2, 159, 245);
}
.icon-astar .path191:before {
  content: '\efac';
  margin-left: -1em;
  color: rgb(63, 53, 153);
}
.icon-astar .path192:before {
  content: '\efad';
  margin-left: -1em;
  color: rgb(68, 80, 210);
}
.icon-astar .path193:before {
  content: '\efae';
  margin-left: -1em;
  color: rgb(112, 61, 195);
}
.icon-astar .path194:before {
  content: '\efaf';
  margin-left: -1em;
  color: rgb(90, 69, 201);
}
.icon-astar .path195:before {
  content: '\efb0';
  margin-left: -1em;
  color: rgb(68, 80, 210);
}
.icon-astar .path196:before {
  content: '\efb1';
  margin-left: -1em;
  color: rgb(24, 87, 198);
}
.icon-astar .path197:before {
  content: '\efb2';
  margin-left: -1em;
  color: rgb(14, 106, 230);
}
.icon-astar .path198:before {
  content: '\efb3';
  margin-left: -1em;
  color: rgb(1, 124, 240);
}
.icon-astar .path199:before {
  content: '\efb4';
  margin-left: -1em;
  color: rgb(1, 124, 240);
}
.icon-astar .path200:before {
  content: '\efb5';
  margin-left: -1em;
  color: rgb(2, 142, 247);
}
.icon-flr .path1:before {
  content: '\efb8';
  color: rgb(230, 32, 88);
}
.icon-flr .path2:before {
  content: '\efb9';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-flr .path3:before {
  content: '\efba';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-flr .path4:before {
  content: '\efbb';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-axs .path1:before {
  content: '\efbc';
  color: rgb(0, 85, 213);
}
.icon-axs .path2:before {
  content: '\efbd';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-grt .path1:before {
  content: '\efe8';
  color: rgb(103, 71, 237);
}
.icon-grt .path2:before {
  content: '\efe9';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
/*! purgecss end ignore */
